import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridCloseIcon,
  GridSearchIcon,
} from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";

import FilterButton from "../FilterButton";
import {  grey } from "@mui/material/colors";
import { Add,} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
const DesktopGridHeader = ({
  setColumnSelectorType,
  setOpenColumnsSelector,
  setIsOpenObjectModal,
  handleSearch,
  searchText: initialSearch,
  quickFilterItems,
  handleExportModal,
}) => {
  const [searchText, setSearchText] = useState(() => initialSearch || "");
  const { t } = useTranslation();
  const theme = useTheme()

  const CustomExportButton = (props) => {
    return (
      <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem
          onClick={(e) => {
            setColumnSelectorType("download-csv");
            setOpenColumnsSelector(true);
          }}
        />
        <MenuItem
          onClick={handleExportModal}
        >
          {t("datatable.label.toolbar.downloadExcel")}
        </MenuItem>
        <GridPrintExportMenuItem
          onClick={(e) => {
            setColumnSelectorType("print");
            setOpenColumnsSelector(true);
          }}
        />
      </GridToolbarExportContainer>
    );
  };
  useEffect(() => {
    setSearchText(initialSearch);
  }, [initialSearch]);
  return (
    <GridToolbarContainer>
      <Stack
        direction="row"
        gap={2.5}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        p="2px 0px"
        marginBottom="8px"
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          padding="5px 0px"
        >
          <Box width="100%" display="flex-start" justifyContent="space-around">
            <Button
              variant="text"
              startIcon={<Add color="primary" />}
              onClick={() => setIsOpenObjectModal(true)}
              sx={{ fontSize: "13px" }}
            >
              {t("general.add")}
            </Button>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <CustomExportButton />
          </Box>
          <Stack direction="row" width="60%" justifyContent="right" gap={2}>

            {quickFilterItems.map((item) => <FilterButton
              onClick={item.onClick}
              isSelected={item.isSelected}
            >
              <Tooltip title={item.title}>

                <div>
                  {item.iconText &&
                    <span
                      style={{ color: item.isSelected ? "white" : grey[600] }}
                    >
                      {item.iconText}
                    </span>}

                  {item.iconClassName && <span>
                    <i
                      aria-hidden="true"
                      style={{ color: item.isSelected ? "white" : grey[600] }}
                      className={item.iconClassName}
                    ></i>
                  </span>}
                </div>
              </Tooltip>
            </FilterButton>)}


          </Stack>
        </Box>
        <Box
          width="40%"
          padding="1px 5px"
          borderRadius="7px"
          boxShadow={theme.shadows[1]}
          margin="2px"
          display="flex"
          alignItems="center"
        >
          <TextField
            fullWidth
            id="standard-search"
            placeholder={t("datatable.label.toolbar.search")}
            type="text"
            variant="standard"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              handleSearch(e.target.value);
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <IconButton size="small">
                  <GridSearchIcon
                    sx={{ margin: "0px 5px", color: "primary.main" }}
                  />
                </IconButton>
              ),
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (searchText !== "") {
                      handleSearch("");
                      setSearchText("");
                    }
                  }}
                >
                  {searchText && (
                    <GridCloseIcon
                      fontSize="small"
                      sx={{ margin: "0px 5px", color: "primary.main" }}
                    />
                  )}
                </IconButton>
              ),
            }}
          />
        </Box>
      </Stack>
    </GridToolbarContainer>
  );
};

export default DesktopGridHeader;
