import React, { useEffect, useState } from 'react'
import {
    CardHeader,
    Avatar,
    IconButton,
    Badge,
    Popover,
    CardContent,
    Grid,
    Button,
    Divider,
    Checkbox,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Business, Email, Phone, Smartphone, Create, Close, Home } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import SelectResponsiblePerson from './SelectResponsiblePerson';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { surveyActions } from '../../../_actions';
import { getNameInitials } from '../../../_helpers';
import clsx from "clsx";

const { webApiUrl } = window.appConfig;

export default function UserInformation({ projectId,isSecondSignature,customTitle, changeExecutorForSurvey, surveyData, canShowCloseIcon = true, onMouseLeave = () => { }, open, anchorEl: anchorElModal, isEditable = false, data, repairRequestRight = true, hideExtraInfo, requestKey, ...props }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();
    const [organizationsLoading, setOrganizationsLoading] = useState(false)
    const dispatch = useDispatch();
    const userInitials = getNameInitials((data && data.userInformation) ? data.userInformation[data.userInformation.isPerson ? "name" : "relationName"] : '');
    const photoUrl = (data && data.userInformation) ? webApiUrl + "api/home/GetPersonPhoto/" + data.userInformation[data.userInformation.isPerson ? "personId" : "relationPersonId"] : '';
    const [canCreateRepairRequest, setCanCreateRepairRequest] = useState(false);
    const [workOrderRight, setWorkOrderRight] = useState(false);

    useEffect(() => {
        if (data && data.userInformation && data.userInformation) {
            setCanCreateRepairRequest(data.userInformation.repairRequestRight)
            setWorkOrderRight(data.userInformation.workorderRight)
        };
    }, [data && data.userInformation])

    useEffect(() => {
        if (anchorElModal && data && !data.userInformation) {
            setAnchorEl(anchorElModal)
        };
    }, [anchorElModal])

    const handleChangeResponsiblePerson = ({ orgId, ...updatedData }) => {
        if (requestKey && (requestKey === "updateResponsiblePersonMoments" || requestKey === "updateAssignedPersonForAssignment")) {
            if (requestKey === "updateResponsiblePersonMoments") {
                updatedData = {
                    organisationId: orgId,
                    relationId: updatedData.relationId,
                    repairRequestRight: updatedData.repairRequestRight,
                    workOrderText: updatedData.workOrderText,
                    sendWorkOrder: updatedData.sendWorkOrder,
                    ids: [data.surveyingMomentId]
                }
            } else {
                updatedData = {
                    loginId: updatedData.loginId,
                    workorderRight: updatedData.workOrderRight,
                    repairRequestRight: updatedData.repairRequestRight,
                    ids: [data.assignmentId],
                }
            }
            dispatch(surveyActions[requestKey](updatedData, {
                surveyMomentId: data.surveyingMomentId,
                id: data.id,
                assignmentId: requestKey === "updateResponsiblePersonMoments" ? null : data.assignmentId
            }, false, orgId));
        } else if (changeExecutorForSurvey) {
            dispatch(surveyActions.updateExecutorForSurveyAction(surveyData, updatedData))
        }
    }


    return <>
        {data && data.userInformation ? <Popover
            id="user-info"
            style={{ overflow: 'hidden' }}
            anchorEl={anchorElModal}
            open={!!anchorElModal}
            disableRestoreFocus
            classes={{
                root: classes.root,
                paper: classes.paper,
            }}
            {...props}
        >
            <div onMouseLeave={onMouseLeave}
            //  onMouseLeave={data && data.event !== 'click' && !anchorEl && props.onClose}
            >
                {canShowCloseIcon && <IconButton
                    className={classes.closeIcon}
                    aria-label="settings"
                    onClick={props.onClose}
                    size="large">
                    <Close className={clsx(classes.create, classes.closeSVGIcon)} />
                </IconButton>}

                <CardHeader
                    avatar={
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <Avatar className={classes.avatar} src={photoUrl}>{userInitials}</Avatar>
                        </Badge>}
                    action={isEditable &&
                        <>
                            <IconButton
                                disable={organizationsLoading}
                                aria-owns={!!anchorEl ? 'persons-list' : undefined}
                                aria-label="persons-list"
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                                size="large">
                                {organizationsLoading ? <CircularProgress size={18} color='primary' /> : <Create className={classes.create} />}
                            </IconButton>
                        </>
                    }
                    title={data.userInformation.isPerson ? data.userInformation.name : data.userInformation.relationName}
                    subheader={!data.userInformation.isPerson && data.userInformation.name}
                />
                {
                    <CardContent className={classes.cardContentStyle}>
                        {data.userInformation.isPerson ?
                            <>
                                {data.userInformation.telephonePrivate && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Home />&nbsp;<Button color="primary"
                                                        href={'tel:'}>{data.userInformation.telephonePrivate}&nbsp;
                                                        <Phone /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}
                                {data.userInformation.mobile && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Smartphone />&nbsp;<Button color="primary"
                                                        href={'tel:'}>{data.userInformation.mobile}&nbsp;
                                                        <Phone /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}
                                {data.userInformation.telephoneWork && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Business />&nbsp;<Button color="primary"
                                                        href={'tel:'}>{data.userInformation.telephoneWork}&nbsp;
                                                        <Phone /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}
                                {data.userInformation.emailPrivate && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Home />&nbsp;<Button component="a" color="primary"
                                                        href={'mailto:'} className={classes.link}>{data.userInformation.emailPrivate}&nbsp;
                                                        <Email /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}
                                {data.userInformation.emailWork && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Business />&nbsp;<Button component="a" color="primary"
                                                        href={'mailto:'} className={classes.link}>{data.userInformation.emailWork}&nbsp;
                                                        <Email /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}
                            </>
                            : <>
                                {data.userInformation.telephone && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Smartphone />&nbsp;<Button color="primary"
                                                        href={'tel:'}>{data.userInformation.telephone}&nbsp;
                                                        <Phone /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}
                                {data.userInformation.telephonePrivate && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Home />&nbsp;<Button color="primary"
                                                        href={'tel:'}>{data.userInformation.telephonePrivate}&nbsp;
                                                        <Phone /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}
                                {data.userInformation.mobile && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Smartphone />&nbsp;<Button color="primary"
                                                        href={'tel:'}>{data.userInformation.mobile}&nbsp;
                                                        <Phone /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}
                                {data.userInformation.telephoneWork && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Business />&nbsp;<Button color="primary"
                                                        href={'tel:'}>{data.userInformation.telephoneWork}&nbsp;
                                                        <Phone /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}
                                {data.userInformation.relationEmail && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Business />&nbsp;<Button component="a" color="primary"
                                                        href={'mailto:'} className={classes.link}>{data.userInformation.relationEmail}&nbsp;
                                                        <Email /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}
                                {data.userInformation.emailPrivate && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Home />&nbsp;<Button component="a" color="primary"
                                                        href={'mailto:'} className={classes.link}>{data.userInformation.emailPrivate}&nbsp;
                                                        <Email /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}
                                {data.userInformation.emailWork && (
                                    <>
                                        <div>
                                            <Grid container className={classes.infoGridRow}>
                                                <Grid item xs={12}>
                                                    <Business />&nbsp;<Button component="a" color="primary"
                                                        href={'mailto:'} className={classes.link}>{data.userInformation.emailWork}&nbsp;
                                                        <Email /></Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Divider />
                                    </>
                                )}

                            </>}
                        <div>
                            {repairRequestRight && <div>
                                <Grid container className={classes.infoGridRow}>
                                    <Grid item xs={12}>
                                        <Checkbox
                                            disabled={!isEditable}
                                            onChange={({ target: { checked } }) => {
                                                const sendingData = {
                                                    loginId: data.userInformation.loginId,
                                                    workOrderRight: workOrderRight && checked,
                                                    repairRequestRight: checked,
                                                }
                                                handleChangeResponsiblePerson(sendingData)
                                                setCanCreateRepairRequest(checked)
                                            }}
                                            className={classes.checkboxRoot}
                                            color="primary"
                                            checked={canCreateRepairRequest}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                                        &nbsp;<span color="primary">{t('survey.canCreateRepairRequests.label')}</span>
                                    </Grid>
                                    {canCreateRepairRequest && <Grid item xs={12}>
                                        <Checkbox
                                            disabled={!isEditable}
                                            onChange={({ target: { checked } }) => {
                                                const sendingData = {
                                                    loginId: data.userInformation.loginId,
                                                    workOrderRight: checked,
                                                    repairRequestRight: canCreateRepairRequest,
                                                }
                                                handleChangeResponsiblePerson(sendingData)
                                                setWorkOrderRight(checked)
                                            }}
                                            className={classes.checkboxRoot}
                                            color="primary"
                                            checked={workOrderRight}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                                        &nbsp;<span color="primary">{t('survey.canCreateWorkOrders.label')}</span>
                                    </Grid>}
                                </Grid>
                            </div>}

                        </div>
                    </CardContent>
                }
            </div>

        </Popover>
            : null}
        {data && <SelectResponsiblePerson
            isSecondSignature={isSecondSignature}
            projectId={projectId}
            customTitle={customTitle}
            surveyIds={surveyData && [surveyData.surveyId]}
            changeExecutorForSurvey={changeExecutorForSurvey}
            hideExtraInfo={hideExtraInfo}
            isCanSendToWB
            canCreateRepairRequest={repairRequestRight}
            onSubmit={(values) => handleChangeResponsiblePerson(values)}
            customOplosser
            closeModal={props.onClose}
            setLoading={(val) => setOrganizationsLoading(val)}
            loading={organizationsLoading}
            anchorEl={anchorEl}
            onClose={() => {
                setAnchorEl(null)
                anchorElModal && data && !data.userInformation && props.onClose()
            }} />}
    </>;
}


const useStyles = makeStyles(theme => ({
    root: {
    },
    grow: {
        flexGrow: 1
    },
    paper: {
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        minWidth: 308,
        borderRadius: 7,
    },
    create: {
        color: "inherit"
    },
    cardContentStyle: {
        padding: theme.spacing(0, 2),
    },
    closeIcon: {
        position: "absolute",
        right: 0,
        padding: 4
    },
    closeSVGIcon: {
        fontSize: 17,
        fontWeight: 'bold',
        color: theme.palette.grey[800]
    },
    infoGridRow: {
        width: "fit-content",
        '& > div': {
            padding: theme.spacing(0.5, 2),
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        '& span:last-child': {
            color: theme.palette.primary.main
        },
        '& .MuiInputLabel-outlined': {
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    checkboxRoot: {
        padding: theme.spacing(0, 1, 0, 0),
    },
    link: {
        textTransform: "lowercase"
    }
}))
