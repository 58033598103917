import { Badge, Button, Grid, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { House } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { groupBy, usePrevious } from '../../_helpers';

const StyledBadge = withStyles(theme => ({
    badge: {
        right: theme.spacing(-0.25),
        padding: theme.spacing(0, 0.5),
        minWidth: theme.spacing(2),
        // height: theme.spacing(2),
    },
}))(Badge);


export default function BuildingFilter({ getSelectedBuilding, requests, selectedBuildingId, ...props }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const { all, selected } = useSelector(state => state.buildings);
    const classes = useStyles()
    const [buildings, setBuildings] = useState({});
    const [requestForBuildings, setRequestForBuildings] = useState([]);
    const { t } = useTranslation();
    const prevSelected = usePrevious(selected);
    // const selectedBuilding = buildings[selectedBuildingId] || {};
    useEffect(() => {
        if (prevSelected && prevSelected.projectId === selected.projectId && all && all.length) {
            const data = all.filter(b => b.projectId === selected.projectId);
            const buildingData = groupBy(data, 'buildingId');
            setBuildings(Object.keys(buildingData).reduce((prev, key) => ({
                ...prev,
                [buildingData[key][0].buildingNoExtern]: buildingData[key][0]
            }), {}));
            const requestForBuildingsData = groupBy(requests.filter(p => p.drawingPinLocation), 'buildingId');
            setRequestForBuildings(Object.keys(requestForBuildingsData).map((key) => ({
                buildingNoExtern: (key !== "null" && key !== "undefined") ? buildingData[key] && buildingData[key][0].buildingNoExtern : "Project Level", data: requestForBuildingsData[key]
            })).sort((a, b) => a.buildingNoExtern - b.buildingNoExtern));
        }
    }, [selected, all, requests, prevSelected]);

    const handleOpen = ({ currentTarget }) => {
        setAnchorEl(currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            <Tooltip title={t('general.objects')}>
                <IconButton
                    onClick={handleOpen}
                    edge="end"
                    color="inherit"
                    className={classes.zoomButton}
                    size="large">
                    {/* <StyledBadge badgeContent={selectedBuilding.buildingNoExtern} color="secondary"> */}
                    <House className={classes.mapSubHeaderIcon} />
                    {/* </StyledBadge> */}
                </IconButton>
            </Tooltip>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container justifyContent="center">
                    <Grid item container justifyContent="center" className={classes.popoverContainer}>
                        <Typography component="div" variant="subtitle2" color="textSecondary" className={classes.subtitle}>{t('general.objects')}</Typography>
                        {
                            requestForBuildings.map(({ buildingNoExtern, data }) => (
                                <Grid item key={`Building-${buildingNoExtern}`}>
                                    <Button
                                        disabled={
                                            buildingNoExtern !== "Project Level" ?
                                                selectedBuildingId === (buildings[buildingNoExtern] && buildings[buildingNoExtern].buildingId) :
                                                !selectedBuildingId
                                        }
                                        onClick={() => getSelectedBuilding(buildings[buildingNoExtern])}
                                        style={{ minWidth: 'auto' }}>
                                        <StyledBadge color="secondary" badgeContent={data.length}>
                                            {t(buildingNoExtern)}
                                        </StyledBadge>
                                    </Button>
                                </Grid>
                            ))
                        }

                    </Grid>
                </Grid>
            </Popover>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    popoverContainer: {
        maxWidth: 300,
        margin: 8,
        maxHeight: 'calc(100vh - 80px)'
    },
    subtitle: {
        fontWeight: "bold",
        width: '100%',
        padding: theme.spacing(0, 2),
        lineHeight: '24px'
    },
    zoomButton: {
        borderRadius: 5,
        // overflow: 'hidden',
        padding: theme.spacing(.5),
        margin: 0,
        height: '38px',
        width: '38px',
        [theme.breakpoints.down('sm')]: {
            height: '31px',
            width: '31px',
        },
        '&:focus': {
            outline: 'none !important'
        },
        '&::before': {
            content: '""',
            height: '100%',
            width: '100%',
            borderRadius: 5,
            opacity: 0.2,
            zIndex: '-1',
            position: 'absolute',
            backgroundColor: theme.palette.grey[700],
        },
        '& .MuiSvgIcon-root': {
            fontSize: 24,
            [theme.breakpoints.down('sm')]: {
                fontSize: 20,
            },
        }
    },
    mapSubHeaderIcon: {
        fill: theme.palette.grey[900]
    },
}))