import { useEffect, useState } from "react";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridCloseIcon,
  GridSearchIcon,
} from "@mui/x-data-grid-pro";
import { Box, IconButton, MenuItem, Stack, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import FilterButton from "../FilterButton";
import { useTheme } from "@mui/styles";

const CustomToolbar = ({
  setColumnSelectorType,
  setOpenColumnsSelector,
  setIsOpenObjectModal,
  handleSearch,
  searchText: initialSearch,
  quickFilterItems,
  handleExportModal,
}) => {
  const [searchText, setSearchText] = useState(() => initialSearch || "");
  const theme = useTheme()

  const CustomExportButton = (props) => {
    const { t } = useTranslation();
    return (
      <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem
          onClick={(e) => {
            setColumnSelectorType("download-csv");
            setOpenColumnsSelector(true);
          }}
        />
        <MenuItem
          onClick={handleExportModal}
        >
          {t("datatable.label.toolbar.downloadExcel")}
        </MenuItem>
        <GridPrintExportMenuItem
          onClick={(e) => {
            setColumnSelectorType("print");
            setOpenColumnsSelector(true);
          }}
        />
      </GridToolbarExportContainer>
    );
  };
  useEffect(() => {
    setSearchText(initialSearch);
  }, [initialSearch]);
  return (
    <GridToolbarContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        display={{ xs: "block" }}
      >
        <Box
          width="100%"
          padding="1px 5px"
          borderRadius="7px"
          boxShadow={theme.shadows[1]}
          display="flex"
          alignItems="center"
        >
          <TextField
            fullWidth
            id="standard-search"
            placeholder="Search"
            type="text"
            variant="standard"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              handleSearch(e.target.value);
            }}
            sx={{ width: "100%", fontSize: "15px" }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <IconButton size="small">
                  <GridSearchIcon
                    sx={{ margin: "0px 5px", color: "primary.main" }}
                  />
                </IconButton>
              ),
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (searchText !== "") {
                      handleSearch("");
                      setSearchText("");
                    }
                  }}
                >
                  {searchText && (
                    <GridCloseIcon
                      fontSize="small"
                      sx={{ margin: "0px 5px", color: "primary.main" }}
                    />
                  )}
                </IconButton>
              ),
            }}
          />
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box width="50%" display="flex" justifyContent="space-around">
            <IconButton
              aria-label="add"
              onClick={() => setIsOpenObjectModal(true)}
            >
              <Add color="primary" />
            </IconButton>
            <GridToolbarFilterButton sx={{ minWidth: 4 }} />
            <CustomExportButton sx={{ minWidth: 4 }} />
          </Box>
          <Box
            borderRight="1px solid black"
            height="20px"
            borderColor={grey[400]}
            sx={{marginRight : "8px"}}
          >
            <span></span>
          </Box>
          <Stack direction="row" width="60%" justifyContent="right" gap={2}>

            {quickFilterItems.map((item) => <FilterButton
              onClick={item.onClick}
              isSelected={item.isSelected}
            >
              {item.iconText && <span
                style={{
                  color: item.isSelected ? "white" : grey[600],
                  padding: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {item.iconText}
              </span>}

              {item.iconClassName && <span>
                <i
                  className={item.iconClassName}
                  aria-hidden="true"
                  style={{
                    color: item.isSelected ? "white" : grey[600],
                    padding: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></i>
              </span>}
            </FilterButton>)}
          </Stack>
        </Box>
      </Stack>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
