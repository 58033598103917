import axios from "axios";
import { URLS } from "./urls";

let cancelTokenSource;
export const getRepairRequests = data => {
  // Cancel the previous request if it exists
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Previous request cancelled');
  }
  // Create a new cancel token source
  cancelTokenSource = axios.CancelToken.source();

  return axios.post(URLS.GET_AFTERCARE_REPAIR_REQUESTS, data, {
    cancelToken: cancelTokenSource.token
  });
};

export const getWorkOrders = data => axios.post(URLS.GET_AFTERCARE_WORK_ORDERS, data);
export const getRepairRequestStatus = () => axios.get(URLS.GET_REPAIR_REQUESTS_STATUS);
export const createWorkorderReportsZip = data => axios.post(URLS.CREATE_WORKORDER_REPORTS_ZIP, data);
export const uploadExcelFileForImportData = (data,config) => axios.post(URLS.VALIDATE_EXCEL_FOR_IMPORT, data,config);
export const jsonIsAvailableForImport = () => axios.get(URLS.JSON_AVAILABLE_FOR_IMPORT);
export const proceedToImport = () => axios.get(URLS.PROCEED_TO_IMPORT);


