import React from "react";
import { connect } from "react-redux";
import HeaderInternal from "./HeaderInternal";
import { CssBaseline } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { commonActions } from "../_actions";
import { withTranslation } from "react-i18next";
import { getUser, groupBy, history } from "../_helpers";
import { apps, internalLayoutViewConstants, userAccountTypeConstants } from "../_constants";
import MenuBar from "./MenuBar";
import { dossiersActions } from "../_actions/dossiers.actions";
import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import { buyerAndAfterCareRoutes, layoutInternalRoutes } from "../routes";
import { matchPath } from "react-router-dom";

const styles = (theme) => ({
  //common for most pages with fixed height
  layoutRoot: {
    minHeight: "100vh",
  },
  bold: {
    fontWeight: "bold",
  },
  errorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  mainElement: {
    height: "calc(100% - 64px)",
    position: "fixed",
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
    paddingLeft: 72,
    "@media (orientation: portrait)": {
      paddingBottom: 56,
      paddingLeft: 0,
    },
    [theme.breakpoints.down("md")]: {
      height: "calc(100% - 64px)",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 56,
      paddingLeft: 0,
      height: "calc(100% - 56px)",
    },
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      height: "calc(100% - 48px)",
    },
  },
});

class LayoutInternal extends React.Component {
  state = {
    recentUnreadMessages: null,
    recentSavedMessages: null,
    updateSavedMessages: true,
    isOnline: false,
  };

  async componentDidMount() {
    const { dispatch, user, app, selected, all } = this.props;
    window.addEventListener("online", () => {
      this.setState({ isOnline: window.navigator.onLine });
      if (this.props.selected) {
        this.props.dispatch(commonActions.availableSurveyModules(this.props.selected.projectId));
      }
    });
    window.addEventListener("offline", () => {
      this.setState({ isOnline: window.navigator.onLine });
      if (this.props.selected) {
        this.props.dispatch(commonActions.availableSurveyModules(this.props.selected.projectId));
      }
    });
    if (user && user.id) {
      if (app !== null) {
        dispatch(commonActions.getBuildings(app));
      }
    }
    if (selected) {
      if (user.type === userAccountTypeConstants.buyer) {
        this.props.dispatch(dossiersActions.getDossiersByBuildingId(selected.buildingId, selected.projectId));
      } else if (app !== apps.aftercare) {
        this.props.dispatch(dossiersActions.getAllDossiersByProject(selected.projectId));
      }
      this.props.dispatch(commonActions.availableSurveyModules(selected.projectId));
      
      const projectIds = Object.keys(groupBy(all||[], "projectId"));
      if (app === apps.resolverModule) this.props.dispatch(commonActions.getWorkOrderCounts(projectIds, app));
      
      this.UpdateDashboardCount();
    }

    //Change document title here to customize based on user
    //document.title = "Custom title here";
    // store intervalId in the state so it can be accessed later:
    this.setState({ isOnline: window.navigator.onLine });
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearTimeout(this.state.intervalId);
    window.removeEventListener("offline", () => 0);
    window.removeEventListener("online", () => 0);
  }

  componentDidUpdate(prevProps, prevState) {
    const { dashboardCount, dispatch, user, selected, app,all } = this.props;
    if (user && user.id && app !== null && (!prevProps.user || prevProps.user.id !== user.id || prevProps.app !== app)) {
      dispatch(commonActions.getBuildings(app));
    }
    if (!prevProps.selected || (prevProps.selected && this.props.selected && prevProps.selected.buildingId !== this.props.selected.buildingId)) {
      if (user.type === userAccountTypeConstants.buyer && selected) {
        this.props.dispatch(dossiersActions.getDossiersByBuildingId(selected.buildingId, selected.projectId));
      } else if (selected && app !== apps.aftercare) {
        this.props.dispatch(dossiersActions.getAllDossiersByProject(selected.projectId));
      }
      if (selected) {
        this.props.dispatch(commonActions.availableSurveyModules(selected.projectId));
      }
      const projectIds = Object.keys(groupBy(all||[], "projectId"));
      if (app === apps.resolverModule) this.props.dispatch(commonActions.getWorkOrderCounts(projectIds, app));
      
      clearTimeout(this.state.intervalId);
      this.UpdateDashboardCount();
    }
    if (prevProps.dashboardCount.isLoading !== dashboardCount.isLoading && prevProps.dashboardCount.retryAction !== dashboardCount.retryAction && !dashboardCount.isLoading && dashboardCount.retryAction) {
      this.timer();
    }
  }

  timer = () => {
    clearTimeout(this.state.intervalId);
    const intervalId = setTimeout(() => {
      this.UpdateDashboardCount();
      this.CheckLoginStatus();
    }, 10000);
    this.setState({ intervalId });
  };

  CheckLoginStatus() {
    var localUser = getUser();
    if (!localUser) {
      history.push("/login");
    }
  }

  UpdateDashboardCount() {
    const { selected, user, app, dispatch } = this.props;
    if (!!selected && !!user && app !== null) {
      dispatch(commonActions.getDashboardCount(app, selected));
    }
  }

  onScroll = () => {
    window.scrollTo(0, 1);
  };

  render() {
    const { selected, user, classes, t, dashboardCount, app, history } = this.props;
    const { recentUnreadMessages, recentSavedMessages } = this.state;
    let layoutView = internalLayoutViewConstants.Project;
    if (history.location.pathname.startsWith("/object/")) {
      layoutView = internalLayoutViewConstants.Building;
    }

    let totalUnreadChats = 0,
      totalUnreadChatMessages = 0,
      totalCountSavedMessages = 0;

    if (selected && dashboardCount) {
      if (dashboardCount.messageCountPerProject) {
        var objects = dashboardCount.messageCountPerProject.find((x) => x.projectId.toUpperCase() === selected.projectId.toUpperCase());
        totalUnreadChatMessages = objects ? objects.count : 0;
        totalUnreadChats = objects ? objects.noOfChats : 0;
      }

      if (dashboardCount.savedMessagesCountPerProject) {
        var objects = dashboardCount.savedMessagesCountPerProject.find((x) => x.projectId.toUpperCase() === selected.projectId.toUpperCase());
        totalCountSavedMessages = objects ? objects.count : 0;
      }
    }
    const dashboardCountObj = { totalUnreadChats, totalUnreadChatMessages, totalCountSavedMessages };
    const isAfterCareModule = user && (user.type === userAccountTypeConstants.buyer || app === apps.aftercare);
    const currentRoute = (isAfterCareModule ? buyerAndAfterCareRoutes : layoutInternalRoutes).find((route) => matchPath(this.props.history.location.pathname, route));
    return user ? (
      //Id layout-root is used for scroll position
      <div id="layout-root" className={classes.layoutRoot}>
        <CssBaseline />
        <HeaderInternal user={user} layoutView={layoutView} selectedBuilding={selected} app={app} dashboardCount={dashboardCountObj} recentUnreadMessages={recentUnreadMessages} recentSavedMessages={recentSavedMessages} />
        <main className={classes.mainElement} onScroll={this.onScroll}>
          <MenuBar user={user} layoutView={layoutView} selectedBuilding={selected} app={app} history={history} dashboardCount={dashboardCountObj} />

          {/* <Sentry.ErrorBoundary fallback={() => <FallBackPage />}> */}
          {!this.state.isOnline && currentRoute && !currentRoute.accessInOffline ? (
            <Container className={classes.errorContainer}>
              <Typography className={classes.bold} noWrap>
                {t("sync.network.error")}
              </Typography>
            </Container>
          ) : (
            this.props.children
          )}
          {/* </Sentry.ErrorBoundary> */}
        </main>
      </div>
    ) : (
      this.props.children
    );
  }
}

function mapStateToProps(state) {
  const {
    alert,
    buildings,
    dashboardCount,
    app
  } = state;
  const { selected } = buildings;
  return {
    alert,
    selected,
    dashboardCount,
    app
  };
}

const connectedLayout = connect(mapStateToProps)(withTranslation()(withStyles(styles)(LayoutInternal)));
export { connectedLayout as LayoutInternal };
