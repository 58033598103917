import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  getDataTableTextLabels,
  getLocalStorageItem,
  groupBy,
  setLocalStorageItem,
} from "../../_helpers";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { getRRDashboardCounts } from "../../apis/surveyingApis";
import { ArrowBack } from "@mui/icons-material";
import { getRepairRequestStatus } from "../../apis/aftercareApis";
import { userAccountRoleConstants } from "../../_constants";

const Dashboard = (props) => {
  const theme = useTheme();
  const { history } = props;
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [requests, setRequests] = useState([]);
  const [canGoBack, setCanGoBack] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [loading, setLoading] = useState(false);
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const { all = [], loading: buildingLoading } = useSelector(
    (state) => state.buildings
  );
  const app = useSelector((state) => state.app);
  const [isAfterCareEmployee, setIsAfterCareEmployee] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    name: "projectName",
    direction: "desc",
  });
  const [defaultFilters, setDefaultFilters] = useState(
    [...Array(8)].map((a) => [])
  );
  let [columnsVisibility, setColumnsVisibility] = useState([
    { name: "projectNo", visible: true },
    { name: "projectName", visible: true },
    { name: "address", visible: true },
    { name: "newRepairRequests", visible: true },
    { name: "inProgressRepairRequests", visible: true },
    { name: "overdueRepairRequests", visible: true },
    { name: "openWorkOrders", visible: true },
    { name: "overdueWorkOrders", visible: true },
  ]);

  useEffect(() => {
    if (
      all.every(
        (a) =>
          !a.roles ||
          a.roles.length === 0 ||
          a.roles.every(
            (b) =>
              b === userAccountRoleConstants.subContractorSupervisor ||
              b === userAccountRoleConstants.subContractorEmployee
          )
      )
    ) {
      history.push("/home");
    } else
      setIsAfterCareEmployee(
        all.some(
          (a) =>
            a.roles &&
            a.roles.includes(userAccountRoleConstants.aftercareEmployee)
        )
      );
  }, [all]);

  useEffect(() => {
    setRequests([]);
    setLoading(true);
    const projectsGroup = groupBy(all, "projectId");
    const projects = Object.keys(projectsGroup);

    if (history.location.state && history.location.state.filter) {
      let filter = Object.assign([], defaultFilters);
      if (history.location.state.filter) {
        filter = Object.assign(filter, {
          [2]: [history.location.state.filter],
        });
      }
      setCanGoBack(history.location.state.filter);
      const state = { ...history.location.state };
      delete state.filter;
      history.replace({ ...history.location, state });
      setDefaultFilters(filter);
      setLocalStorageItem(`AfterCare_Dashboard_Filters`, filter);
    } else {
      const localFilters = getLocalStorageItem("AfterCare_Dashboard_Filters");
      if (!localFilters)
        setLocalStorageItem("AfterCare_Dashboard_Filters", defaultFilters);
      else setDefaultFilters(localFilters);
    }
    if (!buildingLoading) {
      getRRDashboardCounts(app, projects).then(({ data: response }) => {
        const data = [];
        for (let i = 0; i < projects.length; i++) {
          const currentProject = all.find((p) => p.projectId === projects[i]);
          const project = response.find((p) => p.projectId === projects[i]);
          if (project) {
            const objects = projectsGroup[project.projectId].length;
            const newRepairRequests = project.new;
            const inProgressRepairRequests = project.inProgress;
            const overdueRepairRequests = project.overDue;
            const openWorkOrders = project.workOrdersInProgress;
            const overdueWorkOrders = project.workOrdersOverdue;
            data.push({
              ...currentProject,
              ...project,
              address: project.city,
              objects,
              inProgressRepairRequests,
              newRepairRequests,
              overdueRepairRequests,
              openWorkOrders,
              overdueWorkOrders,
            });
          }
        }
        setRequests(data);
        setLoading(false);
      });
    }
    return () => {
      setRequests([]);
    };
  }, [history.location.key, all, app, buildingLoading]);

  useEffect(() => {
    if (!getLocalStorageItem("AfterCare_Dashboard_ColumnsVisibility")) {
      setLocalStorageItem(
        `AfterCare_Dashboard_ColumnsVisibility`,
        columnsVisibility
      );
    } else {
      setColumnsVisibility(
        getLocalStorageItem("AfterCare_Dashboard_ColumnsVisibility")
      );
    }

    var rowsPerPage = getLocalStorageItem("AfterCare_Dashboard_RowsPerPage");
    if (!!rowsPerPage && rowsPerPage > 0) {
      setRowsPerPage(rowsPerPage);
    }
    getRepairRequestStatus()
      .then((res) => {
        setStatusList(res.data);
      })
      .catch(console.log);
  }, []);

  const isColumnVisible = (columnName) => {
    const isSmallScreen = true;
    var column = columnsVisibility.find((x) => x.name === columnName);
    if (
      !isAfterCareEmployee &&
      (columnName === "openWorkOrders" ||
        columnName === "overdueWorkOrders" ||
        columnName === "overdueRepairRequests")
    )
      return "excluded";
    if (column) {
      return column.visible;
    } else if (isSmallScreen) {
      return false;
    } else {
      return true;
    }
  };

  const columns = [
    {
      name: "projectId",
      options: {
        display: "excluded",
        filter: false,
        download: false,
        print: false,
      },
    },
    {
      name: "projectNo",
      label: t("general.projectNumber"),
      options: {
        display: isColumnVisible("projectNo"),
        filter: false,
      },
    },
    {
      name: "projectName",
      label: t("general.projectName"),
      options: {
        filterList: defaultFilters ? defaultFilters[2] : [],
        display: isColumnVisible("projectName"),
        filter: true,
      },
    },
    {
      name: "address",
      label: t("general.address.city"),
      options: {
        display: isColumnVisible("address"),
        filterList: defaultFilters ? defaultFilters[3] : [],
        filter: true,
      },
    },
    {
      name: "newRepairRequests",
      label: t("site.manager.new.repairrequests"),
      options: {
        display: isColumnVisible("newRepairRequests"),
        filter: false,
      },
    },
    {
      name: "inProgressRepairRequests",
      label: t("site.manager.inprogress.repairrequests"),
      options: {
        display: isColumnVisible("inProgressRepairRequests"),
        filter: false,
      },
    },
    {
      name: "overdueRepairRequests",
      label: t("site.manager.overdue.repairrequests"),
      options: {
        display: isColumnVisible("overdueRepairRequests"),
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ color: "#ff1744" }}>{value}</span>;
        },
      },
    },
    {
      name: "openWorkOrders",
      label: t("site.manager.open.work.orders"),
      options: {
        display: isColumnVisible("openWorkOrders"),
        filter: false,
      },
    },
    {
      name: "overdueWorkOrders",
      label: t("site.manager.overdue.work.orders"),
      options: {
        display: isColumnVisible("overdueWorkOrders"),
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ color: "#ff1744" }}>{value}</span>;
        },
      },
    },
  ];

  let columnOrder = [];
  columnOrder =
    getLocalStorageItem("AfterCare_Dashboard_ColumnOrder") ||
    columns.forEach((column, index) => {
      columnOrder.push(index);
    });

  const options = {
    draggableColumns: { enabled: true },
    filterType: "dropdown",
    responsive: "vertical",
    rowsPerPageOptions: [25, 50, 100],
    rowsPerPage: rowsPerPage,
    download: !isExtraSmallScreen,
    print: !isExtraSmallScreen,
    columnOrder,
    sortOrder,
    onColumnSortChange: (name, direction) => {
      setSortOrder({ name, direction });
    },
    onCellClick: (cellData, { colIndex, dataIndex }, _metaData) => {
      const columnName = columns[colIndex].name;
      const selectedProject = all.filter(
        (x) => x.projectId === requests[dataIndex].projectId
      )[0];
      let status = null;
      if (
        columnName === "newRepairRequests" ||
        columnName === "inProgressRepairRequests"
      ) {
        status = statusList.find((a) => {
          if (columnName === "newRepairRequests") return a.name === "Nieuw";
          else if (columnName === "inProgressRepairRequests")
            return a.name === "In behandeling";
        });
      }
      let url = "/nazorg/meldingen";
      if (columnName.includes("WorkOrder"))
        url = url.replace("meldingen", "werkbonnen");
      history.push({
        pathname: url,
        state: { filter: columnName, selectedProject, status },
      });
    },
    customToolbarSelect: (selectedRows) => <></>,
    onColumnOrderChange: (newColumnOrder, columnIndex, newPosition) => {
      setLocalStorageItem(`AfterCare_Dashboard_ColumnOrder`, newColumnOrder);
    },
    onFilterChange: (identifier, list) => {
      setDefaultFilters(list);
      setLocalStorageItem(`AfterCare_Dashboard_Filters`, list);
    },
    onViewColumnsChange: (changedColumn, action) => {
      if (action == "add") {
        var listToUpdate = columnsVisibility;

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = true;
        } else {
          listToUpdate.push({ name: changedColumn, visible: true });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem(
          `AfterCare_Dashboard_ColumnsVisibility`,
          listToUpdate
        );
      } else if (action == "remove") {
        var listToUpdate = columnsVisibility;

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = false;
        } else {
          listToUpdate.push({ name: changedColumn, visible: false });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem(
          `AfterCare_Dashboard_ColumnsVisibility`,
          listToUpdate
        );
      }
    },
    textLabels: getDataTableTextLabels(t, loading),
    onChangeRowsPerPage: (rowsPerPage) => {
      setRowsPerPage(rowsPerPage);
    },
  };

  const handleGoBack = () => {
    const filters = [...Array(11)].map((a) => []);
    setDefaultFilters(filters);
    setLocalStorageItem(`AfterCare_Dashboard_Filters`, filters);
    history.goBack();
  };

  if (buildingLoading)
    return (
      <div className={classes.loader}>
        <CircularProgress size={25} />
      </div>
    );
  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <MUIDataTable
        className={classes.dataTable}
        title={
          <div className={classes.headerTitleContainer}>
            {canGoBack && (
              <IconButton
                aria-label="GoBack"
                color="inherit"
                onClick={handleGoBack}
                size="large"
              >
                <ArrowBack />
              </IconButton>
            )}
            <div className={classes.headerTitleSubContainer}>
              <Typography
                style={{ paddingLeft: canGoBack ? 0 : 24 }}
                className={classes.headerTitle}
                variant="h6"
              >
                {t("aftercare.dashboard")}
              </Typography>
            </div>
          </div>
        }
        data={requests}
        columns={columns}
        options={options}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      paddingLeft: theme.spacing(0),
      "& > div": {
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTableCell-root": {
      "& .MuiButton-root > span div ": {
        textAlign: "start",
      },
      padding: theme.spacing(0, 0.5, 0, 0),
      cursor: "pointer",
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "left",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
        "& .MuiButtonBase-root": {
          textAlign: "left"
        }
      },
    },
  },
  grow: {
    flexGrow: 1,
  },
  fullWidth: {
    width: "100%",
  },
  headerTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerTitleSubContainer: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  headerTitle: {
    color: theme.palette.grey[800],
    fontWeight: 500,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    // fontSize: 16,
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    whiteSpace: "nowrap",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

export { Dashboard };
