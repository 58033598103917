import React from "react";
import { connect } from "react-redux";
import { alpha } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import { AppBar, Backdrop, Button, Card, CardMedia, CircularProgress, Collapse, Container, Dialog, DialogActions, Divider, Fade, Grid, IconButton, Modal, Slide, Toolbar, Tooltip, Typography } from "@mui/material";
import { withTheme } from "@mui/styles";
import { Assignment, Build, Cancel, CheckCircle, Close, Contacts, ExpandLess, ExpandMore, Home, ListAlt, PriorityHigh, Schedule } from "@mui/icons-material";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { authHeader, formatDate, getDataTableTextLabels, nl2br } from "../../../_helpers";
import MUIDataTable from "mui-datatables";
import ContactCard from "../../ConstructionQuality/RepairRequestContactCard";
import { URLS } from "../../../apis/urls";
import { getDrawingFiles } from "../../../apis/dossiersApi";
import RepairRequestForm from "../RepairRequestForm";
import { dossiersConstants } from "../../../_constants";
import {
  getEmailsForRepairRequest,
  getOrganizationsByProject,
  getProductsServices,
  getRepairRequestCarryOutAsTypeList,
  getRepairRequestCauseList,
  getRepairRequestCauserList,
  getRepairRequestDetails,
  getRepairRequestLocations,
  getRepairRequestNatureList,
  getRepairRequestTypeList,
  getSettlementTerms,
} from "../../../apis/surveyingApis";
import OfflineFileWrapper from "../../../components/OfflineFileWrapper";
import { themeBreakpointUp } from "../../../_helpers/breakpointHelper";

const { webApiUrl } = window.appConfig;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = (theme) => ({
  locationOn: {
    position: "absolute",
    zIndex: 1,
  },
  locationMarkPreview: {
    position: "absolute",
  },
  locationPreview: {
    maxWidth: "100%",
    cursor: "pointer",
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `1px 1px 3px ${theme.palette.grey[400]}`,
    objectFit: "contain",
  },
  locationContainer: {
    maxWidth: "100%",
    width: "100%",
    position: "relative",
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    paddingRight: 0,
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  innerContainer: {
    padding: 0,
    width: "100%",
    margin: 0,
  },
  gridContent: {
    padding: theme.spacing(0.5),
  },
  block: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 1, 3),
    "&.collapsed": {
      paddingBottom: theme.spacing(1),
    },
  },
  expandCollapseButton: {
    padding: 0,
  },
  subHeader: {
    fontSize: 14,
    padding: theme.spacing(0),
    "& svg": {
      fontSize: 20,
    },
  },
  infoGrid: {
    //padding: theme.spacing(0.5, 2),
  },
  infoGridRow: {
    "& > div": {
      padding: theme.spacing(0.5, 2),
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "& .MuiInputLabel-outlined": {
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  imageGallery: {
    width: "100%",
    maxHeight: "15vw",
    height: "calc(100% + 4px)",
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down("xl")]: {
      maxHeight: "19vw",
    },
    [theme.breakpoints.down("xl")]: {
      maxHeight: "19vw",
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: "28vw",
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: "56vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "85vw",
    },
  },
  dropzoneContainer: {
    width: "25%",
    float: "left",
    padding: theme.spacing(0.5),
    margin: "-2px auto -4px",
    [theme.breakpoints.down("xl")]: {
      width: "33.33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  galleryImageTile: {
    width: "25%",
    float: "left",
    padding: theme.spacing(0.5),
    position: "relative",

    "& > button": {
      position: "absolute",
      top: 0,
      right: 0,
    },
    "& > div": {
      width: "100%",
      padding: "50% 0px",
      height: 0,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("xl")]: {
      width: "33.33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  bigAvatar: {
    margin: "auto",
    width: 120,
    height: 120,
  },
  halfWidth: {
    width: "50%",
    verticalAlign: "top",
  },
  button: {
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  textField: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("xs")]: {
      width: 350,
      maxWidth: "100%",
    },
  },
  thumbnail: {
    width: 50,
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "rgba(0,0,0,0.1)",
    "&.big": {
      width: "100%",
      height: 0,
      padding: "50%",
      cursor: "pointer",
      //borderRadius: theme.spacing(1)
    },
  },
  warning: {
    color: theme.palette.warning.main,
  },
  thumbnailOrg: {
    width: "calc(100% - 16px)",
    margin: theme.spacing(-1, 0),
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize: "contain",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    background: "none",
    boxShadow: "none",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      padding: theme.spacing(0),
      minHeight: "fit-content",
      "& .header svg": {
        fontSize: 30,
      },
      "& > div": {
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
      "& div button": {
        padding: 0,
      },
    },
    "& .MuiTable-root": {
      "& caption": {
        display: "none",
      },
      //marginBottom: theme.spacing(0.5)
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(0, 0.5, 0, 0),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
  mainLoaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: theme.spacing(1.25),
    height: "50vh",
  },
  addLocationBtn: {
    marginLeft: theme.spacing(1),
    marginTop: 2,
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  locationInfoContainer: {
    display: "flex",
    alignItems: "center",
  },
  closeButton: {
    marginRight: 5,
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    // padding: theme.spacing(0)
  },
  iconStyle: {
    fill: "white",
    width: "1em",
    height: "1em",
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
});

class Page extends React.Component {
  state = {
    attachmentIndex: null,
    repairRequest: null,
    building: null,
    sendReminderCCEmailList: [],
    sendReminderCCEmailValid: true,
    workorderPopoverOpenLoader: false,
    miniMapCoordinates: { left: 0, top: 0 },
    drawingDossiersList: [],
    currentPage: 1,
    locationPinImageVersion: new Date().getTime(),
    expandExtraInfo: ["GeneralRepairRequest", "Status", "ObjectInfo", "ContactInfo", "WorkOrder", "ExtraInfo"],
  };

  componentDidMount() {
    this.GetRepairRequestLocations();
    this.GetRepairRequestCarryOutAsTypeList();
    this.GetProductServices();
    this.GetSettlementTerm();
    this.GetRepairRequestTypeList();
    this.GetRepairRequestNatureList();
    this.GetRepairRequestCauseList();
    this.GetRepairRequestCauserList();
    this.GetOrganisations();
    this.GetRepairRequestDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selected || prevProps.selected.buildingId !== this.props.selected.buildingId) {
      this.GetRepairRequestDetails();
    }
    if (!prevProps.selected || prevProps.selected.projectId !== this.props.selected.projectId) {
      this.GetOrganisations();
    }
    if ((!prevState.repairRequest && !!this.state.repairRequest) || (!!prevState.repairRequest && !!this.state.repairRequest && prevState.repairRequest.requestId !== this.state.repairRequest.requestId)) {
      if (this.props.selected && this.state.repairRequest.buildingId && this.props.selected.buildingId.toUpperCase() !== this.state.repairRequest.buildingId.toUpperCase()) {
        var selectedItem = this.props.allBuildings.find((x) => x.buildingId.toUpperCase() === this.state.repairRequest.buildingId.toUpperCase());
        if (selectedItem.projectId !== this.props.selected.projectId) {
          // history.push('/werk/' + this.props.selected.projectNo + '/kwaliteitsborging');
        } else {
          this.setState({ building: selectedItem });
        }
      } else if (!this.state.repairRequest.buildingId && this.state.repairRequest.projectId) {
        var selectedItem = this.props.allBuildings.find((x) => x.projectId.toUpperCase() === this.state.repairRequest.projectId.toUpperCase());
        if (selectedItem.projectId !== this.props.selected.projectId) {
          // history.push('/werk/' + this.props.selected.projectNo + '/kwaliteitsborging');
        } else {
          this.setState({ building: selectedItem });
        }
      } else {
        this.setState({ building: this.props.selected });
      }
    }
  }

  GetRepairRequestLocations() {
    this.setState({
      locations: null,
    });

    getRepairRequestLocations().then((res) => {
      this.setState({ locations: res.data });
    });
  }

  GetProductServices() {
    this.setState({
      productServices: null,
    });

    getProductsServices().then((res) => {
      this.setState({ productServices: res.data });
    });
  }

  GetSettlementTerm() {
    this.setState({
      terms: null,
    });

    getSettlementTerms().then((res) => {
      this.setState({ terms: res.data });
    });
  }

  GetRepairRequestCarryOutAsTypeList() {
    this.setState({
      types: null,
    });

    getRepairRequestCarryOutAsTypeList().then((res) => {
      this.setState({ carryOutAsTypeList: res.data });
    });
  }

  GetRepairRequestTypeList() {
    this.setState({
      types: null,
    });

    getRepairRequestTypeList().then((res) => {
      this.setState({ typeList: res.data });
    });
  }

  GetRepairRequestNatureList() {
    this.setState({
      natureList: null,
    });

    getRepairRequestNatureList().then((res) => {
      this.setState({ natureList: res.data });
    });
  }

  GetRepairRequestCauseList() {
    this.setState({
      causeList: null,
    });

    getRepairRequestCauseList().then((res) => {
      this.setState({ causeList: res.data });
    });
  }

  GetRepairRequestCauserList() {
    this.setState({
      causerList: null,
    });

    getRepairRequestCauserList().then((res) => {
      this.setState({ causerList: res.data });
    });
  }

  GetOrganisations() {
    const { selected } = this.props;
    if (selected) {
      getOrganizationsByProject(selected.projectId).then((res) => {
        this.setState({ organisationList: Array.isArray(res.data) ? res.data : res.data && [res.data] });
      });
    }
  }

  GetEmailsForRepairRequest() {
    const { t } = this.props;
    const { repairRequest } = this.state;
    if (repairRequest && repairRequest.completed !== true && (repairRequest.isAllWorkOrderCompleted === true || repairRequest.isAllWorkOrderDeclined === true)) {
      getEmailsForRepairRequest(repairRequest.requestId)
        .then((data) => {
          const emails = data.data;
          let toEmail = null;
          let ccEmails = [];
          if (emails.reporterEmails && emails.reporterEmails.length > 0) {
            toEmail = "reporter";
          }

          const emailModel = [
            {
              key: "reporter",
              title: t("general.reporter"),
              emails: emails.reporterEmails,
            },
            {
              key: "buyer",
              title: t("general.objectUser"),
              emails: emails.buyerEmails,
            },
            {
              key: "client",
              title: t("general.client"),
              emails: emails.clientEmails,
            },
            { key: "vve", title: t("general.vvE"), emails: emails.vvEEmails },
            {
              key: "vveadmin",
              title: t("general.vvEAdministrator"),
              emails: emails.vvEAdministratorEmails,
            },
            {
              key: "propertymanager",
              title: t("general.propertyManager"),
              emails: emails.propertyManagerEmails,
            },
            {
              key: "resolver",
              title: t("general.resolver"),
              emails: emails.resolverEmails,
            },
          ];
          this.setState({ emails, emailModel, toEmail, ccEmails });
        })
        .catch((er) => {
          console.log(er);
        });
    }
  }

  updateRepairRequestLocationPinPoint = (reload) => {
    if (reload) {
      // const { repairRequestId } = ;
      const { selected } = this.props;
      this.setState({ repairRequestLoading: true });
      const url = webApiUrl + "api/RepairRequest/AddOrUpdateDrawingPinLocation/" + encodeURI(this.props.requestId);
      // const formData = new FormData()
      // formData.append('DrawingPinLocationModel.DossierFileId', selectedDrawingFiles.dossierFileId);
      // formData.append('DrawingPinLocationModel.originalDossierFileId', selectedDrawingFiles.dossierFileId);
      // formData.append('DrawingPinLocationModel.PageNumber', currentPage);
      // formData.append('DrawingPinLocationModel.Xrel', locationCoordinates.xRel.toFixed(3));
      // formData.append('DrawingPinLocationModel.Yrel', locationCoordinates.yRel.toFixed(3));
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify({
          dossierFileId: this.state.selectedDrawingFiles.dossierFileId,
          //    "originalDossierFileId": this.state.repairRequest.drawingPinLocation.originalDossierFileId,
          pageNumber: this.state.currentPage,
          xrel: +this.state.drawingPinLocationCord.xRel.toFixed(5),
          yrel: +this.state.drawingPinLocationCord.yRel.toFixed(5),
        }),
      };
      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then(() => {
          this.setState({
            openRepairRequestMap: false,
            isCreateRepairRequest: false,
          });
          this.GetRepairRequestDetails(true);
        })
        .catch(() => {
          // this.setState({ repairRequestLoading: false });
          this.setState({
            openRepairRequestMap: false,
            isCreateRepairRequest: false,
          });
        });
    } else
      this.setState({
        openRepairRequestMap: false,
        isCreateRepairRequest: false,
      });
  };

  GetRepairRequestDetails(update = false) {
    const { selected, requestId } = this.props;
    if (selected && requestId) {
      this.setState({ repairRequestLoading: true });

      if (update === false) {
        this.setState({
          repairRequest: null,
        });
      }

      getRepairRequestDetails(requestId)
        .then((res) => {
          if (res.data.number) {
            this.setState({
              locationPinImageVersion: new Date().getTime(),
              repairRequest: res.data,
              completionTextToEdit: res.data.completionText,
              rejectionTextToEdit: res.data.rejectionText,
              repairRequestLoading: false,
            });
            //this.GetRepairRequestContactInfo();
            this.getDrawingDossierFiles(res.data);
            this.GetEmailsForRepairRequest();
          }
        })
        .catch(() => {
          this.setState({ repairRequestLoading: false });
        });
    }
  }

  getDrawingDossierFiles = (request) => {
    if (request) {
      const { projectId, buildingId, drawingPinLocation } = request;
      this.setState({ drawingDossiersList: [] });
      this.props.dispatch({
        type: dossiersConstants.GET_DRAWING_FILES_LOADING,
      });
      getDrawingFiles(projectId, buildingId)
        .then((res) => {
          res = res.data;
          this.setState({ drawingDossiersList: res });
          if (res.length) {
            const selectedFile = res.find((p) => p.dossierFileId === (drawingPinLocation && drawingPinLocation.dossierFileId)) || res[0];
            this.setState({
              currentPage: drawingPinLocation ? drawingPinLocation.pageNumber : 1,
              selectedDrawingFiles: {
                ...selectedFile,
                groupBy: selectedFile.isInternal ? "Internal" : "External",
              },
            });
          }
          this.props.dispatch({
            type: dossiersConstants.GET_DRAWING_FILES_SUCCESS,
            files: res,
          });
        })
        .catch((error) => {
          this.props.dispatch({
            type: dossiersConstants.GET_DRAWING_FILES_ERROR,
            error,
          });
        });
    }
  };

  handleImageModalOpen = (index) => {
    this.setState({ attachmentIndex: index });
  };

  handleImageModalClose = () => {
    this.setState({ attachmentIndex: null });
  };

  isProductServiceRequired() {
    const carryOutAsTypeData = this.state.carryOutAsTypeList && this.state.carryOutAsTypeList.find((p) => p.id === this.state.repairRequest.carryOutAsTypeId);
    if (carryOutAsTypeData) return !["Inspectie", "Voorschouw", "Opleverpunt", "Onderhoudstermijn"].some((p) => p === carryOutAsTypeData.name);
  }

  renderEditTextbox(title, key, value, multi = false) {
    const { classes, t } = this.props;
    const { repairRequest, updating } = this.state;
    return <div style={{ position: "relative" }}>{value && nl2br(value)}</div>;
  }

  handleRemoveAttachFile(attachmentId) {
    const { t } = this.props;
    const { repairRequest } = this.state;
    if (repairRequest && repairRequest.completed !== true) {
      const url = webApiUrl + `api/RepairRequest/DeleteRepairRequestAttachment/${repairRequest.requestId}/${attachmentId}`;
      const requestOptions = {
        method: "DELETE",
        headers: authHeader("application/json"),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((res) => {
          if (res.success === true) {
            this.GetRepairRequestDetails(true);
          } else {
            alert(t("general.api.error"));
          }
        })
        .catch(() => {
          alert(t("general.api.error"));
        });
    }
  }

  blockGeneralRepairRequest() {
    const { t, classes, theme } = this.props;
    const { repairRequest, locations, expandExtraInfo } = this.state;
    const isExpanded = expandExtraInfo.includes("GeneralRepairRequest");
    const isSmUpScreen = themeBreakpointUp(window, theme, "md");

    return (
      <div className={!isExpanded || !isSmUpScreen ? clsx(classes.block, "collapsed") : classes.block}>
        <Grid container alignItems="center">
          <Grid item className={classes.grow}>
            <Typography component="h2" variant="h6" className={classes.subHeader}>
              <Build color="primary" /> &nbsp;
              {t("general.repairRequest")}
              &nbsp;
              {repairRequest ? repairRequest.number : ""}
            </Typography>
          </Grid>
          {!isSmUpScreen && (
            <Grid item>
              <Tooltip title={isExpanded ? t("general.collapse") : t('general.expand"')}>
                <IconButton
                  className={classes.expandCollapseButton}
                  aria-label="expand"
                  onClick={() =>
                    this.setState({
                      expandExtraInfo: isExpanded ? expandExtraInfo.filter((p) => p !== "GeneralRepairRequest") : expandExtraInfo.concat("GeneralRepairRequest"),
                    })
                  }
                  size="large"
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <Collapse in={isExpanded || isSmUpScreen} timeout="auto" unmountOnExit>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.date") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {repairRequest.date && formatDate(new Date(repairRequest.date))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {(isNaN(repairRequest.receivedVia) !== true || repairRequest.receivedVia !== 2) && (
              <>
                <Grid item xs={12}>
                  <Grid container className={classes.infoGridRow}>
                    <Grid item xs={6}>
                      {t("repairRequest.adoptedBy.label") + ":"}
                    </Grid>
                    <Grid item xs={6}>
                      {repairRequest.adoptedBy && repairRequest.adoptedBy}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.reporter") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {repairRequest.reporter && repairRequest.reporter.name}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.repairRequestReceivedVia") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {repairRequest.receivedVia !== null && repairRequest.receivedVia >= 0 && repairRequest.receivedVia <= 7 && t("repairRequest.receivedvia." + repairRequest.receivedVia)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.description") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {this.renderEditTextbox(t("general.description"), "desc", repairRequest.desc)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.originalRepairRequest") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {nl2br(repairRequest.detailDesc)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {!!repairRequest.preferredAppointmentTime && repairRequest.preferredAppointmentTime.trim() !== "" && (
              <>
                <Grid item xs={12}>
                  <Grid container className={classes.infoGridRow}>
                    <Grid item xs={6}>
                      {t("repairRequest.preferredAppointmentTime") + ":"}
                    </Grid>
                    <Grid item xs={6}>
                      {repairRequest.preferredAppointmentTime}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.location") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {(locations.find((x) => x.id === repairRequest.locationId) || {}).name}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={12}>
                  {t("general.images") + ":"}
                </Grid>
              </Grid>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={12}>
                  <div className={classes.imageGallery}>
                    {repairRequest.attachments &&
                      repairRequest.attachments.length > 0 &&
                      repairRequest.attachments.map((file, index) => (
                        <div className={classes.galleryImageTile}>
                          <OfflineFileWrapper url={`${URLS.GET_ATTACHMENT_THUMBNAIL}${file.attachmentId}`} id={file.attachmentId}>
                            {(url) => (
                              <div
                                onClick={() => this.handleImageModalOpen(index)}
                                style={{
                                  backgroundImage: `url(${url})`,
                                }}
                              />
                            )}
                          </OfflineFileWrapper>
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Collapse>
      </div>
    );
  }

  blockStatus() {
    const { t, classes, theme } = this.props;
    const { repairRequest, expandExtraInfo } = this.state;
    const isExpanded = expandExtraInfo.includes("Status");
    const isSmUpScreen = themeBreakpointUp(window, theme, "md");
    return (
      <div className={!isExpanded || !isSmUpScreen ? clsx(classes.block, "collapsed") : classes.block}>
        <Grid container alignItems="center">
          <Grid item className={classes.grow}>
            <Typography component="h2" variant="h6" className={classes.subHeader}>
              <Schedule color="primary" /> &nbsp;
              {t("general.status")}
            </Typography>
          </Grid>
          {!isSmUpScreen && (
            <Grid item>
              <Tooltip title={isExpanded ? t("general.collapse") : t("general.expand")}>
                <IconButton
                  className={classes.expandCollapseButton}
                  aria-label="expand"
                  onClick={() =>
                    this.setState({
                      expandExtraInfo: isExpanded ? expandExtraInfo.filter((p) => p !== "Status") : expandExtraInfo.concat("Status"),
                    })
                  }
                  size="large"
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <Collapse in={isExpanded || isSmUpScreen} timeout="auto" unmountOnExit>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.status") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {repairRequest.status && repairRequest.status}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("workOrders.completedOn") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {repairRequest.settledOn && formatDate(new Date(repairRequest.settledOn))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {repairRequest.completed === true && (
              <>
                {!!repairRequest.completionText && repairRequest.completionText.trim() !== "" && (
                  <>
                    <Grid item xs={12}>
                      <Grid container className={classes.infoGridRow}>
                        <Grid item xs={6}>
                          {t("workOrders.solution") + ":"}
                        </Grid>
                        <Grid item xs={6}>
                          {nl2br(repairRequest.completionText)}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {!!repairRequest.rejectionText && repairRequest.rejectionText.trim() !== "" && (
                  <>
                    <Grid item xs={12}>
                      <Grid container className={classes.infoGridRow}>
                        <Grid item xs={6}>
                          {t("repairRequest.reasonOfRejection") + ":"}
                        </Grid>
                        <Grid item xs={6}>
                          {nl2br(repairRequest.rejectionText)}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Collapse>
      </div>
    );
  }

  blockObjectInfo() {
    const { t, classes, theme } = this.props;
    const { repairRequest, building, expandExtraInfo } = this.state;
    const isExpanded = expandExtraInfo.includes("ObjectInfo");
    const isSmUpScreen = themeBreakpointUp(window, theme, "md");
    return (
      <div className={!isExpanded || !isSmUpScreen ? clsx(classes.block, "collapsed") : classes.block}>
        <Grid container alignItems="center">
          <Grid item className={classes.grow}>
            <Typography component="h2" variant="h6" className={classes.subHeader}>
              <Home color="primary" /> &nbsp;
              {t("general.objectInformation")}
            </Typography>
          </Grid>
          {!isSmUpScreen && (
            <Grid item>
              <Tooltip title={isExpanded ? t("general.collapse") : t("general.expand")}>
                <IconButton
                  className={classes.expandCollapseButton}
                  aria-label="expand"
                  onClick={() =>
                    this.setState({
                      expandExtraInfo: isExpanded ? expandExtraInfo.filter((p) => p !== "ObjectInfo") : expandExtraInfo.concat("ObjectInfo"),
                    })
                  }
                  size="large"
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Collapse in={isExpanded || isSmUpScreen} timeout="auto" unmountOnExit>
          {
            <Grid container>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("general.streetAndHouseNumber") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {repairRequest.address &&
                      (repairRequest.address.street ? repairRequest.address.street : "") +
                        " " +
                        (repairRequest.address.houseNo ? repairRequest.address.houseNo : "") +
                        (repairRequest.address.houseNoAddition ? " " + repairRequest.address.houseNoAddition : "")}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("general.address.postcodeAndCity") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {repairRequest.address && (
                      <>
                        {repairRequest.address.postcode ? repairRequest.address.postcode + " " : ""}
                        &nbsp;
                        {repairRequest.address.place ? repairRequest.address.place : ""}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("general.buildingNumber") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {building && building.buildingNoIntern}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("general.deliveryDate") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {repairRequest.deliveryDate && formatDate(new Date(repairRequest.deliveryDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("repairRequests.secondSignatureDate") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {repairRequest.secondSignatureDate && formatDate(new Date(repairRequest.secondSignatureDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("general.endOfMaintenancePeriod") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {repairRequest.maintenanceEndDate && formatDate(new Date(repairRequest.maintenanceEndDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.endOfWarrantyPeriod") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {repairRequest.warrantyEndDate && formatDate(new Date(repairRequest.warrantyEndDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          }
        </Collapse>
      </div>
    );
  }

  blockContactInfo() {
    const { t, classes, theme } = this.props;
    const { repairRequest, expandExtraInfo } = this.state;
    const isExpanded = expandExtraInfo.includes("ContactInfo");
    const isSmUpScreen = themeBreakpointUp(window, theme, "md");
    return (
      <div className={!isExpanded || !isSmUpScreen ? clsx(classes.block, "collapsed") : classes.block}>
        <Grid container alignItems="center">
          <Grid item className={classes.grow}>
            <Typography component="h2" variant="h6" className={classes.subHeader}>
              <Contacts color="primary" /> &nbsp;
              {t("general.contactDetails")}
            </Typography>
          </Grid>
          {!isSmUpScreen && (
            <Grid item>
              <Tooltip title={isExpanded ? t("general.collapse") : t("general.expand")}>
                <IconButton
                  className={classes.expandCollapseButton}
                  aria-label="expand"
                  onClick={() =>
                    this.setState({
                      expandExtraInfo: isExpanded ? expandExtraInfo.filter((p) => p !== "ContactInfo") : expandExtraInfo.concat("ContactInfo"),
                    })
                  }
                  size="large"
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <Collapse in={isExpanded || isSmUpScreen} timeout="auto" unmountOnExit>
          <Grid container spacing={1}>
            {repairRequest && repairRequest.contactInfo && (
              <>
                {repairRequest.contactInfo.buyer && (
                  <>
                    {repairRequest.contactInfo.buyer.type === 0 && (
                      <>
                        <Grid item xs={12}>
                          <ContactCard
                            hidePointOfContactCheckbox={repairRequest.completed}
                            updatePointOfContact={this.updatePointOfContact}
                            object={repairRequest.contactInfo.buyer.p1}
                            pointOfContactType={0}
                            selectedPointOfContactType={repairRequest.pointOfContact}
                            subTitle={t("general.buyer1")}
                          />
                        </Grid>
                        {repairRequest.contactInfo.buyer.p2 && (
                          <Grid item xs={12}>
                            <ContactCard
                              hidePointOfContactCheckbox={repairRequest.completed}
                              updatePointOfContact={this.updatePointOfContact}
                              object={repairRequest.contactInfo.buyer.p2}
                              pointOfContactType={0}
                              selectedPointOfContactType={repairRequest.pointOfContact}
                              subTitle={t("general.buyer2")}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                    {repairRequest.contactInfo.buyer.type === 1 && (
                      <Grid item xs={12}>
                        <ContactCard
                          hidePointOfContactCheckbox={repairRequest.completed}
                          updatePointOfContact={this.updatePointOfContact}
                          object={repairRequest.contactInfo.buyer.org}
                          isOrg
                          pointOfContactType={0}
                          selectedPointOfContactType={repairRequest.pointOfContact}
                          subTitle={t("general.buyerOrganization")}
                        />
                      </Grid>
                    )}
                  </>
                )}
                {repairRequest.contactInfo.client && (
                  <Grid item xs={12}>
                    <ContactCard
                      hidePointOfContactCheckbox={repairRequest.completed}
                      updatePointOfContact={this.updatePointOfContact}
                      object={repairRequest.contactInfo.client}
                      isOrg
                      pointOfContactType={1}
                      selectedPointOfContactType={repairRequest.pointOfContact}
                      subTitle={t("repairRequest.client")}
                    />
                  </Grid>
                )}
                {repairRequest.contactInfo.vvE && (
                  <Grid item xs={12}>
                    <ContactCard
                      hidePointOfContactCheckbox={repairRequest.completed}
                      updatePointOfContact={this.updatePointOfContact}
                      object={repairRequest.contactInfo.vvE}
                      isOrg
                      pointOfContactType={2}
                      selectedPointOfContactType={repairRequest.pointOfContact}
                      subTitle={t("general.vvE")}
                    />
                  </Grid>
                )}
                {repairRequest.contactInfo.vvEAdministrator && (
                  <Grid item xs={12}>
                    <ContactCard
                      hidePointOfContactCheckbox={repairRequest.completed}
                      updatePointOfContact={this.updatePointOfContact}
                      object={repairRequest.contactInfo.vvEAdministrator}
                      isOrg
                      pointOfContactType={3}
                      selectedPointOfContactType={repairRequest.pointOfContact}
                      subTitle={t("general.vvEAdministrator")}
                    />
                  </Grid>
                )}
                {repairRequest.contactInfo.propertyManagers &&
                  repairRequest.contactInfo.propertyManagers.map((propertyManager, index) => (
                    <Grid key={index} item xs={12}>
                      <ContactCard
                        hidePointOfContactCheckbox={repairRequest.completed}
                        updatePointOfContact={(value) => {
                          let result = value;
                          if (!!propertyManager.relationId) {
                            result = value + "," + propertyManager.organisatonId + "," + propertyManager.relationId;
                          }
                          this.updatePointOfContact(result);
                        }}
                        object={propertyManager}
                        isOrg
                        pointOfContactType={4}
                        selectedPointOfContactType={index === 0 && repairRequest.pointOfContact}
                        subTitle={t("general.propertyManager")}
                      />
                    </Grid>
                  ))}
                {repairRequest.contactInfo.employee && (
                  <Grid item xs={12}>
                    <ContactCard
                      hidePointOfContactCheckbox={repairRequest.completed}
                      updatePointOfContact={this.updatePointOfContact}
                      object={repairRequest.contactInfo.employee}
                      isOrg
                      pointOfContactType={5}
                      selectedPointOfContactType={repairRequest.pointOfContact}
                      subTitle={t("general.employee")}
                    />
                  </Grid>
                )}
                {repairRequest.resolvers &&
                  repairRequest.resolvers.map((resolver, index) => (
                    <Grid key={index} item xs={12}>
                      <ContactCard
                        hidePointOfContactCheckbox
                        object={resolver}
                        isOrg
                        pointOfContactType={-1}
                        subTitle={t("general.resolver") + " - " + t("general.workOrder") + " " + (!resolver.workOrderNumber ? "(" + t("general.draft") + ")" : resolver.workOrderNumber)}
                      />
                    </Grid>
                  ))}
              </>
            )}
          </Grid>
        </Collapse>
      </div>
    );
  }

  blockRepairRequestWorkOrder() {
    const { t, classes, theme } = this.props;
    const { repairRequest, productServices, carryOutAsTypeList, terms, expandExtraInfo } = this.state;
    const isExpanded = expandExtraInfo.includes("WorkOrder");
    const isSmUpScreen = themeBreakpointUp(window, theme, "md");
    const selectedProductService = productServices && repairRequest && productServices.find((x) => x.id === repairRequest.productServiceId);
    const selectedSubProductService = selectedProductService && selectedProductService.subProductServiceList && selectedProductService.subProductServiceList.find((x) => x.id === repairRequest.subProductService1Id);
    const selectedSubProductService1 = selectedSubProductService && selectedSubProductService.subProductServiceList && selectedSubProductService.subProductServiceList.find((x) => x.id === repairRequest.subProductService2Id);
    const carryOutAsType = (repairRequest && carryOutAsTypeList && carryOutAsTypeList.find((x) => x.id === repairRequest.carryOutAsTypeId)) || {};
    const term = repairRequest && terms && terms.find((x) => x.id === repairRequest.completionTermId);

    return (
      <div className={!isExpanded || !isSmUpScreen ? clsx(classes.block, "collapsed") : classes.block}>
        <Grid container alignItems="center">
          <Grid item className={classes.grow}>
            <Typography component="h2" variant="h6" className={classes.subHeader}>
              <Assignment color="primary" /> &nbsp;
              {t("general.additionalInformation")}
            </Typography>
          </Grid>
          {!isSmUpScreen && (
            <Grid item>
              <Tooltip title={isExpanded ? t("general.collapse") : t("general.expand")}>
                <IconButton
                  className={classes.expandCollapseButton}
                  aria-label="expand"
                  onClick={() =>
                    this.setState({
                      expandExtraInfo: isExpanded ? expandExtraInfo.filter((p) => p !== "WorkOrder") : expandExtraInfo.concat("WorkOrder"),
                    })
                  }
                  size="large"
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Collapse in={isExpanded || isSmUpScreen} timeout="auto" unmountOnExit>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.productOrService") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {" "}
                  {productServices && selectedProductService ? `${selectedProductService.code}-${selectedProductService.description}` : ""}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {selectedProductService && !!selectedProductService.subProductServiceList && selectedProductService.subProductServiceList.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Grid container className={classes.infoGridRow}>
                    <Grid item xs={6}>
                      {t("repairRequest.subProductServiceWithNumber", {
                        Number: 1,
                      }) + ":"}
                    </Grid>
                    <Grid item xs={6}>
                      {selectedProductService && selectedSubProductService && selectedSubProductService.description}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {selectedSubProductService && selectedSubProductService.subProductServiceList && selectedSubProductService.subProductServiceList.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Grid container className={classes.infoGridRow}>
                        <Grid item xs={6}>
                          {t("repairRequest.subProductServiceWithNumber", {
                            Number: 2,
                          }) + ":"}
                        </Grid>
                        <Grid item xs={6}>
                          {selectedSubProductService && selectedSubProductService1 && selectedSubProductService1.description}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
              </>
            )}
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.type") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {carryOutAsType.name}{" "}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("workOrders.workOrderText") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {this.renderEditTextbox(t("workOrders.workOrderText"), "workordertext", repairRequest.workOrderText, true)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.priority") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {(!repairRequest.priority || repairRequest.priority < 0 || repairRequest.priority > 2) && t("repairRequest.priority." + repairRequest.priority)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.completionTerm") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {term && term.description + " (" + term.noOfDays + " " + (term.workingDays === true ? t("repairRequest.businessDays") : t("repairRequest.calenderDays")) + ")"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.targetCompletionDate.label") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {repairRequest.targetCompletionDate && formatDate(new Date(repairRequest.targetCompletionDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Collapse>
      </div>
    );
  }

  blockExtraInfo() {
    const { t, classes, theme } = this.props;
    const { repairRequest, typeList, natureList, causeList, causerList, organisationList, expandExtraInfo } = this.state;
    const type = repairRequest && typeList && typeList.length > 0 && typeList.find((x) => x.id === repairRequest.typeId);
    const nature = repairRequest && natureList && natureList.length > 0 && natureList.find((x) => x.id === repairRequest.natureId);
    const cause = repairRequest && causeList && causeList.length > 0 && causeList.find((x) => x.id === repairRequest.causeId);
    const causer = repairRequest && causerList && causerList.length > 0 && causerList.find((x) => x.id === repairRequest.causerId);
    const organisation = repairRequest && repairRequest.isCauserOrganisationVisible === true && organisationList && organisationList.length > 0 && organisationList.find((x) => x.id === repairRequest.causerOrganisationId);
    const isExpanded = expandExtraInfo.includes("ExtraInfo");
    const isSmUpScreen = themeBreakpointUp(window, theme, "md");

    return (
      <div className={!isExpanded || !isSmUpScreen ? clsx(classes.block, "collapsed") : classes.block}>
        <Grid container alignItems="center">
          <Grid item className={classes.grow}>
            <Typography component="h2" variant="h6" className={classes.subHeader}>
              <ListAlt color="primary" /> &nbsp; {t("repairRequest.extraInformation")}
            </Typography>
          </Grid>
          {!isSmUpScreen && (
            <Grid item>
              <Tooltip title={isExpanded ? t("general.collapse") : t("general.expand")}>
                <IconButton
                  className={classes.expandCollapseButton}
                  aria-label="expand"
                  onClick={() =>
                    this.setState({
                      expandExtraInfo: isExpanded ? expandExtraInfo.filter((p) => p !== "ExtraInfo") : expandExtraInfo.concat("ExtraInfo"),
                    })
                  }
                  size="large"
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Collapse in={isExpanded || isSmUpScreen} timeout="auto" unmountOnExit>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.type") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {type && type.name}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.nature.label") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {nature && nature.name}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.cause") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {cause && cause.name}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.causer") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {causer && causer.name}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {repairRequest.isCauserOrganisationVisible === true && (
              <>
                <Grid item xs={12}>
                  <Grid container className={classes.infoGridRow}>
                    <Grid item xs={6}>
                      {t("general.organisation") + ":"}
                    </Grid>
                    <Grid item xs={6}>
                      {organisation && organisation.name}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.bookingPeriod") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {repairRequest.bookingPeriod && repairRequest.bookingPeriod}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.leadTime") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {repairRequest.leadTime && repairRequest.leadTime}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequest.clientReference") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {this.renderEditTextbox(t("repairRequest.clientReference"), "clientreference", repairRequest.clientReference)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {(repairRequest.surveyType === 0 || repairRequest.surveyType === 1 || repairRequest.surveyType === 2) && (
              <>
                <Grid item xs={12}>
                  <Grid container className={classes.infoGridRow}>
                    <Grid item xs={6}>
                      {t("survey.label") + ":"}
                    </Grid>
                    <Grid item xs={6}>
                      {t("survey.type." + repairRequest.surveyType)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}
          </Grid>
        </Collapse>
      </div>
    );
  }

  blockWorkorder() {
    const { t, classes } = this.props;
    const { repairRequest } = this.state;

    const columns = [
      {
        name: "resolverId",
        options: {
          display: "excluded",
          filter: false,
        },
      },
      {
        name: "isRequiredHandling",
        label: " ",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta) => {
            var resolverId = tableMeta.rowData[0];
            var resolver = !!repairRequest && repairRequest.resolvers.find((x) => x.resolverId === resolverId);
            return (
              <Typography noWrap>
                {value === true && (
                  <Tooltip title={t("general.actionRequired")}>
                    <PriorityHigh color="secondary" size="small" />
                  </Tooltip>
                )}
                {resolver.overdue ? (
                  <Tooltip title={t("general.overdue")}>
                    <Schedule fontSize="small" color="error" />
                  </Tooltip>
                ) : resolver.is48HoursReminder ? (
                  <Tooltip title={t("general.48hours")}>
                    <Schedule fontSize="small" className={classes.warning} />
                  </Tooltip>
                ) : (
                  ""
                )}
              </Typography>
            );
          },
        },
      },
      {
        name: "targetCompletionDate",
        label: t("repairRequest.targetCompletionDate.shortLabel"),
        options: {
          filter: true,
          customBodyRender: (v) => v && formatDate(new Date(v)),
        },
      },
      {
        name: "workOrderNumber",
        label: t("workOrders.workOrderNumber.label"),
        options: {
          filter: true,
          customBodyRender: (value) => {
            return (
              <Button style={{ padding: 0, minWidth: "auto" }} color="primary">
                {!value ? t("general.draft") : value}
              </Button>
            );
          },
        },
      },
      {
        name: "name",
        label: t("general.resolver"),
        options: {
          filter: true,
        },
      },
      {
        name: "status",
        label: t("general.status"),
        options: {
          filter: true,
          customBodyRender: (v) => v >= 0 && v <= 4 && t("resolver.status." + v),
        },
      },
    ];

    const options = {
      //filterType: 'dropdown',
      //responsive: 'vertical',
      download: false,
      // filter: true,
      selectableRows: "none",
      selectToolbarPlacement: "none",
      print: false,
      search: false,
      viewColumns: false,
      pagination: false,
      textLabels: getDataTableTextLabels(t),
    };

    return (
      repairRequest && (
        <div className={classes.block}>
          <Grid container>
            <Grid item xs={12}>
              <MUIDataTable
                className={classes.dataTable}
                title={
                  <Typography className={classes.subHeader} component="h2" variant="h6">
                    <Build color="primary" /> &nbsp; {t("general.workOrders")}
                  </Typography>
                }
                data={!!repairRequest.resolvers ? repairRequest.resolvers : []}
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        </div>
      )
    );
  }

  render() {
    const { t, classes, theme, handleClose, open, readOnly } = this.props;
    const { repairRequest, attachmentIndex, repairRequestLoading } = this.state;
    const matchWidthDownXs = themeBreakpointUp(window, theme, "md");
    const canApproveReject = repairRequest && (!repairRequest.resolvers || (repairRequest.resolvers && repairRequest.resolvers.every((r) => !r.workOrderNumber || (r.workOrderNumber && (r.status === 3 || r.status === 4)))));

    return (
      <Dialog onClose={() => handleClose()} fullScreen={matchWidthDownXs} TransitionComponent={Transition} fullWidth className={classes.requestContainer} maxWidth="lg" open={open} aria-labelledby="form-dialog-title" scroll="paper">
        <Container maxWidth={false} className={classes.mainContainer}>
          <Grid container>
            <Grid item container xs={12} className={classes.container}>
              <AppBar position="sticky" className={classes.customHeader}>
                <Toolbar variant="dense" className={classes.toolbar}>
                  <Typography className={clsx(classes.bold, classes.grow, classes.headerText)}>{t("repairRequest.repairRequestDetails") + " " + (repairRequest ? repairRequest.number : "")}</Typography>
                  <IconButton onClick={() => handleClose()} className={classes.closeButton} color="inherit" aria-label="close" size="large">
                    <Close className={classes.iconStyle} />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <div style={{ overflow: "auto", width: "100%" }}>
                {repairRequestLoading ? (
                  <div className={classes.mainLoaderContainer}>
                    <CircularProgress size={30} />
                  </div>
                ) : (
                  repairRequest && (
                    <Grid item xs={12}>
                      <Grid container className={classes.innerContainer} alignItems="flex-start">
                        {
                          //<Grid item xs={12}>
                          //    <Typography variant="h6">{t('general.generalInformation')}</Typography>
                          //</Grid>
                        }
                        <Grid className={classes.gridContent} item xs={12} md={6} lg={6}>
                          {this.blockGeneralRepairRequest()}
                        </Grid>
                        <Grid className={classes.gridContent} item xs={12} md={6} lg={6}>
                          {this.blockStatus()}
                        </Grid>
                        <Grid className={classes.gridContent} item xs={12} md={6} lg={6}>
                          {this.blockObjectInfo()}
                        </Grid>
                        <Grid className={classes.gridContent} item xs={12} md={6} lg={6}>
                          {this.blockContactInfo()}
                        </Grid>
                        <Grid className={classes.gridContent} item xs={12} md={6} lg={6}>
                          {this.blockRepairRequestWorkOrder()}
                        </Grid>
                        <Grid className={classes.gridContent} item xs={12} md={6} lg={6}>
                          {this.blockExtraInfo()}
                        </Grid>
                        <Grid className={classes.gridContent} item xs={12} md={6} lg={6}>
                          {this.blockWorkorder()}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                )}
              </div>
            </Grid>
          </Grid>
          {repairRequest && repairRequest.attachments && attachmentIndex != null && attachmentIndex >= 0 && attachmentIndex < repairRequest.attachments.length && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={attachmentIndex != null}
              onClose={this.handleImageModalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={true}>
                <Card style={{ position: "relative" }}>
                  <IconButton style={{ position: "absolute", right: "0" }} onClick={this.handleImageModalClose} size="large">
                    <Close />
                  </IconButton>
                  <OfflineFileWrapper
                    id={repairRequest.attachments[attachmentIndex] ? repairRequest.attachments[attachmentIndex].attachmentId : ""}
                    url={URLS.GET_ATTACHMENT + encodeURI(repairRequest.attachments[attachmentIndex] ? repairRequest.attachments[attachmentIndex].attachmentId : "")}
                  >
                    {(url) => <CardMedia component="img" alt={attachmentIndex + 1} title={attachmentIndex + 1} image={url} style={{ maxHeight: "100vh", maxWidth: "100%" }} />}
                  </OfflineFileWrapper>
                </Card>
              </Fade>
            </Modal>
          )}
          {this.state.repairRequest && (
            <RepairRequestForm
              isDrawingLocation
              setSelectedDrawingFiles={(val) => this.setState({ selectedDrawingFiles: val, currentPage: 1 })}
              setCurrentPage={(val) => this.setState({ currentPage: val })}
              selectedDrawingFiles={this.state.selectedDrawingFiles}
              currentPage={this.state.currentPage}
              projectId={this.state.repairRequest.projectId}
              requestId={this.state.repairRequest.requestId}
              setCordinates={(drawingPinLocationCord) => this.setState({ drawingPinLocationCord })}
              drawingLocation={this.state.repairRequest.drawingPinLocation}
              openRepairRequestMap={this.state.openRepairRequestMap}
              onClose={this.updateRepairRequestLocationPinPoint}
              updateOrAddLocation
            />
          )}
        </Container>
        {this.state.repairRequest && !readOnly && (
          <DialogActions style={{ justifyContent: "flex-end" }}>
            <Button
              disabled={this.props.surveying.isCompleteRRLoading || this.props.surveying.isUpdateReworkLoading || (!repairRequest.completed && repairRequest.isRework) || !canApproveReject}
              variant="outlined"
              onClick={() => this.props.handleRequest("reject", repairRequest)}
              startIcon={this.props.surveying.isCompleteRRLoading || this.props.surveying.isUpdateReworkLoading ? <CircularProgress size={18} /> : <Cancel color="error" size="small" />}
            >
              <Typography>{t("general.button.reject")}</Typography>
            </Button>
            <Button
              disabled={this.props.surveying.isCompleteRRLoading || this.props.surveying.isUpdateReworkLoading || repairRequest.completedByBuyer || !canApproveReject}
              variant="contained"
              onClick={() => this.props.handleRequest("approve", repairRequest)}
              startIcon={this.props.surveying.isCompleteRRLoading || this.props.surveying.isUpdateReworkLoading ? <CircularProgress size={18} /> : <CheckCircle className={classes.colorSuccess} size="small" />}
            >
              <Typography>{t("repairRequest.approve.label")}</Typography>
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, buildings, surveying } = state;
  const { user } = authentication;
  const { selected, all } = buildings;
  const { selectedMoment, selectedAssignment } = surveying;
  const allBuildings = all;
  return {
    user,
    selected,
    allBuildings,
    selectedMoment,
    selectedAssignment,
    surveying,
  };
}

const connectedPage = connect(mapStateToProps)(withTheme(withTranslation()(withStyles(styles)(Page))));
export { connectedPage as RepairRequestDetailModal };
