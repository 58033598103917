import {
  Checkbox,
  CircularProgress,
  alpha,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Hidden,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Alert,
  Autocomplete,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles";
import {
  ArrowBack,
  ArrowRightAlt,
  CalendarToday,
  Edit,
  ExpandMore,
  KeyboardArrowDown,
  QueryBuilderOutlined,
  ScheduleOutlined,
} from "@mui/icons-material";
import React, { createContext, useEffect, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { DatePicker, TimePicker } from "@mui/x-date-pickers"
import { useSelector } from "react-redux";
import {
  deleteSlots,
  finalizeGeneratedSlots,
  generateSlots,
  getCategories,
  getSearchAddress,
  getSlotsGenerationDetails,
  addBuildingsToGeneration,
  createIndividualAppointments
} from "../../../apis/agendaApis";
import SelectMultipleObjectsModal from "../../../components/SelectMultipleObjectsModal";
import moment from "moment";
import FullCalendar from "./../FullCalendar";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import { debounce } from "../../../_helpers";
import SelectObjectsAppointmentModal from "../Components/SelectObjectsAppointmentModal";

export const PreviewCalenderContext = createContext({});

function StyledRadio(props) {
  const classes = useStyles();
  return (
    <Radio
      className={classes.customRadioBtnRoot}
      disableRipple
      color="default"
      checkedIcon={
        <span
          className={clsx(
            classes.customRadioBtnIcon,
            classes.customRadioBtnCheckedIcon
          )}
        />
      }
      icon={<span className={classes.customRadioBtnIcon} />}
      {...props}
    />
  );
}

export default function CreateSlot(props) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const { selected, all } = useSelector((state) => state.buildings);
  const steps = [t("general.create"), t("general.preview")];
  const history = useHistory();
  const [agendaCategories, setAgendaCategories] = useState([]);
  const [objectModal, setObjectModal] = useState(false);
  const [addressAutoCompleteOpen, setAddressAutoCompleteOpen] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressInput, setAddressInput] = useState("");
  const [errors, setErrors] = useState({});
  const [loaders, setLoaders] = useState({});
  const [calculateSlotSnackbar, setCalculateSlotSnackbar] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const classes = useStyles({ activeStep });
  const baseTime = moment().startOf("d").toDate();
  const [events, setEvents] = useState([]);
  const [confirmBack, setConfirmBack] = useState(false);
  const [generation, setGeneration] = useState(null);
  const [filters, setFilters] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [slotFields, setSlotFields] = useState({
    project: "",
    appointmentType: 1,
    categoryId: "",
    duration: baseTime,
    bufferTime: baseTime,
    title: "",
    description: "",
    startDateTime: moment().startOf("hour").add(1, "h").toDate(),
    endDateTime: moment().startOf("hour").add(2, "h").toDate(),
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    officeStartTime: baseTime,
    officeEndTime: baseTime,
    lunchStartTime: null,
    lunchEndTime: null,
    appointmentLocation: 0,
    addressId: null,
    address: null,
    addressAdditionalDesc: null,
    buildingIds: [],
    buildingAppointments: [],
    chatMessage: "",
  });
  
  const [newBuildingIds, setNewBuildingIds] = useState([]);

  useEffect(() => {
    if (props.location.state && props.location.state.generationId) {
      getSlotsDetails(props.location.state.generationId);
    }
  }, []);

  const searchAddressHandler = debounce((address) => {
    searchAddress(address);
  }, 500);

  const searchAddress = async (address, selectedAddressId) => {
    try {
      setLoaders({ isAddressLoading: true });
      if (address.length >= 3) {
        const res = await getSearchAddress(address).then((p) => p.data);
        setAddressOptions(res);
        const selectedAddress = res.find((p) => p.id === selectedAddressId);
        if (selectedAddress) setAddressInput(selectedAddress);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoaders({ isAddressLoading: false });
    }
  };

  useEffect(() => {
    if (selected) {
      setSlotFields({ ...slotFields, project: selected.projectName });
    }
  }, [selected]);

  useEffect(() => {
    getAgendaCategories();
  }, []);

  const confirmRegenerateHandler = () => {
    setConfirmBack(!confirmBack);
  };

  const getSlotsDetails = async (id) => {
    try {
      setLoaders({ isSlotsLoading: true });
      const events = await getSlotsGenerationDetails(id).then((p) => p.data);
      const slotDuration = moment(events.duration, "HH:mm:ss").toDate();
      const slotBufferTime = moment(events.bufferTime, "HH:mm:ss").toDate();
      const officeStart = moment(events.officeStartTime, "HH:mm:ss").toDate();
      const officeEnd = moment(events.officeEndTime, "HH:mm:ss").toDate();
      const lunchStart = moment(events.lunchStartTime, "HH:mm:ss").toDate();
      const lunchEnd = moment(events.lunchEndTime, "HH:mm:ss").toDate();
      setGeneration(events);
      setSlotFields((x) => ({
        ...x,
        ...events,
        slots: events.slots,
        startDateTime: new Date(events.startDateTime),
        endDateTime: new Date(events.endDateTime),
        officeStartTime: officeStart,
        officeEndTime: officeEnd,
        lunchStartTime: events.lunchStartTime && lunchStart,
        lunchEndTime: events.lunchEndTime && lunchEnd,
        duration: slotDuration,
        bufferTime: slotBufferTime,
        isCreatedStatus: !!events.completionDate
      }));
      searchAddress(events.address, events.addressId);
      if (events.slots && events.slots.length) {
        const startDate = events.slots[0].start;
        const endDate = events.slots[events.slots.length - 1].end;
        setFilters({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        });
      } else {
        setFilters({
          startDate: new Date(events.startDateTime),
          endDate: new Date(events.endDateTime),
        });
      }
      if (events.slots)
        setEvents(
          events.slots.map((e) => ({
            ...events,
            ...e,
            appointmentId: e.slotId,
            start: new Date(e.start),
            end: new Date(e.end),
            title: events.title,
          }))
        );
    } catch (e) {
      console.log(e);
    } finally {
      setLoaders({ isSlotsLoading: false });
    }
  };

  const getAgendaCategories = async () => {
    try {
      setLoaders({ isCategoryLoading: true });
      const categories = await getCategories().then((p) => p.data);
      setAgendaCategories(categories);
    } catch (e) {
      console.log(e);
    } finally {
      setLoaders({ isCategoryLoading: false });
    }
  };

  const handleRegenerateSlots = () => {
    confirmRegenerateHandler();
    generateAgendaSlots();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (
      props.location.state &&
      props.location.state.generationId &&
      activeStep === 0
    ) {
      history.goBack();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const deleteAgendaSlots = async (data) => {
    try {
      await deleteSlots(data).then((p) => p.data);
      if (generation) getSlotsDetails(generation.generationId);
    } catch (e) {
      console.log(e);
    }
  };

  const finalizeSlots = async () => {
    try {
      setLoaders({ isCreateLoading: true });
      await finalizeGeneratedSlots(generation.generationId).then((p) => p.data);
      history.push("/agenda");
    } catch (e) {
      console.log(e);
    } finally {
      setLoaders({ isCreateLoading: false });
    }
  };

  const generateAgendaSlots = async () => {
    if (Object.keys(validate()).length) return;
    setLoaders({ isSlotsLoading: true });
    if (selected.projectId) {
      try {
        const slotsData = {
          ...slotFields,
          generationId: generation ? generation.generationId : null,
          startDateTime: moment(slotFields.startDateTime).format(),
          endDateTime: moment(slotFields.endDateTime).format(),
          officeStartTime: moment(slotFields.officeStartTime).format(
            "HH:mm:ss"
          ),
          officeEndTime: moment(slotFields.officeEndTime).format("HH:mm:ss"),
          lunchStartTime: slotFields.lunchStartTime
            ? moment(slotFields.lunchStartTime).format("HH:mm:ss")
            : null,
          lunchEndTime: slotFields.lunchEndTime
            ? moment(slotFields.lunchEndTime).format("HH:mm:ss")
            : null,
          duration: moment(slotFields.duration).format("HH:mm:ss"),
          bufferTime: moment(slotFields.bufferTime).format("HH:mm:ss"),
        };
        const gen = await generateSlots(slotsData).then((p) => p.data);
        setGeneration(gen);
        if (gen.slots) {
          setCalculateSlotSnackbar((s) => ({ ...s, open: true, error: false }));
          setEvents(
            gen.slots.map((e) => ({
              ...gen,
              ...e,
              appointmentId: e.slotId,
              start: new Date(e.start),
              end: new Date(e.end),
            }))
          );
        } else
          setCalculateSlotSnackbar((s) => ({ ...s, open: true, error: true }));
        setFilters({
          startDate: new Date(gen.startDateTime),
          endDate: new Date(gen.endDateTime),
        });
      } catch (e) {
        console.log(e);
      } finally {
        setLoaders({ isSlotsLoading: false });
      }
    }
  };

  const updateGenerationSlots = async () => {
      await addBuildingsToGeneration({ generationId: generation.generationId, buildingIds: newBuildingIds.map(x => x.buildingId) });
      history.push("/agenda/tijdsloten");
  }
  
  const validate = () => {
    const {
      duration,
      startDateTime,
      endDateTime,
      officeStartTime,
      officeEndTime,
      lunchStartTime,
      lunchEndTime,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      addressId,
      buildingIds,
      categoryId,
      appointmentLocation,
    } = slotFields;
    const errors = {};
    if (!addressId && appointmentLocation === 0)
      errors.addressId = t("agenda.create.errors.address");
    if (
      !monday &&
      !tuesday &&
      !wednesday &&
      !thursday &&
      !friday &&
      !saturday &&
      !sunday
    )
      errors.days = t("agenda.create.errors.days");
    if (!categoryId) errors.categoryId = t("agenda.create.errors.category");
    if (!buildingIds.length)
      errors.buildingIds = t("agenda.create.errors.buildingIds");
    if (
      lunchStartTime &&
      moment(lunchEndTime).diff(lunchStartTime, "minutes") < 15
    )
      errors.lunch = t("agenda.create.errors.lunch");
    if (moment(officeEndTime).diff(officeStartTime, "minutes") < 15)
      errors.office = t("agenda.create.errors.office");
    if (moment(endDateTime).diff(startDateTime, "minutes") < 15)
      errors.dateTime = t("agenda.create.errors.datetime");
    if (
      moment(endDateTime).diff(startDateTime, "minutes") <
      moment(duration).diff(moment().startOf("d"), "minutes")
    )
      errors.dateTime = t("agenda.create.errors.datetime");
    if (moment(duration).diff(moment().startOf("d"), "minutes") < 15)
      errors.duration = t("agenda.create.errors.duration");
    setErrors(errors);
    return errors;
  };

  const createAgendaIndividualAppointments = async () => {
      if (Object.keys(validateIndividualAppointments()).length) return;
      setLoaders({ isSlotsLoading: true });
      if (selected.projectId) {
          try {
              const slotsData = {
                  ...slotFields,
                  buildingAppointments: slotFields.buildingAppointments.map(x => ({ buildingId: x.buildingId, startDateTime: moment(x.startDateTime).format() })),
                  duration: moment(slotFields.duration).format("HH:mm:ss"),
                  bufferTime: moment(slotFields.bufferTime).format("HH:mm:ss"),
              };
              const result = await createIndividualAppointments(slotsData).then((p) => p.data);

              if (result.length > 0) {
                  setTimeout(history.push("/agenda"), 2000);
              }
          } catch (e) {
              console.log(e);
          } finally {
              setLoaders({ isSlotsLoading: false });
          }
      }
  }

  const validateIndividualAppointments = () => {
    const {
      duration,
      addressId,
      buildingAppointments,
      categoryId,
      appointmentLocation,
    } = slotFields;
    const errors = {};
    if (!addressId && appointmentLocation === 0)
      errors.addressId = t("agenda.create.errors.address");
    if (!categoryId) errors.categoryId = t("agenda.create.errors.category");
    if (!buildingAppointments.length)
      errors.buildingIds = t("agenda.create.errors.buildingIds");
    if (moment(duration).diff(moment().startOf("d"), "minutes") < 15)
      errors.duration = t("agenda.create.errors.duration");
    setErrors(errors);
    return errors;
  };

  const slotFieldsChangeHandler = (event) => {
    setErrors({});
    const slotField = agendaCategories.find((p) => p.id === event.target.value);
    if (!!slotField) {
      setSlotFields({
        ...slotFields,
        appointmentType: slotField && slotField.appointmentType,
        chatMessage: slotField && slotField.chatMessage,
        description: slotField && slotField.description,
        duration: slotField && moment(slotField.duration, "HH:mm:ss").toDate(),
        title: slotField && slotField.title,
        [event.target.name]:
          event.target.name === "appointmentLocation"
            ? +event.target.value
            : event.target.value,
      });
    } else {
      setSlotFields({
        ...slotFields,
        [event.target.name]:
          event.target.name === "appointmentLocation"
            ? +event.target.value
            : event.target.value,
      });
    }
  };

  const slotFieldsDateChangeHandler = (key, value) => {
    setErrors({});
    if (key === "startDateTime") {
      const date = moment(value).startOf("hour").add(1, "h").toDate();
      setSlotFields((s) => ({ ...s, endDateTime: date }));
    }
    setSlotFields((s) => ({ ...s, [key]: value }));
  };

  const slotFieldsDaysChangeHandler = (event) => {
    setSlotFields({ ...slotFields, [event.target.name]: event.target.checked });
  };

  const objectModalHandler = () => {
    setObjectModal(!objectModal);
  };

  const handleSaveObjects = (objects, changedObjects) => {
    setErrors({});
      if (!slotFields.isCreatedStatus) {
          setSlotFields((prev) => ({
              ...prev,
              buildingIds: objects.map((p) => p.buildingId),
          }));
      }
      else {
          setNewBuildingIds(objects.filter((p) => !slotFields.buildingIds.find(x => x === p.buildingId)));
      }

    setObjectModal(false);
  };

  const handleSavebuildingAppointments = (buildingAppointments) => {
    setSlotFields((prev) => ({ ...prev, buildingAppointments }))
    setObjectModal(false);
  }

  const handleCloseObjects = () => {
    setObjectModal(!objectModal);
  };

  const calculateSlotSnackBarHandler = () => {
    setCalculateSlotSnackbar((s) => ({
      ...s,
      open: !calculateSlotSnackbar.open,
    }));
  };

  return (
    <div className={classes.container}>
      <div className={classes.calendarContainer}>
        {activeStep === 0 && (
          <div className={classes.headerContainer}>
            <div className={classes.backIconContainer}>
              <IconButton onClick={history.goBack} size="large">
                <ArrowBack className={classes.backIcon} />
              </IconButton>
            </div>
            {
              slotFields.isCreatedStatus ? (
                <Typography variant="h5" className={classes.headerTitle}>
                  {t("general.objects.add")}
                </Typography>
              ) :
              slotFields.appointmentType === 0 ? (
                <Typography variant="h5" className={classes.headerTitle}>
                  {t("general.create")}
                </Typography>
              ) : (
                <Stepper activeStep={activeStep} classes={{ root: classes.customStepper }}>
                  {steps.map((label, index) => {
                    const stepProps = {}
                    const labelProps = {}
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    )
                  })}
                </Stepper>
              )
            }
          </div>
        )}
        <div className={clsx(classes.stepperContainer, activeStep === 0 && classes.activeStepperContainer)}>
          <div className={classes.stepperContentContainer}>
            <div className={classes.stepperContent}>
              {activeStep === 0 ? (
                <div className={classes.createStepperContainer}>
                  <Grid container spacing={2}>
                    <Snackbar
                      classes={{ root: classes.customSuccessSnackbar }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      key={calculateSlotSnackbar.vertical + calculateSlotSnackbar.horizontal}
                      open={calculateSlotSnackbar.open}
                      autoHideDuration={6000}
                      onClose={calculateSlotSnackBarHandler}
                    >
                      {events.length > slotFields.buildingIds.length ? (
                        <Alert onClose={calculateSlotSnackBarHandler} severity="success">
                          {t("agenda.createModal.success.label", {
                            events: events.length,
                            objects: slotFields.buildingIds.length,
                          })}
                        </Alert>
                      ) : events.length === slotFields.buildingIds.length ? (
                        <Alert onClose={calculateSlotSnackBarHandler} severity="warning">
                          {t("agenda.createModal.warning.label", {
                            events: events.length,
                            objects: slotFields.buildingIds.length,
                          })}
                        </Alert>
                      ) : (
                        <Alert onClose={calculateSlotSnackBarHandler} severity="error">
                          {t("agenda.createModal.error.label", {
                            events: events.length,
                            objects: slotFields.buildingIds.length,
                          })}
                        </Alert>
                      )}
                    </Snackbar>
                    <Grid item xs={12} lg={6} className={classes.createStepperMainContainer}>
                      <Grid item xs={12}>
                        <FormControl className={classes.createStepperFormControl}>
                          <InputLabel shrink id="project">
                            {t("layout.navbar.project")}
                          </InputLabel>
                          <Select
                            labelId="project"
                            name="project"
                            classes={{ icon: classes.createStepperSelectIcon }}
                            disabled
                            IconComponent={(props) => <KeyboardArrowDown {...props} />}
                            value={slotFields.project}
                            onChange={slotFieldsChangeHandler}
                            displayEmpty
                            className={classes.selectEmpty}
                            variant="standard"
                          >
                            <MenuItem value={selected.projectName}>{selected.projectName}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} className={classes.fromControlContainer}>
                        <FormControl className={classes.createStepperFormControl}>
                          <InputLabel shrink id="appointment-type">
                            {t("agenda.appointmentType")}
                          </InputLabel>
                          <Select
                            labelId="appointment-type"
                            name="appointmentType"
                            classes={{ icon: classes.createStepperSelectIcon }}
                            disabled
                            IconComponent={(props) => <KeyboardArrowDown {...props} />}
                            value={slotFields.appointmentType}
                            onChange={slotFieldsChangeHandler}
                            displayEmpty
                            className={classes.selectEmpty}
                            variant="standard"
                          >
                            <MenuItem value={slotFields.appointmentType}>{t("agenda.appointmentType." + slotFields.appointmentType)}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} className={classes.fromControlContainer}>
                        <FormControl className={classes.createStepperFormControl} error={errors.category}>
                          <InputLabel shrink id="category">
                            {t("agenda.create.label.createAppointment")}
                          </InputLabel>
                          <Select
                            labelId="category"
                            name="categoryId"
                            classes={{ icon: classes.createStepperSelectIcon }}
                            IconComponent={(props) => <KeyboardArrowDown {...props} />}
                            value={slotFields.categoryId}
                            onChange={slotFieldsChangeHandler}
                            displayEmpty
                            className={classes.selectEmpty}
                            variant="standard"
                            disabled={slotFields.isCreatedStatus || !!generation?.generationId}
                          >
                            {agendaCategories.map((p) => {
                              return (
                                <MenuItem value={p.id} id={`category-item-${p.id}`} key={p.id}>
                                  {p.title}
                                </MenuItem>
                              )
                            })}
                          </Select>
                          <FormHelperText error>{errors.categoryId}</FormHelperText>
                        </FormControl>
                      </Grid>
                      {!!slotFields.categoryId.length && (
                        <>
                          <Grid item xs={12} className={classes.fromControlContainer}>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <FormControl fullWidth className={classes.createStepperFormControl} error={errors.duration}>
                                  <InputLabel shrink id="duration">
                                    {t("agenda.duration")}
                                  </InputLabel>
                                  <TimePicker
                                    ampm={false}
                                    // variant="inline"
                                    minutesStep={5}
                                    okLabel={t("general.ok")}
                                    cancelLabel={t("general.cancel")}
                                    views={["hours", "minutes"]}
                                    id="duration"
                                    name="duration"
                                    value={slotFields.duration}
                                    onChange={(time) => slotFieldsDateChangeHandler("duration", time)}
                                    slotProps={{
                                      openPickerIcon: { className: classes.inputFieldIcon },
                                      openPickerButton: { edge: "start", size: "large" },
                                      textField: {
                                        variant: "standard",
                                        size: "large"
                                      },
                                    }}
                                    slots={{
                                      openPickerButton: IconButton,
                                      openPickerIcon: ScheduleOutlined,
                                    }}
                                    disabled={slotFields.isCreatedStatus}
                                  />
                                  <FormHelperText error>{errors.duration}</FormHelperText>
                                </FormControl>
                              </Grid>
                              { slotFields.appointmentType === 1 &&
                              <Grid item xs={6}>
                                <FormControl fullWidth className={classes.createStepperFormControl}>
                                  <InputLabel shrink id="bufferTime">
                                    {t("agenda.bufferTime")}
                                  </InputLabel>
                                  <TimePicker
                                    ampm={false}
                                    minutesStep={5}
                                    okLabel={t("general.ok")}
                                    cancelLabel={t("general.cancel")}
                                    views={["hours", "minutes"]}
                                    id="buffer-time"
                                    name="bufferTime"
                                    helperText={""}
                                    value={slotFields.bufferTime}
                                    formatDensity="dense"
                                    onChange={(time) => slotFieldsDateChangeHandler("bufferTime", time)}
                                    slotProps={{
                                      openPickerIcon: { className: classes.inputFieldIcon },
                                      openPickerButton: { edge: "start", size: "large" },
                                      textField: {
                                        variant: "standard",
                                        size: "large",
                                      },
                                    }}
                                    slots={{
                                      openPickerButton: IconButton,
                                      openPickerIcon: Edit,
                                    }}
                                    disabled={slotFields.isCreatedStatus}
                                  />
                                </FormControl>
                              </Grid>
                              }
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className={classes.fromControlContainer}>
                            <FormControl fullWidth className={classes.createStepperFormControl}>
                              <InputLabel shrink="true" htmlFor="title">
                                {t("general.subject")}
                              </InputLabel>
                              <Input id="title" name="title" value={slotFields.title} classes={{ root: classes.inputFieldControl }} onChange={slotFieldsChangeHandler} disabled={slotFields.isCreatedStatus}/>
                            </FormControl>
                          </Grid>
                          {
                            slotFields.appointmentType === 1 &&
                            <>
                          <Grid item xs={12} className={clsx(classes.fromControlContainer, classes.datePickerMainContainer)}>
                            <Grid container className={classes.startDateContainer}>
                              <Grid item xs={12} sm={6} className={classes.datePickerContainer}>
                                <FormControl fullWidth className={classes.createStepperFormControl} error={errors.dateTime}>
                                  <InputLabel shrink id="startDate">
                                    {t("general.startDate")}
                                  </InputLabel>
                                  <DatePicker
                                    disableToolbar
                                    minDate={new Date()}
                                    variant="inline"
                                    format="dd-MM-yyyy"
                                    id="start-date-picker"
                                    name="startDateTime"
                                    value={slotFields.startDateTime}
                                    className={classes.customDatePicker}
                                    onChange={(date) => slotFieldsDateChangeHandler("startDateTime", date)}
                                    autoOk
                                    slotProps={{
                                      openPickerIcon: { className: classes.inputFieldIcon },
                                      openPickerButton: { edge: "start", size: "large" },
                                      textField: {
                                        variant: "standard",
                                          size: "large"
                                      },
                                    }}
                                    slots={{
                                      openPickerButton: IconButton,
                                      openPickerIcon: CalendarToday,
                                    }}
                                    disabled={slotFields.isCreatedStatus}
                                  />
                                  <FormHelperText error>{errors.dateTime}</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth className={classes.createStepperFormControl}>
                                  <TimePicker
                                    ampm={false}
                                    minutesStep={15}
                                    views={["hours", "minutes"]}
                                    id="start-time-picker"
                                    helperText={""}
                                    okLabel={t("general.ok")}
                                    cancelLabel={t("general.cancel")}
                                    name="startDateTime"
                                    label={null}
                                    value={slotFields.startDateTime}
                                    onChange={(time) => slotFieldsDateChangeHandler("startDateTime", time)}
                                    slotProps={{
                                      openPickerIcon: { className: classes.inputFieldIcon },
                                      openPickerButton: { edge: "start", size: "large" },
                                      textField: {
                                        variant: "standard",
                                        size: "large",
                                      },
                                    }}
                                    slots={{
                                      openPickerButton: IconButton,
                                      openPickerIcon: ScheduleOutlined,
                                    }}
                                    disabled={slotFields.isCreatedStatus}
                                  />
                                </FormControl>
                                <Hidden smDown>
                                  <ArrowRightAlt className={classes.arrowRightIcon} />
                                </Hidden>
                              </Grid>
                              <Hidden smUp>
                                <Grid xs="auto" className={classes.arrowRightContainer}>
                                  <ArrowRightAlt className={classes.arrowRightIcon} />
                                </Grid>
                              </Hidden>
                              <Grid item xs={12} sm={6} className={classes.datePickerContainer}>
                                <FormControl fullWidth className={classes.createStepperFormControl}>
                                  <InputLabel shrink id="endDate">
                                    {t("general.endDate")}
                                  </InputLabel>
                                  <DatePicker
                                    disableToolbar
                                    minDate={new Date(slotFields.startDateTime)}
                                    variant="inline"
                                    format="dd-MM-yyyy"
                                    id="end-date-picker"
                                    name="endDateTime"
                                    value={slotFields.endDateTime}
                                    helperText={""}
                                    className={classes.customDatePicker}
                                    onChange={(date) => date >= slotFields.startDateTime && slotFieldsDateChangeHandler("endDateTime", date)}
                                    autoOk
                                    slotProps={{
                                      openPickerIcon: { className: classes.inputFieldIcon },
                                      openPickerButton: { edge: "start", size: "large" },
                                      textField: {
                                        variant: "standard",
                                        size: "large",
                                      },
                                    }}
                                    slots={{
                                      openPickerButton: IconButton,
                                      openPickerIcon: CalendarToday,
                                    }}
                                    disabled={slotFields.isCreatedStatus}
                                  />
                                </FormControl>
                                <FormControl fullWidth className={classes.createStepperFormControl}>
                                  <TimePicker
                                    ampm={false}
                                    okLabel={t("general.ok")}
                                    cancelLabel={t("general.cancel")}
                                    minutesStep={15}
                                    views={["hours", "minutes"]}
                                    id="end-time-picker"
                                    name="endDateTime"
                                    helperText={""}
                                    label={null}
                                    value={slotFields.endDateTime}
                                    onChange={(time) => time >= slotFields.startDateTime && slotFieldsDateChangeHandler("endDateTime", time)}
                                    slotProps={{
                                      openPickerIcon: { className: classes.inputFieldIcon },
                                      openPickerButton: { edge: "start", size: "large" },
                                      textField: {
                                        variant: "standard",
                                        size: "large",
                                      },
                                    }}
                                    slots={{
                                      openPickerButton: IconButton,
                                      openPickerIcon: ScheduleOutlined,
                                    }}
                                    disabled={slotFields.isCreatedStatus}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className={classes.fromControlContainer}>
                            <FormControl component="fieldset" fullWidth className={classes.createStepperFormControl} error={errors.days} disabled={slotFields.isCreatedStatus}>
                              <InputLabel shrink id="available-days">
                                {t("agenda.availableDays")}
                              </InputLabel>
                              <FormGroup className={classes.assignResponsibilityContainer}>
                                <FormControlLabel
                                  id="available-days-monday"
                                  control={
                                    <Checkbox
                                      checked={slotFields.monday}
                                      onChange={slotFieldsDaysChangeHandler}
                                      classes={{
                                        root: classes.modalCustomCheckBox,
                                        checked: classes.modalCustomCheckBoxChecked,
                                      }}
                                      name="monday"
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <Typography variant="h6" id="lbl-available-days-monday" className={classes.customCheckBoxLabel}>
                                      {t("general.days.monday.2chars")}
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  id="available-days-tuesday"
                                  control={
                                    <Checkbox
                                      checked={slotFields.tuesday}
                                      onChange={slotFieldsDaysChangeHandler}
                                      classes={{
                                        root: classes.modalCustomCheckBox,
                                        checked: classes.modalCustomCheckBoxChecked,
                                      }}
                                      name="tuesday"
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <Typography variant="h6" id="lbl-available-days-tuesday" className={classes.customCheckBoxLabel}>
                                      {t("general.days.tuesday.2chars")}
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  id="available-days-wednesday"
                                  control={
                                    <Checkbox
                                      checked={slotFields.wednesday}
                                      onChange={slotFieldsDaysChangeHandler}
                                      classes={{
                                        root: classes.modalCustomCheckBox,
                                        checked: classes.modalCustomCheckBoxChecked,
                                      }}
                                      name="wednesday"
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <Typography variant="h6" id="lbl-available-days-wednesday" className={classes.customCheckBoxLabel}>
                                      {t("general.days.wednesday.2chars")}
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  id="available-days-thursday"
                                  control={
                                    <Checkbox
                                      checked={slotFields.thursday}
                                      onChange={slotFieldsDaysChangeHandler}
                                      classes={{
                                        root: classes.modalCustomCheckBox,
                                        checked: classes.modalCustomCheckBoxChecked,
                                      }}
                                      name="thursday"
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <Typography variant="h6" id="lbl-available-days-thursday" className={classes.customCheckBoxLabel}>
                                      {t("general.days.thursday.2chars")}
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  id="available-days-friday"
                                  control={
                                    <Checkbox
                                      checked={slotFields.friday}
                                      onChange={slotFieldsDaysChangeHandler}
                                      classes={{
                                        root: classes.modalCustomCheckBox,
                                        checked: classes.modalCustomCheckBoxChecked,
                                      }}
                                      name="friday"
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <Typography variant="h6" id="lbl-available-days-friday" className={classes.customCheckBoxLabel}>
                                      {t("general.days.friday.2chars")}
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  id="available-days-saturday"
                                  control={
                                    <Checkbox
                                      checked={slotFields.saturday}
                                      onChange={slotFieldsDaysChangeHandler}
                                      classes={{
                                        root: classes.modalCustomCheckBox,
                                        checked: classes.modalCustomCheckBoxChecked,
                                      }}
                                      name="saturday"
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <Typography variant="h6" id="lbl-available-days-saturday" className={classes.customCheckBoxLabel}>
                                      {t("general.days.saturday.2chars")}
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  id="available-days-sunday"
                                  control={
                                    <Checkbox
                                      checked={slotFields.sunday}
                                      onChange={slotFieldsDaysChangeHandler}
                                      classes={{
                                        root: classes.modalCustomCheckBox,
                                        checked: classes.modalCustomCheckBoxChecked,
                                      }}
                                      name="sunday"
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <Typography variant="h6" id="lbl-available-days-sunday" className={classes.customCheckBoxLabel}>
                                      {t("general.days.sunday.2chars")}
                                    </Typography>
                                  }
                                />
                              </FormGroup>
                              <FormHelperText error>{errors.days}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} className={clsx(classes.fromControlContainer, classes.datePickerMainContainer)}>
                            <Grid container spacing={1} className={classes.startDateContainer}>
                              <Grid item xs={12} sm={6} className={classes.datePickerContainer}>
                                <FormControl fullWidth className={classes.createStepperOfficeHoursFormControl} error={errors.office}>
                                  <InputLabel shrink id="office-start-time">
                                    {t("agenda.create.label.officeHours")}
                                  </InputLabel>
                                  <TimePicker
                                    okLabel={t("general.ok")}
                                    cancelLabel={t("general.cancel")}
                                    ampm={false}
                                    minutesStep={15}
                                    views={["hours", "minutes"]}
                                    id="office-start-time"
                                    name="officeStartTime"
                                    value={slotFields.officeStartTime}
                                    onChange={(time) => slotFieldsDateChangeHandler("officeStartTime", time)}
                                    slotProps={{
                                      openPickerIcon: { className: classes.inputFieldIcon },
                                      openPickerButton: { edge: "start", size: "large" },
                                      textField: {
                                        variant: "standard",
                                        size: "large",
                                        helperText: t("general.startTime"),
                                      },
                                    }}
                                    slots={{
                                      openPickerButton: IconButton,
                                      openPickerIcon: QueryBuilderOutlined,
                                    }}
                                    disabled={slotFields.isCreatedStatus}
                                  />

                                  <FormHelperText error>{errors.office}</FormHelperText>
                                </FormControl>
                                <ArrowRightAlt className={classes.arrowRightIcon} />
                                <FormControl fullWidth className={classes.createStepperOfficeHoursFormControl}>
                                  <TimePicker
                                    okLabel={t("general.ok")}
                                    cancelLabel={t("general.cancel")}
                                    ampm={false}
                                    minutesStep={15}
                                    views={["hours", "minutes"]}
                                    id="office-end-time"
                                    name="officeEndTime"
                                    label={null}
                                    value={slotFields.officeEndTime}
                                    onChange={(time) => slotFieldsDateChangeHandler("officeEndTime", time)}
                                    slotProps={{
                                      openPickerIcon: { className: classes.inputFieldIcon },
                                      openPickerButton: { edge: "start", size: "large" },
                                      textField: {
                                        variant: "standard",
                                        size: "large",
                                        helperText: t("general.endTime"),
                                      },
                                    }}
                                    slots={{
                                      openPickerButton: IconButton,
                                      openPickerIcon: QueryBuilderOutlined,
                                    }}
                                    disabled={slotFields.isCreatedStatus}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} className={classes.datePickerContainer}>
                                {!slotFields.lunchStartTime && (
                                  <FormControlLabel
                                    id="lunch-time-checkbox"
                                    className="lunchTimeCheckbox"
                                    control={
                                      <Checkbox
                                        checked={!!slotFields.lunchStartTime}
                                        onChange={() => {
                                          slotFieldsDateChangeHandler("lunchStartTime", slotFields.lunchStartTime ? null : baseTime)
                                          slotFieldsDateChangeHandler("lunchEndTime", slotFields.lunchEndTime ? null : baseTime)
                                        }}
                                        classes={{
                                          root: classes.modalCustomCheckBox,
                                          checked: classes.modalCustomCheckBoxChecked,
                                        }}
                                        name="keepAvailable"
                                        color="primary"
                                        disabled={slotFields.isCreatedStatus}
                                      />
                                    }
                                    label={
                                      <Typography variant="h6" id="lunch-time-checkbox" className={classes.customCheckBoxLabel}>
                                        {t("agenda.create.label.lunchBreak")}
                                      </Typography>
                                    }
                                  />
                                )}

                                {!!slotFields.lunchStartTime && (
                                  <>
                                    <FormControl fullWidth error={errors.lunch} className={classes.createStepperOfficeHoursFormControl}>
                                      <div id="lunch-start-time" className={classes.positionLabel}>
                                        <FormControlLabel
                                          id="lunch-start-time"
                                          control={
                                            <Checkbox
                                              checked={!!slotFields.lunchStartTime}
                                              onChange={() => {
                                                slotFieldsDateChangeHandler("lunchStartTime", slotFields.lunchStartTime ? null : baseTime)
                                                slotFieldsDateChangeHandler("lunchEndTime", slotFields.lunchEndTime ? null : baseTime)
                                              }}
                                              className={clsx(classes.modalCustomCheckBox, !!slotFields.lunchStartTime && "small", !!slotFields.lunchStartTime && classes.modalCustomCheckBoxChecked)}
                                              name="keepAvailable"
                                              color="primary"
                                              disabled={slotFields.isCreatedStatus}
                                            />
                                          }
                                          label={
                                            <Typography variant="h6" id="lunch-start-time" className={clsx(classes.customCheckBoxLabel, !!slotFields.lunchStartTime && "checked")}>
                                              {t("agenda.create.label.lunchBreak")}
                                            </Typography>
                                          }
                                        />
                                      </div>

                                      <TimePicker
                                        okLabel={t("general.ok")}
                                        cancelLabel={t("general.cancel")}
                                        ampm={false}
                                        minutesStep={15}
                                        views={["hours", "minutes"]}
                                        id="lunch-start-time"
                                        name="lunchStartTime"
                                        value={slotFields.lunchStartTime}
                                        onChange={(time) => slotFieldsDateChangeHandler("lunchStartTime", time)}
                                        slotProps={{
                                          openPickerIcon: { className: classes.inputFieldIcon },
                                          openPickerButton: { edge: "start", size: "large" },
                                          textField: {
                                            variant: "standard",
                                            size: "large",
                                            helperText: t("general.startTime"),
                                          },
                                        }}
                                        slots={{
                                          openPickerButton: IconButton,
                                          openPickerIcon: QueryBuilderOutlined,
                                        }}
                                        disabled={slotFields.isCreatedStatus}
                                      />
                                      <FormHelperText error>{errors.lunch}</FormHelperText>
                                    </FormControl>
                                    <ArrowRightAlt className={classes.arrowRightIcon} />
                                    <FormControl fullWidth className={classes.createStepperOfficeHoursFormControl}>
                                      <TimePicker
                                        okLabel={t("general.ok")}
                                        cancelLabel={t("general.cancel")}
                                        ampm={false}
                                        minutesStep={15}
                                        views={["hours", "minutes"]}
                                        id="lunch-end-time"
                                        name="lunchEndTime"
                                        label={null}
                                        value={slotFields.lunchEndTime}
                                        onChange={(time) => slotFieldsDateChangeHandler("lunchEndTime", time)}
                                        InputProps={{
                                          endAdornment: (
                                            <IconButton size="large">
                                              <QueryBuilderOutlined className={classes.inputFieldIcon} />
                                            </IconButton>
                                          ),
                                        }}
                                        slotProps={{
                                          openPickerIcon: { className: classes.inputFieldIcon },
                                          openPickerButton: { edge: "start", size: "large" },
                                          textField: {
                                            variant: "standard",
                                            size: "large",
                                            helperText: t("general.endTime"),
                                          },
                                        }}
                                        slots={{
                                          openPickerButton: IconButton,
                                          openPickerIcon: QueryBuilderOutlined,
                                        }}
                                        disabled={slotFields.isCreatedStatus}
                                      />
                                    </FormControl>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                            </>
                          }
                        </>
                      )}
                    </Grid>
                    {!!slotFields.categoryId.length && (
                      <>
                        <Grid item xs={12} lg={6} className={classes.createStepperMainContainer}>
                          <FormControl className={classes.createStepperFormControl} disabled={slotFields.isCreatedStatus}>
                            <InputLabel shrink id="meetings">
                              {t("agenda.create.label.meetings")}
                            </InputLabel>
                            <RadioGroup aria-label="appointmentLocation" name="appointmentLocation" value={slotFields.appointmentLocation} className={classes.customRadioButtonGroup} onChange={(event, value) => slotFieldsChangeHandler(event, value)}>
                              <FormControlLabel value={0} control={<StyledRadio />} label={t("agenda.meetingMode.0")} />
                              <FormControlLabel value={1} control={<StyledRadio />} label={t("agenda.meetingMode.1")} />
                            </RadioGroup>
                          </FormControl>
                          {slotFields.appointmentLocation === 0 && (
                            <>
                              <Autocomplete
                                id="address"
                                freeSolo
                                classes={{
                                  root: classes.addressInputFieldControl,
                                }}
                                name="addressId"
                                hiddenLabel="true"
                                value={addressInput}
                                open={addressAutoCompleteOpen}
                                onOpen={() => {
                                  setAddressAutoCompleteOpen(true)
                                }}
                                onClose={() => {
                                  setAddressAutoCompleteOpen(false)
                                }}
                                onChange={(_,newValue) => {
                                  slotFieldsDateChangeHandler("addressId", newValue ? newValue.id : null)
                                }}
                                onInputChange={(_,newInputValue) => {
                                  searchAddressHandler(newInputValue)
                                }}
                                isOptionEqualToValue={(option, value) => {
                                  return option.id === value.id
                                }}
                                getOptionLabel={(option) => option?.name || ""}
                                options={addressOptions}
                                loading={loaders.isAddressLoading}
                                renderInput={(params) => (
                                  <FormControl fullWidth className={classes.createStepperFormControl} error={errors.addressId}>
                                    <TextField
                                      variant="standard"
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {loaders.isAddressLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                      placeholder={t("layout.navbar.address")}
                                    />
                                    <FormHelperText error>{errors.addressId}</FormHelperText>
                                  </FormControl>
                                )}
                                disabled={slotFields.isCreatedStatus}
                              />

                              <FormControl fullWidth className={clsx(classes.createStepperFormControl, classes.additionalInputFieldContainer)}>
                                <Input
                                  id="address-additional-desc"
                                  name="addressAdditionalDesc"
                                  multiline
                                  placeholder={t("agenda.create.label.additionalInformation")}
                                  value={slotFields.addressAdditionalDesc}
                                  classes={{
                                    root: classes.additionalInputFieldControl,
                                  }}
                                  onChange={slotFieldsChangeHandler}
                                  disabled={slotFields.isCreatedStatus}
                                />
                              </FormControl>
                            </>
                          )}
                          <Grid item xs={12} className={classes.fromControlContainer}>
                            <FormControl fullWidth className={classes.createStepperFormControl} error={errors.buildingIds}>
                              <InputLabel shrink="true" htmlFor="building-ids">
                                {t("general.objects.select")}
                              </InputLabel>
                              <Input
                                id="building-ids"
                                name="buildingIds"
                                value={slotFields.isCreatedStatus ? `${t("general.newObjectsSelected", { count: newBuildingIds?.length })}, ${t("general.objectsSelected", { count: slotFields.buildingIds.length })}` : t("general.objectsSelected", { count: slotFields.appointmentType === 0 ? +slotFields.buildingAppointments?.length : +slotFields.buildingIds.length })}
                                classes={{ root: classes.inputFieldControl }}
                                onClick={objectModalHandler}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <ExpandMore className={classes.inputFieldExpandIcon} />
                                  </InputAdornment>
                                }
                              />
                              <FormHelperText error>{errors.buildingIds}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} className={classes.fromControlContainer}>
                            <FormControl fullWidth className={classes.createStepperFormControl}>
                              <InputLabel shrink="true" htmlFor="description">
                                {t("general.description")}
                              </InputLabel>
                              <Input
                                id="description"
                                name="description"
                                multiline
                                value={slotFields.description}
                                classes={{
                                  root: classes.additionalInputFieldControl,
                                }}
                                onChange={slotFieldsChangeHandler}
                                disabled={slotFields.isCreatedStatus}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} className={classes.fromControlContainer}>
                            <FormControl fullWidth className={classes.createStepperFormControl}>
                              <InputLabel shrink="true" htmlFor="chat-message">
                                {t("general.message")}
                              </InputLabel>
                              <Input
                                id="chat-message"
                                name="chatMessage"
                                multiline
                                value={slotFields.chatMessage}
                                classes={{
                                  root: classes.additionalInputFieldControl,
                                }}
                                onChange={slotFieldsChangeHandler}
                                disabled={slotFields.isCreatedStatus}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </div>
              ) : (
                <PreviewCalenderContext.Provider
                  value={{
                    activeStep,
                    onBack: handleBack,
                    steps,
                    deleteAgendaSlots,
                    handleFilters: setFilters,
                  }}
                >
                  <FullCalendar filteredEvents={events} filters={filters} handleFilters={setFilters} loading={loaders.isSlotsLoading} activeStep={activeStep} />
                </PreviewCalenderContext.Provider>
              )}
            </div>
            
            <div className={classes.stepperBtnContainer}>
            {slotFields.isCreatedStatus ? (
              <Button
              variant="outlined"
              color="primary"
              onClick={updateGenerationSlots}
              className={classes.button}
              disabled={newBuildingIds.length <= 0 || loaders.isUpdateLoading}
            >
              {t("agenda.generationList.update.label")}
            </Button>
            ) :
              slotFields.appointmentType === 0 ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={createAgendaIndividualAppointments}
                className={classes.button}
                disabled={slotFields?.buildingAppointments?.length <= 0 || loaders.isUpdateLoading}
              >
                {t("general.create")}
              </Button>
              ) :
              (
              <>
                <Button
                variant="outlined"
                color="primary"
                isLoading={loaders.isCreateLoading}
                disabled={!generation || events.length < generation.buildingIds.length || loaders.isCreateLoading}
                onClick={activeStep === steps.length - 1 ? finalizeSlots : handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? t("general.create") : t("general.next")}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                disabled={Object.keys(errors).length}
                onClick={activeStep === steps.length - 1 ? history.goBack : generation && events.length >= generation.buildingIds.length ? confirmRegenerateHandler : generateAgendaSlots}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? t("general.draft") : t("agenda.calculateOptions")}
              </Button>
              </>
            )}
              
            </div>
          </div>
          {
            slotFields.appointmentType === 0 ?
            <SelectObjectsAppointmentModal
              open={objectModal}
              buildings={all.filter((x) => x.projectId === selected.projectId)}
              selectedObjects={slotFields.buildingAppointments || []}
              onSave={handleSavebuildingAppointments}
              onClose={handleCloseObjects}
            />
          :
            <SelectMultipleObjectsModal
              open={objectModal}
              buildings={all.filter((x) => x.projectId === selected.projectId).map(x => ({ disableSelect: slotFields.isCreatedStatus && !!slotFields.buildingIds.find(y=> y === x.buildingId), ...x }))}
              selectedObjects={[...(slotFields.buildingIds.map((buildingId) => ({
                buildingId,
              }))),
              ...newBuildingIds
              ]}
              onSave={handleSaveObjects}
              onClose={handleCloseObjects}
              isAgendaSlotLimit={slotFields.isCreatedStatus}
              maxObjects={slotFields.isCreatedStatus ? (slotFields?.slots?.length) + (+slotFields.confirmedAppointmentsCount) : "NA"}
            />
          }
          <Modal open={confirmBack} onClose={confirmRegenerateHandler} aria-labelledby="regenerate-modal-title" aria-describedby="regenerate-modal-description">
            <div className={classes.deleteEventModalPaper}>
              <Typography className={classes.buyersLabel}>{t("agenda.createModal.regenerateWarning.label")}</Typography>
              <div className={classes.buyerBtnContainer}>
                <Button className={classes.eventRemoveModalBtn} onClick={handleRegenerateSlots}>
                  {t("button.action.yes")}
                </Button>
                <Button className={classes.eventRemoveModalBtnClose} onClick={confirmRegenerateHandler}>
                  {t("button.action.no")}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  eventRemoveModalBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    padding: theme.spacing(0.9, 1.2),
    width: 100,
    height: 38,
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down("md")]: {
      height: 35,
      padding: theme.spacing(1.3),
      width: 90,
      fontSize: 14,
    },
  },
  eventRemoveModalBtnClose: {
    background: theme.palette.grey[400],
    color: theme.palette.common.white,
    fontSize: 14,
    padding: theme.spacing(0.9, 1.2),
    width: 100,
    height: 38,
    "&:hover": {
      background: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down("md")]: {
      height: 35,
      padding: theme.spacing(1.3),
      width: 90,
      fontSize: 14,
    },
  },
  buyerBtnContainer: {
    display: "flex",
    justifyContent: "center",
    columnGap: theme.spacing(2),
  },
  buyersLabel: {
    fontSize: 16,
    padding: theme.spacing(3, 0),
    textAlign: "center",
    color: theme.palette.grey[600],
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  deleteEventModalPaper: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    width: "auto",
    maxWidth: 600,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    "&:focus-visible": {
      outline: "none !important",
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(2),
      width: "80%",
      borderRadius: 12,
    },
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(2),
      width: "70%",
      borderRadius: 12,
    },
  },
  customSuccessSnackbar: {
    "& .MuiAlert-standardSuccess": {
      color: theme.palette.grey[900],
      fontSize: 18,
      backgroundColor: theme.palette.green.light,
      borderRadius: 6,
      [theme.breakpoints.between("xs", "md")]: {
        fontSize: 14,
      },
      "& .MuiAlert-icon": {
        color: theme.palette.green.dark,
        fontSize: 25,
        [theme.breakpoints.between("xs", "md")]: {
          fontSize: 20,
        },
      },
    },
    "& .MuiAlert-standardError": {
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.red.light,
      fontSize: 18,
      borderRadius: 6,
      [theme.breakpoints.between("xs", "md")]: {
        fontSize: 14,
      },
      "& .MuiAlert-icon": {
        color: theme.palette.red.dark,
        fontSize: 25,
        [theme.breakpoints.between("xs", "md")]: {
          fontSize: 20,
        },
      },
    },

    [theme.breakpoints.up("sm")]: {
      bottom: theme.spacing(13),
    },
    ["@media (orientation: portrait)"]: {
      bottom: theme.spacing(20),
    },
    [theme.breakpoints.only("xs")]: {
      bottom: theme.spacing(21),
      left: 30,
      right: 30,
    },
  },
  arrowRightContainer: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
  },
  hoursLabelContainer: {
    margin: theme.spacing(-1.5),
  },
  lunchBreakLabelContainer: {
    padding: "0px",
    margin: "-7px",
  },
  startDateContainer: {
    alignItems: "top",
  },
  inputFieldExpandIcon: {
    fill: theme.palette.grey[400],
  },
  additionalInputFieldContainer: {
    paddingTop: theme.spacing(2),
  },
  createStepperOfficeHoursFormControl: {
    width: "100%",
    "& .MuiInputBase-input": {
      paddingLeft: theme.spacing(1),
      fontSize: 14,
      marginTop: 10,
    },
    "& .MuiFormHelperText-root": {
      marginLeft: theme.spacing(1.8),
    }
  },
  positionLabel: {
    position: "absolute",
    top: theme.spacing(-2),
  },
  assignResponsibilityContainer: {
    flexDirection: "row",
    paddingTop: theme.spacing(3),
    "& label": {
      marginBottom: 0,
    },
  },
  customCheckBoxLabel: {
    color: theme.palette.grey[900],
    fontSize: 14,
    fontWeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  modalCustomCheckBox: {
    color: theme.palette.primary.main,
    borderRadius: 4,
    padding: `${theme.spacing(0, 1)} !important`,
    "&.small": {
      "& .MuiSvgIcon-root": {
        width: 16,
        height: 16,
      },
    },
    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
      borderRadius: 20,
    },
  },
  modalCustomCheckBoxChecked: {
    color: theme.palette.primary.main,
    borderRadius: 4,
  },
  arrowRightIcon: {
    margin: theme.spacing(1),
    fill: theme.palette.grey[600],
    alignSelf: "center",
    marginTop: theme.spacing(2),
  },
  durationLabel: {
    color: theme.palette.grey[900],
    fontSize: 14,
    whiteSpace: "nowrap",
  },
  datePickerMainContainer: {
    display: "flex",
    alignItems: "center",
  },
  createStepperDurationFormControl: {
    width: "20%",
  },
  datePickerContainer: {
    display: "flex",
    columnGap: theme.spacing(0.5),
    alignItems: "baseline",

    "& .MuiFormControlLabel-root": {
      margin: 0,
      '&.lunchTimeCheckbox': {
        marginTop: theme.spacing(2.5),
      },
    },
  },
  customDatePicker: {
    margin: 0,
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.2),
      fontSize: 14,
    },
  },
  inputDateFieldControl: {
    "& .MuiInputBase-input": {
      padding: theme.spacing(1.2),
      fontSize: 14,
    },
  },
  inputFieldIcon: {
    fill: theme.palette.primary.main,
    fontSize: 22,
  },
  inputFieldControl: {
    padding: theme.spacing(1.2),
    "& .MuiInputBase-input": {
      fontSize: 14,
    },
  },
  addressInputFieldControl: {
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(0),
    "& .MuiInputBase-root": {
      padding: theme.spacing(1.2),
      fontSize: 14,
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingRight: theme.spacing(4),
    },
    "& .MuiAutocomplete-endAdornment": {
      right: theme.spacing(1),
    },
  },
  additionalInputFieldControl: {
    height: "80px !important",
    display: "flex",
    alignItems: "flex-start",
    overflow: "auto",
    padding: theme.spacing(1, 0),
    "& .MuiInputBase-input": {
      fontSize: 14,
    },
  },
  fromControlContainer: {
    paddingTop: theme.spacing(3),
  },
  customRadioButtonGroup: {
    flexDirection: "row",
    paddingTop: theme.spacing(3.2),
  },
  customRadioBtnRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: `${theme.spacing(0, 1)} !important`,
  },
  customRadioBtnIcon: {
    borderRadius: "50%",
    width: 17,
    height: 17,
    boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.common.white,
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "none",
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.common.white,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: alpha(theme.palette.grey[600], 0.3),
    },
  },
  customRadioBtnCheckedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 17,
      height: 17,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  createStepperMainContainer: {
    "& label + .MuiInput-formControl": {
      marginTop: theme.spacing(2.5),
    },
    "& .MuiPopover-paper": {
      width: "100%",
      boxShadow: theme.shadows[1],
      background: theme.palette.common.white,
      borderRadius: 6,
      padding: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        maxWidth: 510,
      },
    },
    "& .MuiInputBase-root.Mui-disabled": {
      background: alpha(theme.palette.grey[600], 0.05),
      marginTop: theme.spacing(1),
    },
    "& .MuiInputBase-root": {
      height: 43,
      background: alpha(theme.palette.primary.main, 0.05),
      borderRadius: 10,
      marginTop: theme.spacing(1),
      "&:before": {
        borderBottom: "none !important",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none !important",
    },
    "& .MuiSelect-select:focus": {
      borderRadius: 0,
      backgroundColor: "transparent",
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.grey[600],
      fontSize: 14,
      fontWeight: 500,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.grey[600],
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: theme.spacing(1.3),
      paddingRight: theme.spacing(4.6),
      fontSize: 14,
    },
  },
  createStepperFormControl: {
    width: "100%",
    minWidth: "40%",
    "& .MuiInputBase-input": {
      paddingLeft: theme.spacing(1),
      fontSize: 14,
    },
  },
  createStepperSelectIcon: {
    height: 22,
    width: 22,
    background: "none",
    borderRadius: 50,
    fill: theme.palette.grey[400],
    right: theme.spacing(1.2),
  },
  createStepperContainer: {
    padding: theme.spacing(2),
  },
  stepperBtnContainer: {
    display: "flex",
    alignItems: "flex-end",
    borderTop: `1px solid ${alpha(theme.palette.grey[600], 0.19)}`,
    padding: theme.spacing(2),
    justifyContent: "center",
    columnGap: theme.spacing(1.2),
  },
  stepperContent: {
    height: "calc(100% - 60px)",
    overflow: "auto",
  },
  stepperContentContainer: {
    height: "100%",
  },
  stepperContainer: {
    height: "100%",
  },
  activeStepperContainer: {
    height: "calc(100% - 80px)",
  },
  backIcon: {
    fontSize: 24,
    fill: theme.palette.grey[600],
  },
  backIconContainer: {
    position: "absolute",
    left: 16,
    [theme.breakpoints.only("xs")]: {
      left: 0,
    },
  },
  customStepper: {
    width: "50%",
    padding: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      width: "90%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "80%",
    },
  },
  headerContainer: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    position: "relative",
    alignItems: "center",
    borderBottom: `1px solid ${alpha(theme.palette.grey[600], 0.19)}`,
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1),
    },
  },
  container: {
    height: "100%",
    backgroundColor: theme.palette.indigo.light,
    padding: theme.spacing(2.4),
    overflow: "hidden",
  },
  calendarContainer: {
    height: "100%",
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    borderRadius: 6,
  },
}))
