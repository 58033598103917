import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Button,
  Icon,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  Popover,
  Tooltip,
  FormLabel,
  FormGroup,
  FormHelperText,
  RadioGroup,
  Radio,
  Menu,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  MenuList,
  ClickAwayListener,
  ListItemIcon,
  Paper,
  Popper,
  Hidden,
  Slide,
} from "@mui/material";
import { withTheme } from "@mui/styles";
import { Alert } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { MoreVert, LocationOn, OpenInNew, ArrowBack, Clear, Build, Home, FileCopyOutlined, Mail, Share, Assignment, Schedule, Edit, Delete, Contacts, PictureAsPdf } from "@mui/icons-material";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import WorkOrderPDF from "./WorkOrderPDF";
import { history, nl2br, formatDate, authHeader, validateFile, formatTime, getMimeTypefromString } from "../../_helpers";
import { Link } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import Dropzone from "../../components/Dropzone";
import ContactCard from "./RepairRequestContactCard";
import SelectOrganisationPopover from "./SelectOrganisationPopover";
import { URLS } from "../../apis/urls";
import RepairRequestForm from "../Survey/RepairRequestForm";
import DocumentViewer from "../../components/DocumentViewer";
import { apps, userAccountTypeConstants } from "../../_constants";

const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  appBar: {},
  bold: {
    fontWeight: "bold",
  },

  locationOn: {
    position: "absolute",
    zIndex: 1,
  },
  locationMarkPreview: {
    position: "absolute",
  },
  locationPreview: {
    maxWidth: "100%",
    // maxHeight: 200,
    cursor: "pointer",
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `1px 1px 3px ${theme.palette.grey[400]}`,
    objectFit: "contain",
  },
  locationContainer: {
    maxWidth: "100%",
    width: "100%",
    position: "relative",
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  grow: {
    flexGrow: 1,
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  innerContainer: {
    padding: theme.spacing(2, 3, 4),
  },
  block: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 1, 3),
    "&.collapsed": {
      paddingBottom: theme.spacing(1),
    },
  },
  subHeader: {
    padding: theme.spacing(2),
    "& svg": {
      fontSize: 30,
    },
  },
  pdfButton: {
    padding: theme.spacing(0.2),
    outline: "none !important",
  },
  pdfButtonTitle: {
    display: "flex",
    alignItems: "center",
  },
  infoGridRow: {
    "& > div": {
      padding: theme.spacing(0.5, 2),
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "& .MuiInputLabel-outlined": {
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  pdfTextWrapper: {
    position: "absolute",
    top: "8px",
    width: "100%",
    textAlign: 'center',
    fontSize: "24px",
    fontWeight: "bolder"
  },
  imageGallery: {
    width: "100%",
    maxHeight: "15vw",
    height: "calc(100% + 4px)",
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down("xl")]: {
      maxHeight: "19vw",
    },
    [theme.breakpoints.down("xl")]: {
      maxHeight: "19vw",
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: "28vw",
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: "56vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "85vw",
    },
  },
  alert: {
    width: "355px",
    marginBottom: "8px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  dropzoneContainer: {
    width: "25%",
    float: "left",
    padding: theme.spacing(0.5),
    margin: "-2px auto -4px",
    [theme.breakpoints.down("xl")]: {
      width: "33.33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  galleryImageTile: {
    width: "25%",
    float: "left",
    padding: theme.spacing(0.5),
    position: "relative",

    "& > button": {
      position: "absolute",
      top: 0,
      right: 0,
    },
    "& > div": {
      width: "100%",
      padding: "50% 0px",
      height: 0,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("xl")]: {
      width: "33.33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  bigAvatar: {
    margin: "auto",
    width: 120,
    height: 120,
  },
  halfWidth: {
    width: "50%",
    verticalAlign: "top",
  },
  button: {
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  textField: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("xs")]: {
      width: 350,
      maxWidth: "100%",
    },
  },
  attachmentsWrapper: {
    position: "relative"
  },
  thumbnail: {
    width: 50,
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "rgba(0,0,0,0.1)",
    "&.big": {
      width: "100%",
      height: 0,
      padding: "50%",
      cursor: "pointer",
      //borderRadius: theme.spacing(1)
    },
  },
  thumbnailOrg: {
    width: "calc(100% - 16px)",
    margin: theme.spacing(-1, 0),
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize: "contain",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    //overflow: 'hidden',
    background: "none",
    boxShadow: "none",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      padding: theme.spacing(0, 0, 0, 2),
      "& .header svg": {
        fontSize: 30,
      },
      "& > div": {
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTable-root": {
      "& caption": {
        display: "none",
      },
      //marginBottom: theme.spacing(0.5)
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(0, 0.5, 0, 0),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
  formControlCheckList: {
    paddingLeft: theme.spacing(0.5),
    "& .MuiTypography-body1": {
      lineHeight: 1,
    },
  },
  menuContent: {
    "& + .MuiPopover-root .MuiPopover-paper": {
      // maxWidth: ({ matchWidthOnlyXs, matchWidthOnlySm, matchWidthOnlyMd, matchWidthUpMd }) => matchWidthOnlyXs ? '90%' : matchWidthOnlySm ? '70%' : matchWidthOnlyMd ? '60%' : '40%',
      // minWidth: ({ matchWidthOnlyXs, matchWidthOnlySm, matchWidthOnlyMd, matchWidthUpMd }) => matchWidthOnlyXs ? '90%' : matchWidthOnlySm ? '70%' : matchWidthOnlyMd ? '60%' : '40%',
    },
    "& .MuiPopover-paper": {
      borderRadius: 10,
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
    },
    "& ul": {
      padding: theme.spacing(0),
    },
    "& ul > ul": {
      padding: theme.spacing(0.5, 2),
    },
  },
  menuListStyle: {
    padding: theme.spacing(1.875, 1.25),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: 10,
    width: 167,
  },
  menuIcon: {
    marginRight: 10,
  },
  objectInfoTitle: {
    display: "flex",
    alignItems: "center",
  },
  popperMenu: {
    zIndex: theme.zIndex.drawer,
  },
  listItemIcon: {
    minWidth: 35,
  },
});

class Page extends React.Component {
  state = {
    attachmentIndex: null,
    workOrder: null,
    building: null,
    shareAnchorEl: null,
    openAlert: false,
    copiedMessage: "",
    customEmails: [],
    customEmailsValid: true,
    reportPopOverEl: null,
    openRepairRequestMap: false,
    repairRequestCompletionText: null,
    repairRequestNotes: null,
    repairRequestCompletionTextAndNotesLoading: false,
    filesSizeIsInValid: false,
    fileUploadErrorMessage: null,
  };
  objectAdditionalAnchorRef = React.createRef();

  componentDidMount() {
    this.GetRepairRequestCarryOutAsTypeList();
    this.GetWorkOrderDetails();
    this.GetProductServices();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selected || prevProps.selected.buildingId.toUpperCase() !== this.props.selected.buildingId.toUpperCase()) {
      //this.GetWorkOrderDetails();
    }
    if ((!prevState.workOrder && !!this.state.workOrder) || (!!prevState.workOrder && !!this.state.workOrder && prevState.workOrder.resolverId !== this.state.workOrder.resolverId)) {
      if (this.props.selected && this.state.workOrder.buildingId && this.props.selected.buildingId.toUpperCase() !== this.state.workOrder.buildingId.toUpperCase()) {
        var selectedItem = this.props.allBuildings.find((x) => x.buildingId.toUpperCase() === this.state.workOrder.buildingId.toUpperCase());
        if (this.props.app !== apps.aftercare && this.props.selected && selectedItem && selectedItem.projectId !== this.props.selected.projectId) {
          history.push("/werk/" + this.props.selected.projectNo + "/kwaliteitsborging");
        } else {
          this.setState({ building: selectedItem });
        }
      } else if (!this.state.workOrder.buildingId && this.state.workOrder.projectId) {
        var selectedItem = this.props.allBuildings.find((x) => x.projectId.toUpperCase() === this.state.workOrder.projectId.toUpperCase());
        if (this.props.selected && selectedItem && selectedItem.projectId !== this.props.selected.projectId) {
          history.push("/werk/" + this.props.selected.projectNo + "/kwaliteitsborging");
          this.repairRequestRedirectHelper();
        } else {
          this.setState({ building: selectedItem });
        }
      } else {
        if (this.props.app === apps.aftercare && this.state.repairRequest) {
          const selectedItem = this.props.allBuildings.find((x) => {
            return x.projectId.toUpperCase() === this.state.repairRequest.projectId.toUpperCase() && (!this.state.repairRequest.buildingId || x.buildingId.toUpperCase() === this.state.repairRequest.buildingId.toUpperCase());
          });
          this.setState({ building: selectedItem });
        } else this.setState({ building: this.props.selected });
      }
    }
    if (prevProps.match.params.resolverId !== this.props.match.params.resolverId) {
      this.GetWorkOrderDetails();
    }
  }

  GetRepairRequestCarryOutAsTypeList() {
    const url = webApiUrl + "api/RepairRequest/GetRepairRequestCarryOutAsTypeList";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      types: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ carryOutAsTypeList: findResponse });
      });
  }

  getRepairRequestCompletionTextAndNotes() {
    const { completeOrRejectionText, workOrder } = this.state;
    this.setState({ repairRequestCompletionTextAndNotesLoading: true });
    if (workOrder) {
      const url = webApiUrl + "api/RepairRequest/GetRepairRequestCompletionTextAndNotes/" + encodeURI(workOrder.repairRequestId);
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.setState({
            repairRequestCompletionText: findResponse.completionText,
            repairRequestNotes: `Oplossing ${formatDate(new Date())} ${workOrder.name}:\r\n${completeOrRejectionText}${findResponse.notes ? "\r\n\r\n" + findResponse.notes : ""}`,
            repairRequestCompletionTextAndNotesLoading: false,
          });
        });
    }
  }

  GetResolverRelations() {
    const { workOrder } = this.state;
    if (workOrder) {
      const url = webApiUrl + "api/Organisation/GetRelationsbyOrganisationId/" + encodeURI(workOrder.organisatonId);

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.setState({ resolverRelations: findResponse });
        });
    }
  }

  GetEmailsForWorkOrderResolver() {
    const { t } = this.props;
    const { workOrder, isTemporary } = this.state;
    if (workOrder) {
      const url = webApiUrl + "api/RepairRequest/GetEmailsForWorkOrderResolver/" + encodeURI(workOrder.resolverId);

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((emails) => {
          let toEmail = null;
          let ccEmails = [];
          if (isTemporary === true) {
            if (emails.resolverEmails && emails.resolverEmails.length > 0) {
              toEmail = "resolver";
            }
          } else if (workOrder.isHandled !== true && emails.reporterEmails && emails.reporterEmails.length > 0) {
            toEmail = "reporter";
          }

          const emailModel = [
            {
              key: "reporter",
              title: t("general.reporter"),
              emails: emails.reporterEmails,
            },
            {
              key: "buyer",
              title: t("general.objectUser"),
              emails: emails.buyerEmails,
            },
            {
              key: "client",
              title: t("general.client"),
              emails: emails.clientEmails,
            },
            { key: "vve", title: t("general.vvE"), emails: emails.vvEEmails },
            {
              key: "vveadmin",
              title: t("general.vvEAdministrator"),
              emails: emails.vvEAdministratorEmails,
            },
            {
              key: "propertymanager",
              title: t("general.propertyManager"),
              emails: emails.propertyManagerEmails,
            },
            {
              key: "resolver",
              title: t("general.resolver"),
              emails: emails.resolverEmails,
            },
          ];
          this.setState({ emails, emailModel, toEmail, ccEmails });
        });
    }
  }

  GetWorkOrderDetails(update = false) {
    const { resolverId } = this.props.match.params;
    if (resolverId) {
      const url = webApiUrl + "api/RepairRequest/GetWorkOrderDetails/" + encodeURI(resolverId);
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      if (update === false) {
        this.setState({
          workOrder: null,
        });
      }

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((workOrder) => {
          if (!!workOrder.resolverId) {
            let reworkOrganizationName = workOrder.name || null;
            let reworkOrganizationId = workOrder.organisatonId || null;
            workOrder.completed = workOrder.status === 3 || workOrder.status === 4;
            this.setState({
              reworkOrganizationName,
              reworkOrganizationId,
              workOrder,
              isTemporary: workOrder.status == 0 && !workOrder.workOrderNumber,
              completeOrRejectionText: workOrder.status === 3 ? workOrder.rejectionText : workOrder.status === 4 ? workOrder.solutionText : this.state.completeOrRejectionText,
            });
            this.GetResolverRelations();
            if (workOrder.isHandled !== true) {
              this.GetEmailsForWorkOrderResolver();
            }
          }
        });
    }
  }

  updateWorkOrder(key, value) {
    const { t } = this.props;
    const { workOrder } = this.state;

    if (workOrder && workOrder.completed !== true) {
      this.setState({ updating: key });

      const url = webApiUrl + `api/RepairRequest/UpdateWorkOrder/` + encodeURI(workOrder.resolverId);
      const requestOptions = {
        method: "PATCH",
        headers: authHeader("application/json"),
        body: JSON.stringify([
          {
            id: key,
            name: value.toString(),
          },
        ]),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            this.setState({ updating: null, edit: null });
            this.GetWorkOrderDetails(true);
          } else {
            this.setState({ updating: null });
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          this.setState({ updating: null, edit: null });
          alert(t("general.api.error"));
        });
    }
  }

  updateWorkOrderRelation(relationId) {
    const { t } = this.props;
    const { workOrder } = this.state;

    if (workOrder && workOrder.completed !== true) {
      this.setState({ updating: "relation" });

      const url = webApiUrl + `api/RepairRequest/UpdateRepairRequestResolverRelation/` + encodeURI(workOrder.resolverId) + "?relationId=" + relationId;
      const requestOptions = {
        method: "PATCH",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            this.setState({ updating: null, edit: null });
            this.GetWorkOrderDetails(true);
          } else {
            this.setState({ updating: null });
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          this.setState({ updating: null });
          alert(t("general.api.error"));
        });
    }
  }

  deleteResolver = () => {
    const { t, selected } = this.props;
    const { workOrder, isTemporary } = this.state;

    if (workOrder && isTemporary === true) {
      this.setState({ deleting: true });

      const url = webApiUrl + `api/RepairRequest/DeleteRepairRequestResolver/` + encodeURI(workOrder.resolverId);
      const requestOptions = {
        method: "DELETE",
        headers: authHeader("application/json"),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            this.setState({ deleting: false });
            this.repairRequestRedirectHelper(workOrder.repairRequestId);
          } else {
            this.setState({ deleting: false });
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          this.setState({ deleting: false });
          alert(t("general.api.error"));
        });
    }
  };
  repairRequestRedirectHelper(id, state = {}) {
    let url = this.props.app !== apps.aftercare ? "/werk/" + this.props.selected.projectNo + "/kwaliteitsborging" : "/nazorg/melding";
    if (id) url += `/${id}`;
    history.push(url, state);
  }

  workOrderRedirectHelper(resolverId) {
    const url = this.props.app !== apps.aftercare ? "/werk/" + this.props.selected.projectNo + "/werkbon/" + resolverId : `/nazorg/werkbon/${resolverId}`;
    history.push(url);
  }
  initiateWorkOrder = (e) => {
    const { workOrder, isTemporary } = this.state;
    if (workOrder && isTemporary === true) {
      if ((workOrder.explanation && workOrder.explanation.trim() !== "") || workOrder.workOrderText) {
        this.setState({
          anchorElCreateWorkOrder: e.currentTarget,
          customEmails: [],
          customEmailsValid: true,
        });
      } else {
        this.setState({ showCreateWorkOrderErrors: true });
      }
    }
  };

  createWorkOrder = () => {
    const { t, selected } = this.props;
    const { workOrder, isTemporary } = this.state;
    if (workOrder && isTemporary === true) {
      if ((workOrder.explanation && workOrder.explanation.trim() !== "") || workOrder.workOrderText) {
        this.setState({ creating: true });

        let model = this.createModelForEmailNotify();

        const url = webApiUrl + `api/RepairRequest/CreateWorkOrder/` + encodeURI(workOrder.resolverId);
        const requestOptions = {
          method: "POST",
          headers: authHeader("application/json"),
          body: JSON.stringify(model),
        };

        fetch(url, requestOptions)
          .then((Response) => Response.json())
          .then((success) => {
            if (success) {
              this.setState({ creating: false });
              this.repairRequestRedirectHelper(workOrder.repairRequestId);
            } else {
              this.setState({ creating: false });
              alert(t("general.api.error"));
            }
          })
          .catch((e) => {
            this.setState({ creating: false });
            alert(t("general.api.error"));
          });
      } else {
        this.setState({ showCreateWorkOrderErrors: true });
      }
    }
  };

  initiateHandle = (e, handleType) => {
    if (["complete", "reject", "rework"].includes(handleType)) {
      const { workOrder, isTemporary, completeOrRejectionText } = this.state;
      if (workOrder && isTemporary !== true && workOrder.isHandled !== true) {
        if (completeOrRejectionText && completeOrRejectionText.trim() !== "") {
          this.setState({
            anchorElHandleWorkOrder: e.currentTarget,
            handleType,
          });
        } else {
          this.setState({ completeOrRejectionTextError: true });
        }
      }
    }
  };

  handleWorkOrder = () => {
    const { t, selected, app } = this.props;
    const { workOrder, reworkOrganizationId, repairRequestCompletionText, repairRequestNotes, isTemporary, handleType, completeOrRejectionText } = this.state;
    if (workOrder && isTemporary !== true && workOrder.isHandled !== true) {
      if (completeOrRejectionText && completeOrRejectionText.trim() !== "" && ["complete", "reject", "rework"].includes(handleType)) {
        this.setState({ handling: true });

        let notification = this.createModelForEmailNotify();
        let isComplete = false;
        let isCompleteRepairRequest = false;
        switch (handleType) {
          case "complete":
            isComplete = true;
            isCompleteRepairRequest = workOrder.isOnlyOrAllOthersWorkOrderCompleted === true && this.state.isCompleteRepairRequest === true;
            break;
          case "reject":
            isComplete = false;
            break;
          case "rework":
            isComplete = workOrder.status !== 3;
            break;
          default:
            break;
        }

        const model = {
          resolverId: workOrder.resolverId,
          completeOrRejectionText,
          isComplete,
          continuedWorkOrder: handleType === "rework",
          organisationId: reworkOrganizationId,
          isCompleteRepairRequest,
          repairRequestCompletionText: isComplete && isCompleteRepairRequest ? repairRequestCompletionText : "",
          repairRequestNotes: isComplete && isCompleteRepairRequest ? repairRequestNotes : "",
          notification,
        };

        const url = webApiUrl + `api/RepairRequest/UpdateWorkOrderStatus`;
        const requestOptions = {
          method: "POST",
          headers: authHeader("application/json"),
          body: JSON.stringify(model),
        };

        fetch(url, requestOptions)
          .then((Response) => Response.json())
          .then((success) => {
            if (success) {
              this.setState({ anchorElHandleWorkOrder: null, handling: false });
              if (handleType === "rework") {
                this.workOrderRedirectHelper(success.reworkResolverId);
              } else {
                this.repairRequestRedirectHelper(workOrder.repairRequestId);
              }
              this.GetWorkOrderDetails();
            } else {
              this.setState({ handling: false });
              alert(t("general.api.error"));
            }
          })
          .catch((e) => {
            this.setState({ handling: false });
            alert(t("general.api.error"));
          });
      } else {
        this.setState({ showCreateWorkOrderErrors: true });
      }
    }
  };

  createModelForEmailNotify = () => {
    const { isNotify, toEmail, ccEmails, emails, customEmails } = this.state;
    const model = {
      isNotify: isNotify === true,
      toEmails: {
        reporterEmails: toEmail === "reporter" ? emails.reporterEmails : null,
        buyerEmails: toEmail === "buyer" ? emails.buyerEmails : null,
        clientEmails: toEmail === "client" ? emails.clientEmails : null,
        vvEEmails: toEmail === "vve" ? emails.vvEEmails : null,
        vvEAdministratorEmails: toEmail === "vveadmin" ? emails.vvEAdministratorEmails : null,
        propertyManagerEmails: toEmail === "propertymanager" ? emails.propertyManagerEmails : null,
        resolverEmails: toEmail === "resolver" ? emails.resolverEmails : null,
      },
      cCEmails: {
        reporterEmails: ccEmails.includes("reporter") ? emails.reporterEmails : null,
        buyerEmails: ccEmails.includes("buyer") ? emails.buyerEmails : null,
        clientEmails: ccEmails.includes("client") ? emails.clientEmails : null,
        vvEEmails: ccEmails.includes("vve") ? emails.vvEEmails : null,
        vvEAdministratorEmails: ccEmails.includes("vveadmin") ? emails.vvEAdministratorEmails : null,
        propertyManagerEmails: ccEmails.includes("propertymanager") ? emails.propertyManagerEmails : null,
        resolverEmails: ccEmails.includes("resolver") ? emails.resolverEmails : null,
        customEmails: customEmails,
      },
    };

    return model;
  };

  uploadAttachment = (files) => {
    const { workOrder } = this.state;
    const length = workOrder.solutionImages.length + workOrder.solutionPdfs.length
    if (files.length + length > 20) {
      this.setState({
        filesSizeIsInValid: true,
        fileUploadErrorMessage: "files.noOfFilesLimit.error",
      });
      return;
    }
    let filesToUpload = [];
    for (let i = 0; i < files.length; i++) {
      if (validateFile({ file: files[i], allowImage: true, allowPdf: true }) === true) {
        filesToUpload.push(files[i]);
      }
    }

    if (filesToUpload.length > 0) {
      const { t } = this.props;
      const { workOrder } = this.state;
      this.setState({
        uploading: true,
      });

      if (workOrder && workOrder.isHandled !== true) {
        const formData = new FormData();

        for (var i = 0; i < filesToUpload.length; i++) {
          formData.append("files", filesToUpload[i]);
        }

        const url = webApiUrl + `api/RepairRequest/UploadWorkOrderFiles/` + encodeURI(workOrder.resolverId);
        fetch(url, {
          method: "POST",
          headers: authHeader(),
          body: formData,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success === true) {
              this.setState({
                uploading: false,
              });
              this.GetWorkOrderDetails(true);
            } else {
              this.setState({ uploading: false });
              alert(t("general.api.error"));
            }
          })
          .catch((e) => {
            this.setState({ uploading: false });
            alert(t("general.api.error"));
          });
      }
    }
  };

  handleRemoveAttachFile(attachmentId) {
    const { t } = this.props;
    const { workOrder } = this.state;
    if (workOrder && workOrder.isHandled !== true) {
      const url = webApiUrl + `api/RepairRequest/DeleteRepairRequestAttachment/${workOrder.repairRequestId}/${attachmentId}`;
      const requestOptions = {
        method: "DELETE",
        headers: authHeader("application/json"),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((res) => {
          if (res.success === true) {
            this.GetWorkOrderDetails(true);
          } else {
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          alert(t("general.api.error"));
        });
    }
  }

  handleFileModalOpen = (index, type = "image") => {
    const { workOrder } = this.state;
    const isImage = type === "image"
    const filesAttribute = isImage ? "repairRequestImages" : "repairRequestPdfs"

    this.setState({
      attachmentIndex: index,
      document: [
        {
          uri: URLS.GET_ATTACHMENT + encodeURI(workOrder[filesAttribute][index] ? workOrder[filesAttribute][index].attachmentId : ""),
          fileType: getMimeTypefromString(isImage ? ".jpg" : ".pdf"), //TODO: use mimetype from server after api changes
          name: "",
        },
      ],
    });
  };


  handleFileModalClose = () => {
    this.setState({ attachmentIndex: null, document: null });
  };

  handleFileModal2Open = (index, type = "image") => {
    const { workOrder } = this.state;
    const isImage = type === "image"
    const filesAttribute = isImage ? "solutionImages" : "solutionPdfs"
    this.setState({
      attachmentIndex: index,
      document: [
        {
          uri: URLS.GET_ATTACHMENT + encodeURI(workOrder[filesAttribute][index] ? workOrder[filesAttribute][index].attachmentId : ""),
          fileType: getMimeTypefromString(isImage ? ".jpg" : ".pdf"), //TODO: use mimetype from server after api changes
          name: "",
        },
      ],
    });
  };

  handleClose = (event) => {
    if (this.objectAdditionalAnchorRef.current && this.objectAdditionalAnchorRef.current.contains(event.target)) {
      return;
    }
    this.setState({ openObjectAdditionalMenu: false });
  };
  applyFilterAndRedirect = (e, type, filter) => {
    this.handleClose(e);
    let url = `/nazorg/`;
    let data = {};
    switch (type) {
      case "repairRequest": {
        url += "meldingen";
        data = { selectedProject: this.state.building, filter };
        break;
      }
      case "workOrder": {
        url += "werkbonnen";
        data = { selectedProject: this.state.building, filter };
        break;
      }
      default:
        break;
    }
    history.push(url, data);
  };
  handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.setState({ openObjectAdditionalMenu: false });
    }
  };

  renderEditTextbox(title, key, value, multi = false) {
    const { classes, t } = this.props;
    const { workOrder, updating } = this.state;
    return (
      <div style={{ position: "relative" }}>
        {value && nl2br(value)}
        {workOrder.completed !== true && (
          <>
            {updating === key ? (
              <Icon color="inherit" fontSize="small" style={{ position: "absolute", right: -15, top: 0 }}>
                <CircularProgress size="small" />
              </Icon>
            ) : (
              <>
                <Tooltip title={t("general.editText")}>
                  <IconButton
                    aria-describedby={"edit-" + key}
                    color="inherit"
                    aria-label="edit"
                    component="span"
                    size="small"
                    edge="end"
                    style={{ position: "absolute", right: -15, top: -5 }}
                    onClick={(e) =>
                      this.setState({
                        edit: { key, value, anchorEl: e.currentTarget },
                      })
                    }
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                {this.state.edit && this.state.edit.key === key && (
                  <Popover
                    open={true}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    id={"edit-" + key}
                    anchorEl={this.state.edit.anchorEl}
                    onClose={() => {
                      this.setState({ edit: null });
                    }}
                  >
                    <div style={{ padding: "16px" }}>
                      <Grid container spacing={1} direction="column">
                        <Grid item>
                          <Typography variant="h6">{title}</Typography>
                        </Grid>
                        <Grid item>
                          <TextField
                            className={classes.textField}
                            value={this.state.edit.value}
                            onChange={(e) =>
                              this.setState({
                                edit: {
                                  anchorEl: this.state.edit.anchorEl,
                                  key,
                                  value: e.target.value,
                                },
                              })
                            }
                            margin="dense"
                            variant="outlined"
                            multiline={multi}
                            rows={10}
                            fullWidth
                            autoFocus
                            disabled={workOrder.completed}
                          />
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1} justifyContent="flex-end">
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  this.setState({ edit: null });
                                }}
                              >
                                {t("general.cancel")}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button variant="outlined" onClick={() => this.updateWorkOrder(key, this.state.edit.value)}>
                                {t("general.save")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Popover>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }

  blockGeneral() {
    const { t, classes, selected, location } = this.props;
    const { workOrder, isTemporary } = this.state;
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={clsx(classes.subHeader, classes.pdfButtonTitle)}>
          <Build color="primary" /> &nbsp;
          <div className={classes.grow}>
            {t("general.workOrder")}
            &nbsp;
            {workOrder && (workOrder.workOrderNumber ? workOrder.workOrderNumber : workOrder.repairRequestNo + ".##")}
          </div>
          <WorkOrderPDF resolverId={workOrder && workOrder.resolverId} />
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.resolver") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.name && workOrder.name}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.repairRequest") + ":"}
              </Grid>
              <Grid item xs={6}>
                <Button style={{ padding: 0 }} onClick={() => this.repairRequestRedirectHelper(workOrder.repairRequestId, { fromWorkOrder: true })} color="primary">
                  {workOrder.repairRequestNo && workOrder.repairRequestNo}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {isTemporary !== true && workOrder.relationName && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.relation") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.relationName}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.description") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.description && workOrder.description}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {workOrder && workOrder.previousWorkOrderDetails && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("general.workOrderOld") + ": "}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.previousWorkOrderDetails.workOrderNumber}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.oldDateOfCompletion") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.previousWorkOrderDetails.handledDate && formatDate(new Date(workOrder.previousWorkOrderDetails.handledDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.solutionOld") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {nl2br(workOrder.previousWorkOrderDetails.solution)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.explanationOld") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {nl2br(workOrder.previousWorkOrderDetails.explantion)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.workOrderTextOld") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {nl2br(workOrder.prevWorkOrderText)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("workOrders.workOrderText") + ":"}
              </Grid>
              <Grid item xs={6}>
                {nl2br(workOrder.workOrderText)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {isTemporary !== true && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("general.explanation") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {nl2br(workOrder.explanation)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("general.type") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.carryOutAsType}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.repairRequestDate") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.repairRequestDate && formatDate(new Date(workOrder.repairRequestDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.dateNotified") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.dateNotified && formatDate(new Date(workOrder.dateNotified))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("repairRequest.targetCompletionDate.label") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.targetCompletionDate && formatDate(new Date(workOrder.targetCompletionDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={12}>
                {t("repairRequests.repairRequestAttachments") + ":"}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={12}>
                {((workOrder.repairRequestImages && workOrder.repairRequestImages.length > 0) || (workOrder.repairRequestPdfs && workOrder.repairRequestPdfs.length > 0)) && (
                  <div className={classes.imageGallery}>
                    <Grid container spacing={1}>
                      {(workOrder.repairRequestImages || []).map((attachment, index) => (
                        <Grid key={index} item xs={6} sm={4} xl={3}>
                          <div
                            key={index}
                            class={clsx(classes.thumbnail, "big")}
                            onClick={() => this.handleFileModalOpen(index)}
                            style={{
                              backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${attachment.attachmentId})`,
                            }}
                          />
                        </Grid>
                      ))}
                      {(workOrder.repairRequestPdfs ||[]).map((attachment, index) => (
                        <Grid key={index} item xs={6} sm={4} xl={3}>
                          <div className={classes.attachmentsWrapper} onClick={() => this.handleFileModalOpen(index, "pdf")} style={{ background: "red" }}>
                            <div
                              key={index}
                              class={clsx(classes.thumbnail, "big")}
                              style={{
                                backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${attachment.attachmentId})`,
                              }}
                            >
                            </div>
                            <Typography className={classes.pdfTextWrapper} > <PictureAsPdf color={"error"} /></Typography>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        {workOrder && workOrder.drawingPinLocation && (
          <>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.location") + ":"}
                </Grid>
                <Grid item xs={6} className={classes.locationContainer}>
                  <img
                    alt="map"
                    src={`${webApiUrl}api/Dossier/GetDossierPdfImage/${workOrder.drawingPinLocation.dossierFileId}?page=${workOrder.drawingPinLocation.pageNumber}`}
                    className={classes.locationPreview}
                    onClick={() => this.setState({ openRepairRequestMap: true })}
                  />
                  <LocationOn
                    color="primary"
                    className={classes.locationMarkPreview}
                    style={{
                      left: `calc(${workOrder.drawingPinLocation.xrel} * 100% - 12px)`,
                      top: `calc(${workOrder.drawingPinLocation.yrel} * 100% - 22px)`,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
      </div>
    );
  }

  blockStatus() {
    const { t, classes } = this.props;
    const { workOrder, terms } = this.state;
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Schedule color="primary" /> &nbsp;
          {t("general.status")}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.status") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.status >= 0 && t("resolver.status." + workOrder.status)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("workOrders.completedOn") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.dateHandled && formatDate(new Date(workOrder.dateHandled))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {workOrder.isHandled === true && (
            <>
              {" "}
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.verifiedBy") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.handledBy}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}

          {workOrder.isHandled === true && (
            <>
              {" "}
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.verifiedOn") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.handledOn && formatDate(new Date(workOrder.handledOn)) + " " + formatTime(new Date(workOrder.handledOn))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }

  blockObjectInfo() {
    const { t, classes, app, user } = this.props;
    const { workOrder, building, openObjectAdditionalMenu } = this.state;
    const isBuyer = user && user.type === userAccountTypeConstants.buyer;
    return (
      <div className={classes.block}>
        <div className={classes.objectInfoTitle}>
          <Typography component="h2" variant="h6" className={clsx(classes.subHeader, classes.grow)}>
            <Home color="primary" /> &nbsp;
            {t("general.objectInformation")}
          </Typography>
          {app === apps.aftercare && !isBuyer && (
            <>
              <IconButton
                ref={this.objectAdditionalAnchorRef}
                onClick={() =>
                  this.setState({
                    openObjectAdditionalMenu: !openObjectAdditionalMenu,
                  })
                }
                size="large"
              >
                <MoreVert />
              </IconButton>
              <Popper placement="bottom-end" className={classes.popperMenu} open={!!openObjectAdditionalMenu} anchorEl={this.objectAdditionalAnchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={this.handleClose}>
                        <MenuList autoFocusItem={openObjectAdditionalMenu} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                          <MenuItem onClick={(e) => this.applyFilterAndRedirect(e, "repairRequest", "project")}>
                            <ListItemIcon className={classes.listItemIcon}>
                              <OpenInNew fontSize="small" />
                            </ListItemIcon>
                            <Typography>{t("repairRequest.for.project")}</Typography>
                          </MenuItem>
                          <MenuItem onClick={(e) => this.applyFilterAndRedirect(e, "repairRequest", "building")}>
                            <ListItemIcon className={classes.listItemIcon}>
                              <OpenInNew fontSize="small" />
                            </ListItemIcon>
                            <Typography>{t("repairRequest.for.object")}</Typography>
                          </MenuItem>
                          <MenuItem onClick={(e) => this.applyFilterAndRedirect(e, "workOrder", "project")}>
                            <ListItemIcon className={classes.listItemIcon}>
                              <OpenInNew fontSize="small" />
                            </ListItemIcon>
                            <Typography>{t("workOrders.for.project")}</Typography>
                          </MenuItem>
                          <MenuItem onClick={(e) => this.applyFilterAndRedirect(e, "workOrder", "building")}>
                            <ListItemIcon className={classes.listItemIcon}>
                              <OpenInNew fontSize="small" />
                            </ListItemIcon>
                            <Typography>{t("workOrders.for.object")}</Typography>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          )}
        </div>
        {
          <Grid container>
            {app === apps.aftercare && !isBuyer && (
              <>
                <Grid item xs={12}>
                  <Grid container className={classes.infoGridRow}>
                    <Grid item xs={6}>
                      {t("general.projectName") + ":"}
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        sx={{ padding: 0, minWidth: "auto", textTransform: "none" }}
                        component={Link}
                        to={{
                          pathname: "/dashboard",
                          state: { filter: building && building.projectName },
                        }}
                        color="primary"
                      >
                        {building && building.projectName.replace(`${building.projectNo} - `, "")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container className={classes.infoGridRow}>
                    <Grid item xs={6}>
                      {t("general.projectNumber") + ":"}
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        style={{ padding: 0, minWidth: "auto" }}
                        component={Link}
                        to={{
                          pathname: "/dashboard",
                          state: { filter: building && building.projectName },
                        }}
                        color="primary"
                      >
                        {building && building.projectNo}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.streetAndHouseNumber") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.address &&
                    (workOrder.address.street ? workOrder.address.street : "") + " " + (workOrder.address.houseNo ? workOrder.address.houseNo : "") + (workOrder.address.houseNoAddition ? " " + workOrder.address.houseNoAddition : "")}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.address.postcodeAndCity") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.address && (
                    <>
                      {workOrder.address.postcode ? workOrder.address.postcode + " " : ""}
                      &nbsp;
                      {workOrder.address.place ? workOrder.address.place : ""}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.buildingNumber") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {building && building.buildingNoIntern}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.deliveryDate") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.deliveryDate && formatDate(new Date(workOrder.deliveryDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequests.secondSignatureDate") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.secondSignatureDate && formatDate(new Date(workOrder.secondSignatureDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.endOfMaintenancePeriod") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.maintenanceEndDate && formatDate(new Date(workOrder.maintenanceEndDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("workOrders.endOfWarrantyPeriod") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.warrantyEndDate && formatDate(new Date(workOrder.warrantyEndDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        }
      </div>
    );
  }

  blockWorkOrder() {
    const { t, classes } = this.props;
    const { workOrder, isTemporary, carryOutAsTypeList, resolverRelations, uploading, showCreateWorkOrderErrors } = this.state;
    const completionRejectionLabel = workOrder.isHandled === true && workOrder.status == 3 ? t("repairRequest.reasonOfRejection") : workOrder.isHandled === true && workOrder.status == 4 ? t("workOrders.solution") : t("workOrders.workPerformed");

    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Assignment color="primary" /> &nbsp;
          {t("general.additionalInformation")}
        </Typography>
        <Grid container>
          {isTemporary === true && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("general.type") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="standard" margin="dense" fullWidth disabled={workOrder.completed}>
                      <InputLabel id="carry-out-as-type-select-label">{t("general.type")}</InputLabel>
                      <Select labelId="carry-out-as-type-select-label" id="carry-out-as-type-select" value={workOrder.carryOutAsTypeId} onChange={(e) => this.updateWorkOrder("carryoutastypeid", e.target.value)} label={t("general.type")}>
                        {!carryOutAsTypeList || (carryOutAsTypeList.filter((x) => x.id === workOrder.carryOutAsTypeId).length === 0 && <MenuItem value="">&nbsp;</MenuItem>)}
                        {carryOutAsTypeList &&
                          carryOutAsTypeList.map((t, index) => (
                            <MenuItem key={index} value={t.id}>
                              {t.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("repairRequest.targetCompletionDate.label") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      variant="outlined"
                      margin="dense"
                      format="dd-MM-yyyy"
                      id="start-date-picker"
                      minDate={new Date()}
                      name="startDateTime"
                      value={new Date(workOrder.targetCompletionDate)}
                      onChange={(date) => this.updateWorkOrder("targetcompletiondate", date.toJSON())}
                      autoOk
                      ampm={false}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.relation") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="standard" margin="dense" fullWidth disabled={workOrder.completed}>
                      <InputLabel id="relation-select-label">{t("workOrders.relation")}</InputLabel>
                      <Select labelId="relation-select-label" id="relation-select" value={workOrder.relationId} onChange={(e) => this.updateWorkOrderRelation(e.target.value)} label={t("workOrders.relation")}>
                        {!resolverRelations || (resolverRelations.filter((x) => x.id === workOrder.relationId).length === 0 && <MenuItem value="">&nbsp;</MenuItem>)}
                        {resolverRelations &&
                          resolverRelations.map((t, index) => (
                            <MenuItem key={index} value={t.id}>
                              {t.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color={workOrder && showCreateWorkOrderErrors === true && (!workOrder.explanation || workOrder.explanation.trim() === "") ? "error" : "inherit"}>
                      {`${t("general.explanation")}${workOrder.workOrderText ? "" : "*"}:`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderEditTextbox(t("general.explanation"), "explanation", workOrder.explanation, true)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {isTemporary !== true && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={12}>
                    <TextField
                      label={completionRejectionLabel}
                      value={this.state.completeOrRejectionText}
                      onChange={(e) =>
                        this.setState({
                          completeOrRejectionText: e.target.value,
                        })
                      }
                      margin="dense"
                      variant="outlined"
                      multiline
                      rows={10}
                      required
                      error={this.state.completeOrRejectionTextError === true && (!this.state.completeOrRejectionText || this.state.completeOrRejectionText.trim() === "")}
                      fullWidth
                      autoFocus
                      disabled={workOrder.isHandled === true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {(!!workOrder.workOrderNumber || (workOrder.solutionImages && workOrder.solutionImages.length > 0)) && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={12}>
                    {t("general.attachments") + ":"}
                  </Grid>
                </Grid>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={12}>
                    <div className={classes.imageGallery}>
                      {workOrder.isHandled !== true && (
                        <div className={classes.dropzoneContainer}>
                          <Dropzone onFilesAdded={this.uploadAttachment} disabled={workOrder.isHandled === true} uploading={uploading} accept="image/*, application/pdf" />
                        </div>
                      )}
                      {workOrder.solutionImages &&
                        workOrder.solutionImages.length > 0 &&
                        workOrder.solutionImages.map((file, index) => (
                          <div className={classes.galleryImageTile}>
                            <div
                              onClick={() => this.handleFileModal2Open(index)}
                              style={{
                                backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${file.attachmentId})`,
                              }}
                            ></div>
                            {workOrder.isHandled !== true && (
                              <Tooltip title={t("general.remove")}>
                                <IconButton aria-label="delete" size="small" disabled={uploading} onClick={() => this.handleRemoveAttachFile(file.attachmentId)}>
                                  <Clear />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        ))}
                      {workOrder.solutionPdfs &&
                        workOrder.solutionPdfs.length > 0 &&
                        workOrder.solutionPdfs.map((file, index) => (
                          <div className={classes.galleryImageTile}>
                            <div
                              onClick={() => this.handleFileModal2Open(index, "pdf")}
                              style={{
                                backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${file.attachmentId})`,
                              }}
                            >
                               <Typography className={classes.pdfTextWrapper} > <PictureAsPdf color={"error"} /></Typography>
                            </div>
                           
                            {workOrder.isHandled !== true && (
                              <Tooltip title={t("general.remove")}>
                                <IconButton aria-label="delete" size="small" disabled={uploading} onClick={() => this.handleRemoveAttachFile(file.attachmentId)}>
                                  <Clear />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        ))}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    )
  }

  blockContactInfo() {
    const { t, classes } = this.props;
    const { workOrder } = this.state;
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Contacts color="primary" /> &nbsp;
          {t("general.contactDetails")}
        </Typography>
        <Grid container spacing={1}>
          {workOrder && workOrder.contactInfo && (
            <>
              {workOrder.contactInfo.buyer && (
                <>
                  {workOrder.contactInfo.buyer.type === 0 && (
                    <>
                      <Grid item xs={12}>
                        <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.buyer.p1} pointOfContactType={0} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.buyer1")} />
                      </Grid>
                      {workOrder.contactInfo.buyer.p2 && (
                        <Grid item xs={12}>
                          <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.buyer.p2} pointOfContactType={0} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.buyer2")} />
                        </Grid>
                      )}
                    </>
                  )}
                  {workOrder.contactInfo.buyer.type === 1 && (
                    <Grid item xs={12}>
                      <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.buyer.org} isOrg pointOfContactType={0} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.buyerOrganization")} />
                    </Grid>
                  )}
                </>
              )}
              {workOrder.contactInfo.client && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.client} isOrg pointOfContactType={1} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("repairRequest.client")} />
                </Grid>
              )}
              {workOrder.contactInfo.vvE && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.vvE} isOrg pointOfContactType={2} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.vvE")} />
                </Grid>
              )}
              {workOrder.contactInfo.vvEAdministrator && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.vvEAdministrator} isOrg pointOfContactType={3} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.vvEAdministrator")} />
                </Grid>
              )}
              {workOrder.contactInfo.propertyManagers &&
                workOrder.contactInfo.propertyManagers.map((propertyManager, index) => (
                  <Grid key={index} item xs={12}>
                    <ContactCard hidePointOfContactCheckbox={true} object={propertyManager} isOrg pointOfContactType={4} selectedPointOfContactType={index === 0 && workOrder.pointOfContact} subTitle={t("general.propertyManager")} />
                  </Grid>
                ))}
              {workOrder.contactInfo.employee && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.employee} isOrg pointOfContactType={5} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.employee")} />
                </Grid>
              )}
              <Grid item xs={12}>
                <ContactCard
                  hidePointOfContactCheckbox
                  object={workOrder}
                  isOrg
                  pointOfContactType={-1}
                  subTitle={t("general.resolver") + " - " + t("general.workOrder") + " " + (!workOrder.workOrderNumber ? "(" + t("general.draft") + ")" : workOrder.workOrderNumber)}
                />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }

  updateCustomEmails = (value) => {
    let { customEmails } = this.state;
    var customEmailsValid = true;
    customEmails = value.trim() !== "" ? value.replace(/\s/g, "").split(/[,;]+/) : [];
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (var i = 0; i < customEmails.length; i++) {
      if (customEmails[i] == "" || !regex.test(customEmails[i])) {
        customEmailsValid = false;
        break;
      }
    }
    this.setState({
      customEmails,
      customEmailsValid,
    });
  };

  SendReminderToResolver = () => {
    const { t } = this.props;
    const { workOrder, customEmails, customEmailsValid } = this.state;

    if (customEmailsValid === true && this.canSendReminderToWorkorder() === true) {
      this.setState({ sendingReminder: true });
      let sendReminderModel = {
        resolverIdList: [workOrder.resolverId],
        ccEmails: customEmails,
      };
      const url = webApiUrl + `api/RepairRequest/SendReminder/`;
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify(sendReminderModel),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            this.setState({
              sendingReminder: false,
              customEmails: [],
              customEmailsValid: true,
              sendReminderAnchorEl: null,
            });
            this.GetWorkOrderDetails(true);
          } else {
            this.setState({ sendingReminder: false });
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          this.setState({ sendingReminder: false });
          alert(t("general.api.error"));
        });
    }
  };

  canSendReminderToWorkorder() {
    if (this.state.workOrder != null) {
      if (this.state.workOrder.status === 3 || this.state.workOrder.status === 4 || (this.state.workOrder.status === 0 && !this.state.workOrder.workOrderNumber)) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  renderProjectSelectPopup() {
    const { workOrder, productServices, searchResolversResult, selectProjectLoading } = this.state;
    const { t, classes, selected } = this.props;
    return (
      <Popover
        open={!selectProjectLoading && true}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableRestoreFocus
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={"add-workorder"}
        anchorEl={this.state.selectProjectAnchorEl}
        onClose={() => {
          this.setState({ selectProjectAnchorEl: null });
        }}
      >
        <div style={{ padding: "16px" }}>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <SelectOrganisationPopover
                projectId={selected && selected.projectId}
                productServices={productServices}
                // disabled={repairRequest.completed}
                setLoading={(val) => this.setState({ selectProjectLoading: val })}
                setSelectOrgAnchorEl={(v) => this.setState({ selectProjectAnchorEl: v })}
                onSelect={(item) => {
                  this.setState(
                    {
                      reworkOrganizationName: item.name,
                      reworkOrganizationId: item.id,
                    },
                    () => {
                      this.setState({ selectProjectAnchorEl: null });
                    }
                  );
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Popover>
    );
  }

  GetProductServices() {
    const url = webApiUrl + "api/Home/GetProductServices";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      productServices: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ productServices: findResponse });
      });
  }

  handleShare(e) {
    this.setState({
      shareAnchorEl: e.currentTarget,
    });
  }

  copyWorkOrder(organisationId) {
    const { t, selected } = this.props;
    const { workOrder } = this.state;
    if (workOrder && workOrder.repairRequestCompleted !== true) {
      const url = webApiUrl + `api/RepairRequest/CopyWorkOrder/${organisationId}/${workOrder.resolverId}`;
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((res) => {
          if (res.success === true && !!res.resolverId) {
            this.workOrderRedirectHelper(res.resolverId);
          } else {
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          alert(t("general.api.error"));
        });
    }
  }

  render() {
    const { t, classes, selected } = this.props;
    const { workOrder, productServices, attachmentIndex, openAlert, copiedMessage, repairRequestCompletionText, repairRequestNotes, reworkOrganizationName, shareAnchorEl, isTemporary, isNotify, emails, emailModel, toEmail, ccEmails } = this.state;

    return (
      <Container maxWidth={false} className={classes.mainContainer}>
        <Grid container>
          <Grid item container xs={12} className={classes.container}>
            <AppBar position="sticky" color="inherit" className={classes.appBar}>
              <Toolbar variant="dense">
                <IconButton color="inherit" edge="start" aria-label="go back" component="span" onClick={() => history.goBack()} size="large">
                  <ArrowBack />
                </IconButton>
                <Typography className={clsx(classes.bold, classes.grow)} noWrap>
                  {t("general.workOrder") + " " + (workOrder ? (workOrder.workOrderNumber ? workOrder.workOrderNumber : workOrder.repairRequestNo + ".##") : "")}
                </Typography>
                {workOrder && workOrder.repairRequestCompleted !== true && (
                  <>
                    <Tooltip title={t("workOrders.copyWorkOrder")}>
                      <IconButton
                        color="inherit"
                        aria-label="CopyWorkOrder"
                        component="span"
                        onClick={(e) => {
                          this.setState({
                            copyWorkOrderAnchorEl: e.currentTarget,
                          });
                        }}
                        size="large"
                      >
                        <FileCopyOutlined />
                      </IconButton>
                    </Tooltip>
                    {
                      <Popover
                        open={!!this.state.copyWorkOrderAnchorEl}
                        disableRestoreFocus
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        anchorEl={this.state.copyWorkOrderAnchorEl}
                        onClose={() => this.setState({ copyWorkOrderAnchorEl: null })}
                      >
                        <SelectOrganisationPopover
                          projectId={selected && selected.projectId}
                          productServices={productServices}
                          disabled={workOrder.repairRequestCompleted}
                          selectOrgAnchorEl={this.state.copyWorkOrderAnchorEl}
                          setSelectOrgAnchorEl={(v) => this.setState({ copyWorkOrderAnchorEl: v })}
                          onSelect={(item) => this.copyWorkOrder(item.id)}
                        />
                      </Popover>
                    }
                  </>
                )}
                {workOrder && !!workOrder.workOrderNumber && (
                  <>
                    <Tooltip title={t("general.share")}>
                      <IconButton
                        color="inherit"
                        aria-label="Share"
                        component="span"
                        onClick={(e) => {
                          this.handleShare(e);
                        }}
                        size="large"
                      >
                        <Share />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="share-menu"
                      anchorEl={shareAnchorEl}
                      keepMounted
                      open={Boolean(shareAnchorEl)}
                      onClose={() => {
                        this.setState({ shareAnchorEl: null });
                      }}
                    >
                      {workOrder.completed !== true && (
                        <MenuItem
                          onClick={() => {
                            let Unsubscribelink = `${window.location.origin}/werkbon/${this.props.match.params.resolverId}`;
                            navigator.clipboard.writeText(Unsubscribelink);
                            this.setState({
                              openAlert: true,
                              shareAnchorEl: null,
                              copiedMessage: t("workOrders.task.directLinkCopied"),
                            });
                          }}
                        >
                          <FileCopyOutlined />
                          &nbsp;
                          <span>{t("workOrders.task.directLink")}</span>
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          let Workorderlink = window.location.href;
                          navigator.clipboard.writeText(Workorderlink);
                          this.setState({
                            openAlert: true,
                            shareAnchorEl: null,
                            copiedMessage: t("workOrders.workOrderLinkCopied"),
                          });
                        }}
                      >
                        <FileCopyOutlined />
                        &nbsp;
                        <span>{t("workOrders.workOrderLink")}</span>
                      </MenuItem>
                      {this.canSendReminderToWorkorder() && (
                        <MenuItem
                          onClick={(e) => {
                            this.setState({
                              shareAnchorEl: null,
                              sendReminderAnchorEl: e.currentTarget,
                            });
                          }}
                        >
                          <Mail />
                          &nbsp;
                          <span>{workOrder.status > 0 ? t("workOrders.task.sendReminder") : t("workOrders.task.sendWorkOrder")}</span>
                        </MenuItem>
                      )}
                    </Menu>
                    {!!this.state.sendReminderAnchorEl && (
                      <Dialog
                        open={true}
                        onClose={() =>
                          this.setState({
                            customEmails: [],
                            customEmailsValid: true,
                            sendReminderAnchorEl: null,
                          })
                        }
                        aria-labelledby="form-dialog-title"
                      >
                        <DialogTitle id="send_reminder">{workOrder.status > 0 ? t("workOrders.task.sendReminder") : t("workOrders.task.sendWorkOrder")}</DialogTitle>
                        <DialogContent>
                          <TextField autoFocus disabled rows={2} variant="outlined" value={emails.resolverEmails.join(", ")} margin="dense" id="resolverEmail" label={t("general.email.to")} type="email" fullWidth />
                          <TextField
                            autoFocus
                            disabled={this.state.sendingReminder === true}
                            error={this.state.customEmailsValid !== true}
                            rows={2}
                            variant="outlined"
                            onChange={(e) => this.updateCustomEmails(e.target.value)}
                            margin="dense"
                            id="ccemail"
                            label={t("general.email.cc")}
                            type="email"
                            fullWidth
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            disabled={this.state.sendingReminder === true}
                            onClick={() => {
                              this.setState({
                                customEmails: [],
                                customEmailsValid: true,
                                sendReminderAnchorEl: null,
                              });
                            }}
                            color="primary"
                          >
                            {t("general.cancel")}
                          </Button>
                          <Button
                            disabled={this.state.customEmailsValid !== true || this.state.sendingReminder === true}
                            onClick={() => {
                              this.SendReminderToResolver();
                            }}
                            color="primary"
                          >
                            {workOrder.status > 0 ? t("workOrders.task.sendReminder") : t("workOrders.task.sendWorkOrder")}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    )}
                    <Snackbar
                      open={openAlert}
                      autoHideDuration={6000}
                      onClose={() =>
                        this.setState({
                          openAlert: false,
                          copiedMessage: "",
                        })
                      }
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    >
                      <Alert
                        onClose={() =>
                          this.setState({
                            openAlert: false,
                            copiedMessage: "",
                          })
                        }
                        severity="success"
                      >
                        {copiedMessage}
                      </Alert>
                    </Snackbar>
                  </>
                )}
                {!!workOrder && isTemporary === true && (
                  <>
                    <Tooltip title={t("datatable.label.selectedrows.delete")}>
                      <IconButton color="inherit" aria-label="Delete" component="span" onClick={this.deleteResolver} size="large">
                        <Delete />
                      </IconButton>
                    </Tooltip>
                    <Button aria-describedby="saveDraft" variant="outlined" color="primary" style={{ marginLeft: 12 }} onClick={() => (!!workOrder ? this.repairRequestRedirectHelper(workOrder.repairRequestId) : history.goBack())}>
                      {t("general.saveDraft")}
                    </Button>
                    <Button aria-describedby="createWorkOrderPopup" variant="outlined" color="primary" style={{ marginLeft: 12 }} onClick={this.initiateWorkOrder}>
                      {t("workOrders.create")}
                    </Button>
                    {!!this.state.anchorElCreateWorkOrder && (
                      <Popover
                        open={true}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        id={"createWorkOrderPopup"}
                        anchorEl={this.state.anchorElCreateWorkOrder}
                        onClose={() => {
                          this.setState({ anchorElCreateWorkOrder: null });
                        }}
                      >
                        <div style={{ padding: "16px" }}>
                          <Grid container spacing={1} direction="column">
                            <Grid item>
                              <Typography variant="h6">{t("workOrders.create")}</Typography>
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isNotify === true}
                                    onChange={(e) =>
                                      this.setState({
                                        isNotify: e.target.checked === true,
                                      })
                                    }
                                    name="notify"
                                    color="primary"
                                  />
                                }
                                label={t("workOrders.notifySolverNow")}
                              />
                            </Grid>
                            <Collapse in={isNotify} timeout="auto" unmountOnExit>
                              <Grid item>
                                <FormControl component="fieldset" className={classes.formControlCheckList} required error={toEmail !== "resolver"}>
                                  <FormLabel component="legend">{t("general.email.to")}:</FormLabel>
                                  {toEmail !== "resolver" && <FormHelperText>{t("workOrders.noEmailKnown")}</FormHelperText>}
                                  <FormGroup>
                                    {toEmail === "resolver" && (
                                      <FormControlLabel
                                        control={<Checkbox checked={true} name="to-resolver" />}
                                        label={
                                          <>
                                            {t("general.resolver")}
                                            <br />
                                            <Typography variant="caption">{emails && emails.resolverEmails && emails.resolverEmails.join("; ")}</Typography>
                                          </>
                                        }
                                      />
                                    )}
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <FormControl component="fieldset" className={classes.formControlCheckList}>
                                  <FormLabel component="legend">{t("general.email.cc")}</FormLabel>
                                  <FormGroup>
                                    {emailModel &&
                                      emailModel
                                        .filter((x) => x.key !== toEmail && x.emails && x.emails.length > 0)
                                        .map((item, index) => (
                                          <FormControlLabel
                                            key={index}
                                            control={
                                              <Checkbox
                                                checked={ccEmails.includes(item.key)}
                                                color="primary"
                                                onChange={(e) => {
                                                  if (e.target.checked === true) {
                                                    let ccEmailsToUpdate = ccEmails.slice();
                                                    ccEmailsToUpdate.push(item.key);
                                                    this.setState({
                                                      ccEmails: ccEmailsToUpdate,
                                                    });
                                                  } else {
                                                    this.setState({
                                                      ccEmails: ccEmails.filter((x) => x !== item.key),
                                                    });
                                                  }
                                                }}
                                                name={"cc-" + item.key}
                                              />
                                            }
                                            label={
                                              <>
                                                {item.title}
                                                {ccEmails.includes(item.key) && (
                                                  <>
                                                    {item.emails &&
                                                      item.emails.map((email, index1) => (
                                                        <React.Fragment key={index1}>
                                                          <br />
                                                          <Typography variant="caption">{email}</Typography>
                                                        </React.Fragment>
                                                      ))}
                                                  </>
                                                )}
                                              </>
                                            }
                                          />
                                        ))}
                                    <TextField
                                      autoFocus
                                      error={this.state.customEmailsValid !== true}
                                      rows={2}
                                      variant="outlined"
                                      onChange={(e) => this.updateCustomEmails(e.target.value)}
                                      margin="dense"
                                      id="ccemail"
                                      label="Extra CC"
                                      type="email"
                                      fullWidth
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                            </Collapse>
                            <Grid item>
                              <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      this.setState({
                                        anchorElCreateWorkOrder: null,
                                      });
                                    }}
                                  >
                                    {t("general.cancel")}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button variant="outlined" disabled={(isNotify === true && toEmail !== "resolver") || this.state.customEmailsValid !== true} onClick={() => this.createWorkOrder()}>
                                    {t("workOrders.create")}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Popover>
                    )}
                  </>
                )}
                {!!workOrder && isTemporary !== true && workOrder.isHandled !== true && (
                  <>
                    {
                      <Button aria-describedby="handleWorkOrderPopup" variant="outlined" color="primary" style={{ marginLeft: 12 }} onClick={(e) => this.initiateHandle(e, "reject")}>
                        {t("general.button.reject")}
                      </Button>
                    }
                    {
                      <Button aria-describedby="handleWorkOrderPopup" variant="outlined" color="primary" style={{ marginLeft: 12 }} onClick={(e) => this.initiateHandle(e, "rework")}>
                        {t("workOrders.handle.rework")}
                      </Button>
                    }
                    {
                      <Button aria-describedby="handleWorkOrderPopup" variant="outlined" color="primary" style={{ marginLeft: 12 }} onClick={(e) => this.initiateHandle(e, "complete")}>
                        {t("general.button.complete")}
                      </Button>
                    }
                    {!!this.state.anchorElHandleWorkOrder && this.state.handleType && (
                      <Popover
                        open={true}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        id={"handleWorkOrderPopup"}
                        anchorEl={this.state.anchorElHandleWorkOrder}
                        onClose={() => {
                          this.setState({
                            anchorElHandleWorkOrder: null,
                            isCompleteRepairRequest: false,
                          });
                        }}
                      >
                        <div style={{ padding: "16px" }}>
                          <Grid container spacing={1} direction="column">
                            <Grid item>
                              <Typography variant="h6">{t("workOrders.handle." + this.state.handleType)}</Typography>
                              {this.state.handleType == "rework" && (
                                <Typography noWrap variant="caption">
                                  {reworkOrganizationName}
                                  <IconButton
                                    color="inherit"
                                    aria-label="edit"
                                    component="span"
                                    size="small"
                                    edge="end"
                                    onClick={(e) => {
                                      const selectProjectMethod = !this.state.selectProjectMethod ? 0 : this.state.selectProjectMethod;
                                      const selectProjectSearchTerm = !this.state.selectProjectSearchTerm ? null : this.state.selectProjectSearchTerm;
                                      this.setState({
                                        selectProjectAnchorEl: e.currentTarget,
                                        selectProjectMethod,
                                        selectProjectSearchTerm,
                                      });
                                    }}
                                  >
                                    {this.state.selectProjectLoading ? <CircularProgress size={20} /> : <Edit fontSize="small" />}
                                  </IconButton>
                                </Typography>
                              )}
                              {!!this.state.selectProjectAnchorEl && this.renderProjectSelectPopup()}
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isNotify === true}
                                    onChange={(e) =>
                                      this.setState({
                                        isNotify: e.target.checked === true,
                                      })
                                    }
                                    name="notify"
                                    color="primary"
                                  />
                                }
                                label={t("workOrders.notifyNow")}
                              />
                            </Grid>
                            <Collapse in={isNotify} timeout="auto" unmountOnExit>
                              <Grid item>
                                <FormControl component="fieldset" className={classes.formControlCheckList} required error={!toEmail || toEmail.trim() === ""}>
                                  <FormLabel component="legend">{t("general.email.to")}</FormLabel>
                                  {!toEmail || (toEmail.trim() === "" && <FormHelperText>{t("workOrders.noEmailKnown")}</FormHelperText>)}
                                  <RadioGroup
                                    aria-label="toEmail"
                                    name="toEmail"
                                    value={toEmail}
                                    onChange={(e) => {
                                      this.setState({
                                        toEmail: e.target.value,
                                        ccEmails: ccEmails.filter((x) => x !== e.target.value),
                                      });
                                    }}
                                  >
                                    {emailModel &&
                                      emailModel
                                        .filter((x) => x.emails && x.emails.length > 0)
                                        .map((item, index) => (
                                          <FormControlLabel
                                            key={index}
                                            value={item.key}
                                            control={<Radio />}
                                            label={
                                              <>
                                                {item.title}
                                                {toEmail === item.key && (
                                                  <>
                                                    {item.emails &&
                                                      item.emails.map((email, index1) => (
                                                        <React.Fragment key={index1}>
                                                          <br />
                                                          <Typography variant="caption">{email}</Typography>
                                                        </React.Fragment>
                                                      ))}
                                                  </>
                                                )}
                                              </>
                                            }
                                          />
                                        ))}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <FormControl component="fieldset" className={classes.formControlCheckList}>
                                  <FormLabel component="legend">{t("general.email.cc")}</FormLabel>
                                  <FormGroup>
                                    {emailModel &&
                                      emailModel
                                        .filter((x) => x.key !== toEmail && x.emails && x.emails.length > 0)
                                        .map((item, index) => (
                                          <FormControlLabel
                                            key={index}
                                            control={
                                              <Checkbox
                                                checked={ccEmails.includes(item.key)}
                                                color="primary"
                                                onChange={(e) => {
                                                  if (e.target.checked === true) {
                                                    let ccEmailsToUpdate = ccEmails.slice();
                                                    ccEmailsToUpdate.push(item.key);
                                                    this.setState({
                                                      ccEmails: ccEmailsToUpdate,
                                                    });
                                                  } else {
                                                    this.setState({
                                                      ccEmails: ccEmails.filter((x) => x !== item.key),
                                                    });
                                                  }
                                                }}
                                                name={"cc-" + item.key}
                                              />
                                            }
                                            label={
                                              <>
                                                {item.title}
                                                {ccEmails.includes(item.key) && (
                                                  <>
                                                    {item.emails &&
                                                      item.emails.map((email, index1) => (
                                                        <React.Fragment key={index1}>
                                                          <br />
                                                          <Typography variant="caption">{email}</Typography>
                                                        </React.Fragment>
                                                      ))}
                                                  </>
                                                )}
                                              </>
                                            }
                                          />
                                        ))}
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                            </Collapse>
                            {this.state.handleType === "complete" && workOrder.isOnlyOrAllOthersWorkOrderCompleted === true && (
                              <Grid item>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.isCompleteRepairRequest === true}
                                      onChange={(e) => {
                                        this.setState({
                                          isCompleteRepairRequest: e.target.checked === true,
                                        });
                                        e.target.checked === true && this.getRepairRequestCompletionTextAndNotes();
                                      }}
                                      name="notify"
                                      color="primary"
                                    />
                                  }
                                  label={t("workOrders.handle.completerepairrequest")}
                                />
                                {this.state.isCompleteRepairRequest === true && (
                                  <>
                                    {" "}
                                    {!this.state.repairRequestCompletionTextAndNotesLoading ? (
                                      <Alert className={classes.alert} severity="warning">
                                        {t("repairRequest.action.completionTextWarning")}
                                      </Alert>
                                    ) : (
                                      <div className={classes.loader}>
                                        <CircularProgress size={20} />
                                      </div>
                                    )}
                                    {!this.state.repairRequestCompletionTextAndNotesLoading ? (
                                      <>
                                        <Grid item>
                                          <TextField
                                            label={t("repairRequest.solution")}
                                            className={classes.textField}
                                            value={repairRequestCompletionText}
                                            onChange={(e) => {
                                              this.setState({
                                                repairRequestCompletionText: e.target.value,
                                              });
                                            }}
                                            margin="dense"
                                            variant="outlined"
                                            multiline
                                            rows={10}
                                            fullWidth
                                            autoFocus
                                            error={!repairRequestCompletionText || repairRequestCompletionText.trim() === ""}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <TextField
                                            label={t("repairRequest.internalNotes")}
                                            className={classes.textField}
                                            value={repairRequestNotes}
                                            onChange={(e) => {
                                              this.setState({
                                                repairRequestNotes: e.target.value,
                                              });
                                            }}
                                            margin="dense"
                                            variant="outlined"
                                            multiline
                                            rows={10}
                                            fullWidth
                                            error={!repairRequestNotes || repairRequestNotes.trim() === ""}
                                          />
                                        </Grid>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </Grid>
                            )}
                            <Grid item>
                              <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    disabled={this.state.repairRequestCompletionTextAndNotesLoading}
                                    onClick={() => {
                                      this.setState({
                                        anchorElHandleWorkOrder: null,
                                      });
                                    }}
                                  >
                                    {t("general.cancel")}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    disabled={
                                      (this.state.isCompleteRepairRequest && (!repairRequestCompletionText || repairRequestCompletionText.trim() === "")) ||
                                      this.state.repairRequestCompletionTextAndNotesLoading ||
                                      (isNotify === true && (!toEmail || toEmail.trim() === ""))
                                    }
                                    onClick={() => this.handleWorkOrder()}
                                  >
                                    {t("workOrders.handle." + this.state.handleType)}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Popover>
                    )}
                  </>
                )}
              </Toolbar>
            </AppBar>
            {workOrder && (
              <Grid item xs={12}>
                <Grid container spacing={2} className={classes.innerContainer} alignItems="flex-start">
                  <Grid item xs={12} md={6} lg={4}>
                    {this.blockGeneral()}
                  </Grid>
                  <Hidden lgDown>
                    <Grid item xs={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {this.blockWorkOrder()}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Hidden>

                  <Grid item xs={12} md={6} lg={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {this.blockStatus()}
                      </Grid>
                      <Grid item xs={12}>
                        {this.blockObjectInfo()}
                      </Grid>
                      <Grid item xs={12}>
                        {this.blockContactInfo()}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Hidden lgUp>
                    <>
                      <Grid item xs={12} md={6}>
                        {this.blockWorkOrder()}
                      </Grid>
                    </>
                  </Hidden>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {<DocumentViewer radius={classes.docRadius} isSurveying open={attachmentIndex != null && this.state.document} onClose={this.handleFileModalClose} documents={this.state.document} />}

        <RepairRequestForm
          isDrawingLocation
          isCreateRepairRequest={false}
          projectId={this.state.workOrder && this.state.workOrder.projectId}
          isReadOnly
          requestId={this.state.workOrder && this.state.workOrder.repairRequestId}
          openRepairRequestMap={this.state.openRepairRequestMap}
          onClose={(reload) => this.setState({ openRepairRequestMap: false })}
        />
        <Snackbar
          TransitionComponent={(props) => (
            <Slide {...props} direction="left" />
          )}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.filesSizeIsInValid }
          onClose={() =>
            this.setState((p) => ({ ...p, filesSizeIsInValid: false }))
          }
          autoHideDuration={3000}
          key={"file-size-validation"}
          style={{top:"80px", position:"fixed"}}
        >
          <Alert  elevation={6} variant="filled" severity="error">
            {t(this.state.fileUploadErrorMessage, { noOfFilesAllowed: 20 })}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, buildings, app } = state;
  const { user } = authentication;
  const { selected, all } = buildings;
  const allBuildings = all;
  return {
    user,
    app,
    selected,
    allBuildings,
  };
}

const connectedPage = connect(mapStateToProps)(withTheme(withTranslation()(withStyles(styles)(Page))));
export { connectedPage as WorkOrderDetailsPage };
