export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    VIEW_AS_REQUEST: 'USERS_VIEW_AS_REQUEST',
    VIEW_AS_SUCCESS: 'USERS_VIEW_AS_SUCCESS',
    VIEW_AS_FAILURE: 'USERS_VIEW_AS_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    /* Constants for forgot password */
    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    FORGOTPASSWORD_REQUEST: 'USER_FORGOTPASSWORD_REQUEST',
    FORGOTPASSWORD_SUCCESS: 'USER_FORGOTPASSWORD_SUCCESS',
    FORGOTPASSWORD_FAILURE: 'USER_FORGOTPASSWORD_FAILURE',

    UPDATEPASSWORD_REQUEST: 'USER_UPDATEPASSWORD_REQUEST',
    UPDATEPASSWORD_SUCCESS: 'USER_UPDATEPASSWORD_SUCCESS',
    UPDATEPASSWORD_FAILURE: 'USER_UPDATEPASSWORD_FAILURE'
};
