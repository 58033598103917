import React from "react";
import { connect } from "react-redux";
import {
    Container,
    useMediaQuery,
    Typography,
    Checkbox,
    IconButton,
    Tooltip,
    FormControlLabel,
    useTheme,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { Schedule, DashboardOutlined, MapOutlined, Image } from "@mui/icons-material"
import { authHeader, nl2br, history, setLocalStorageItem, getLocalStorageItem, generateLocalStorageKey, formatDate } from "../../_helpers"
import { withTranslation } from 'react-i18next';
import { URLS } from "../../apis/urls";
import DataGrid from "../../components/DataGrid";
import WorkOrderRequestCustomRow from "./WorkOrderRequestCustomRow";
import { ImageColumn, LabelColumn, LinkColumn } from "../../components/DataColumns";
import DataGridSetting from "../../components/DataGridSetting";
import { apps } from "../../_constants";

const { webApiUrl } = window.appConfig;

const styles = theme => ({
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        padding: 0
    },
    warning: {
        color: theme.palette.warning.main
    },


    iconHeader: {
        marginRight: theme.spacing(-3)
    },
    paper: {
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        borderRadius: 7,
    },
    CustomCheckbox: {
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 2px',
        borderRadius: '6px',
        padding: theme.spacing(0.6, 1.2),
    },
    noWrapText: {
        whiteSpace: "nowrap"
    }
});


class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSize: 20,
            fontSize: 12,
            workOrders: [],
            columnsVisibility: [
                { name: "isRequiredHandling", visible: true },
                { name: "repairRequestImages", visible: true },
                { name: "workOrderNumber", visible: true },
                { name: "description", visible: true },
                { name: "buildingNoIntern", visible: true },
                { name: "addressText", visible: true },
                { name: "address_postcode", visible: true },
                { name: "address_place", visible: true },
                { name: "targetCompletionDate", visible: true },
                { name: "statusText", visible: true },
                { name: "name", visible: true },
                { name: "repairRequestLocation", visible: true },
            ],
            isLoading: true,
            defaultFilter: [...Array(14)].map(() => []),
            view: false,
            selectedRepairRequest: null,
            filterType: '',
            canListType: false
        };
        this.getWorkOrdersForPrintAndDownload = this.getWorkOrdersForPrintAndDownload.bind(this);
    }

    componentDidMount() {
        const { selected, location, t } = this.props;
        let { columnsVisibility, defaultFilter } = this.state;
        if (location.state && location.state.filter) {
            let filter = [...defaultFilter];
            switch (location.state.filter) {
                case "newWorkOrder": {
                    filter = Object.assign(filter, { [11]: [t("resolver.status.0")] });
                    break;
                }
                case "openWorkOrders": {
                    filter = Object.assign(filter, { [11]: [t("resolver.status.1")] });
                    break;
                }
                case "overdueWorkOrders": {
                    filter = Object.assign(filter, { [2]: [t("general.overdue")] });
                    break;
                }
                case "subContractorName": {
                    filter = Object.assign(filter, { [13]: [location.state.contractor] });
                    break;
                }
                default: { break }
            }
            if (location.state.subContractorName)
                filter = Object.assign(filter, { [13]: [location.state.subContractorName] });
            this.setState({ filterType: location.state })
            const state = { ...this.props.history.location.state };
            delete state.filter;
            history.replace({ ...this.props.history.location, state });
            this.setState({ defaultFilter: filter })
            setLocalStorageItem(`ResolverWorkOrders_Columns_Filters`, filter);

        } else {
            const filter = getLocalStorageItem(`ResolverWorkOrders_Columns_Filters`);
            filter && this.setState({ defaultFilter: filter })
        }

        const localColumnsVisibility = getLocalStorageItem("ResolverWorkOrders_ColumnsVisibility");
        if (localColumnsVisibility) columnsVisibility = localColumnsVisibility;

        else setLocalStorageItem("ResolverWorkOrders_ColumnsVisibility", columnsVisibility);
        if (selected) {
            this.UpdateWorkOrders(this.state.isLoading);
        }
        var intervalId = setInterval(this.timer, 10000);
        // store intervalId in the state so it can be accessed later:
        const size = getLocalStorageItem("ResolverWorkOrders_List_Image_Size");
        const fontSizeLocal = getLocalStorageItem("ResolverWorkOrders_Grid_Font_Size");
        let canListType = getLocalStorageItem("ResolverWorkOrders_Grid_Style");
        canListType = canListType === null ? this.props.isMobileOrTab : canListType;

        this.setState({ intervalId: intervalId, columnsVisibility, fontSize: +fontSizeLocal || this.state.fontSize, imageSize: +size || this.state.imageSize, canListType });
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    componentDidUpdate(prevProps, prevState) {
        const { selected } = this.props;
        if (((!prevProps.selected || prevProps.selected.projectId !== selected.projectId) || this.props.location.key !== prevProps.location.key)) {
            this.UpdateWorkOrders(true);
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let canListType = getLocalStorageItem("ResolverWorkOrders_Grid_Style");
        canListType = canListType === null ? nextProps.isMobileOrTab : canListType;
        this.setState({ canListType });
    }

    isColumnVisible = (columnName) => {
        const { columnsVisibility } = this.state;
        const isSmallScreen = true; //isWidthDown('sm', width);
        var column = columnsVisibility.find((x) => x.name === columnName);
        if (column) {
            return column.visible;
        } else if (isSmallScreen) {
            return false;
        } else {
            return true;
        }
    };

    UpdateWorkOrders(loading = false) {
        const { t, selected, buildings, app } = this.props;
        if (selected) {
            this.setState({ isLoading: loading, workOrders: [] })
            const url = `${webApiUrl}api/RepairRequest/GetWorkOrdersByProjectId/${encodeURI(selected.projectId)}?app=${app}`;
            const requestOptions = {
                method: "GET",
                headers: authHeader(),
            };

            fetch(url, requestOptions)
                .then((Response) => Response.json())
                .then((findResponse) => {
                    if (app === apps.resolverModule && !findResponse.length) {
                        history.push('/werkbonnen');
                    } else {
                        findResponse.forEach((workOrder, index) => {
                            var building = buildings.find((x) => x.buildingId === workOrder.buildingId);

                            workOrder.buildingNoIntern = building && building.buildingNoIntern;
                            workOrder.buildingNoExtern = building && building.buildingNoExtern;

                            workOrder.buildingNoIntern = building && building.buildingNoIntern;
                            workOrder.buildingNoExtern = building && building.buildingNoExtern;

                            workOrder.statusText = t("resolver.status." + workOrder.status);
                            if (workOrder.address) {
                                workOrder.addressText =
                                    (!!workOrder.address.street ? workOrder.address.street : "") +
                                    " " +
                                    (!!workOrder.address.houseNo ? workOrder.address.houseNo : "") +
                                    (!!workOrder.address.houseNoAddition
                                        ? " " + workOrder.address.houseNoAddition
                                        : "");

                                workOrder.address_postcode = workOrder.address.postcode;
                                workOrder.address_place = workOrder.address.place;
                            }
                        });
                        this.setState({ workOrders: findResponse, isLoading: false });
                    }
                });
        }
    }

    overViewHandler = () => {
        const werkBonnenUrl = this.props.selected ? '/werk/' + this.props.selected.projectNo + "/werkbonnen/onderaannemer" : '';
        history.push(werkBonnenUrl)
    }

    listToMapRedirectHandler = (_, selectedRequest) => {
        const { t } = this.props;
        history.push({
            pathname: `/werk/${this.props.selected.projectNo}/${this.props.app === 3 ? "kwaliteitsborging/meldingen/map" : "meldingen/map"}`,
            state: {
                selectedRequest: {
                    ...selectedRequest,
                    desc: selectedRequest.description,
                    textResolvers: [selectedRequest.name],
                    attachments: selectedRequest.repairRequestImages || [],
                    number: selectedRequest.workOrderNumber,
                    status: t("resolver.status." + selectedRequest.status),
                },
                requests: this.state.workOrders.map(p => ({
                    ...p,
                    desc: selectedRequest.description,
                    textResolvers: [p.name],
                    attachments: p.repairRequestImages || [],
                    number: p.workOrderNumber,
                    status: t("resolver.status." + p.status),
                })),
                buildingId: selectedRequest.buildingId
            }
        })
    }


    handleResizeImage(value) {
        this.setState({ imageSize: +value });
        setLocalStorageItem("ResolverWorkOrders_List_Image_Size", +value);
    }
    handleResizeFont(value) {
        this.setState({ fontSize: +value });
        setLocalStorageItem("ResolverWorkOrders_Grid_Font_Size", +value);
    }

    handleChangeGridStyle(val) {
        this.setState({ canListType: val })
        setLocalStorageItem("ResolverWorkOrders_Grid_Style", val);
    }

    async getWorkOrdersForPrintAndDownload(tableRef) {
        const { t } = this.props;
        const { displayData, data: tableData, columns } = tableRef.current.state;
        const data = displayData.map(a => tableData.find(p => p.index === a.dataIndex));
        return data.map(a => {
            const workOrder = this.state.workOrders.find((x) => x.resolverId === a.data[0]);
            return columns.reduce((prev, c, i) => {
                const obj = { ...prev };
                const selectedCellData = a.data[i];
                if (c.name === "isRequiredHandling") {
                    const array = [];
                    if (workOrder.overdue) array.push({ icon: "Schedule", color: "error" })
                    else if (workOrder.is48HoursReminder) array.push({ icon: "Schedule", color: "warning" })
                    obj[c.name] = array;
                }
                else if (c.name.toLowerCase().includes("date")) obj[c.name] = formatDate(new Date(selectedCellData))
                else obj[c.name] = typeof selectedCellData === "string" ? t(selectedCellData) : selectedCellData;
                return obj
            }, {})
        });
    }

    render() {
        const { t, classes, selected } = this.props;
        const { workOrders, columnsVisibility, defaultFilter, imageSize, fontSize } = this.state;
        const columns = [
            {
                name: "resolverId",
                options: {
                    display: "excluded",
                    filter: false,
                    print: false,
                    download: false,
                },
            },
            {
                name: "isRequiredHandling",
                label: " ",
                options: {
                    display: this.isColumnVisible("isRequiredHandling"),
                    filter: false,
                    print: true,
                    maxWidth: 70,
                    download: false,
                    customHeadLabelRender: (columnMeta) => {
                        return <div className={classes.iconHeader}>
                            <Schedule fontSize="small" />
                        </div>
                    },
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return ""
                        return (
                            <div className={classes.noWrapText}>
                                {workOrder.overdue ? (
                                    <Tooltip title={<Typography>{t("general.overdue")}</Typography>}>
                                        <Schedule fontSize="small" color="error" />
                                    </Tooltip>
                                ) : workOrder.is48HoursReminder ? (
                                    <Tooltip title={<Typography>{t("general.48hours")}</Typography>}>
                                        <Schedule fontSize="small" className={classes.warning} />
                                    </Tooltip>
                                ) : ("")}
                            </div>
                        );
                    },
                },
            },
            {
                name: "overdue",
                label: " ",
                options: {
                    display: "excluded",
                    filter: true,
                    filterList: defaultFilter ? defaultFilter[2] : [],
                    print: false,
                    download: false,
                    filterType: 'custom',
                    filterOptions: {
                        names: [t('general.overdue')],
                        label: t('general.overdue'),
                        logic: (value, filters, row) => {
                            if (filters.length && filters.includes(t('general.overdue'))) return !value;
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            return (
                                <div className={classes.CustomCheckbox}>
                                    <FormControlLabel control={<Checkbox checked={!!filterList.find(p => p[0] === column.filterOptions.label)} color="primary"
                                        onChange={event => {
                                            if (event.nativeEvent.target.checked)
                                                filterList[index][0] = column.filterOptions.label;
                                            else
                                                filterList[index] = [];
                                            onChange(filterList[index], index, column);
                                        }}
                                    />} label={column.filterOptions.label} />
                                </div>
                            )
                        }
                    },
                },
            },
            {
                name: "repairRequestImages",
                label: t("general.image"),
                options: {
                    display: this.isColumnVisible("repairRequestImages"),
                    filter: false,
                    sort: false,
                    maxWidth: 60,
                    print: true,
                    download: false,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder || !workOrder.repairRequestImages) return ""
                        var imageId = workOrder.repairRequestImages.length > 0 && workOrder.repairRequestImages[0].attachmentId;
                        return <ImageColumn size={imageSize} src={imageId ? URLS.GET_ATTACHMENT_THUMBNAIL + imageId : null} />
                    },
                    customHeadLabelRender: (_) => {
                        return <div style={{ textAlign: "center" }}><Image /></div>
                    }
                },
            },
            {
                name: "workOrderNumber",
                label: t("general.number"),
                options: {
                    display: this.isColumnVisible("workOrderNumber"),
                    filter: false,
                    maxWidth: 90,
                    sort: true,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return ""
                        var { resolverId, workOrderNumber } = workOrder;
                        return <LinkColumn className={classes.noWrapText} label={workOrderNumber} to={{ pathname: "/werk/" + selected.projectNo + "/werkbon/" + resolverId, state: { fromWorkOrderGrids: true } }} />
                    },
                },
            },
            {
                name: "description",
                label: t("general.description"),
                options: {
                    filter: false,
                    maxWidth: 110,
                    display: this.isColumnVisible("description"),
                    sort: true,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return ""
                        return (
                            <LabelColumn
                                label={workOrder.description}
                                tooltipLabel={<>
                                    {nl2br(workOrder.workOrderText)}
                                    {workOrder.explanation && (
                                        <>
                                            <br />
                                            {nl2br(workOrder.explanation)}
                                        </>
                                    )}
                                </>}
                            />
                        );
                    },
                },
            },
            {
                name: "buildingNoIntern",
                label: t("general.buildingNumber"),
                options: {
                    display: this.isColumnVisible("buildingNoIntern"),
                    maxWidth: 120,
                    filter: true,
                    filterList: defaultFilter ? defaultFilter[6] : [],
                    filterType: "multiselect",
                    sort: true,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return ""
                        return <LabelColumn tooltipLabel={workOrder.buildingNoIntern} label={workOrder.buildingNoIntern} />
                    },
                },
            },
            {
                name: "addressText",
                label: t("general.objectAddress"),
                options: {
                    maxWidth: 130,
                    display: this.isColumnVisible("addressText"),
                    filter: false,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return ""
                        return <LabelColumn tooltipLabel={workOrder.addressText} label={workOrder.addressText} />
                    },
                    sort: true,
                },
            },
            {
                name: "address_postcode",
                label: t("general.address.postcode"),
                options: {
                    maxWidth: 90,
                    display: this.isColumnVisible("address_postcode"),
                    filter: false,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return ""
                        return <LabelColumn tooltipLabel={workOrder.address_postcode} label={workOrder.address_postcode} />
                    },
                    sort: true,
                },
            },
            {
                name: "address_place",
                label: t("general.address.city"),
                options: {
                    display: this.isColumnVisible("address_place"),
                    filter: false,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return ""
                        return <LabelColumn tooltipLabel={workOrder.address_place} label={workOrder.address_place} />
                    },
                    maxWidth: 100,
                    sort: true,
                },
            },
            {
                name: "targetCompletionDate",
                label: t("repairRequest.targetCompletionDate.shortLabel"),
                options: {
                    display: this.isColumnVisible("targetCompletionDate"),
                    maxWidth: 200,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return "";
                        return <LabelColumn date={new Date(workOrder.targetCompletionDate)} />
                    },
                    filter: false,
                    sort: true,
                },
            },
            {
                name: "statusText",
                label: t("general.status"),
                options: {
                    display: this.isColumnVisible("statusText"),
                    filter: true,
                    maxWidth: 100,
                    filterType: "multiselect",
                    filterList: defaultFilter ? defaultFilter[11] : [],
                    sort: true,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return ""
                        return <LabelColumn label={workOrder.statusText} tooltipLabel={workOrder.statusText} color={workOrder && workOrder.overdue && "#f00"} />
                    },
                },
            },

            {
                name: "relationName",
                label: t("workOrders.relation"),
                options: {
                    display: this.isColumnVisible("relationName"),
                    maxWidth: 150,
                    filter: true,
                    filterList: defaultFilter ? defaultFilter[12] : [],
                    filterType: "multiselect",
                    sort: true,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return ""
                        return <LabelColumn tooltipLabel={workOrder.relationName} label={workOrder.relationName} />
                    },
                },
            },
            {
                name: "name",
                label: t("general.name"),
                options: {
                    display: this.isColumnVisible("name"),
                    filter: true,
                    maxWidth: 200,
                    filterType: "multiselect",
                    filterList: defaultFilter ? defaultFilter[13] : [],
                    sort: true,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return ""
                        return <LabelColumn tooltipLabel={workOrder.name} label={workOrder.name} />
                    },
                },
            },
            {
                name: "repairRequestLocation",
                label: t("general.location"),
                options: {
                    display: this.isColumnVisible("repairRequestLocation"),
                    filterList: defaultFilter ? defaultFilter[14] : [],
                    filter: false,
                    download: false,
                    maxWidth: 120,
                    customBodyRenderLite: (rowIndex) => {
                        const workOrder = workOrders[rowIndex];
                        if (!workOrder) return ""
                        return (
                            <LabelColumn
                                label={workOrder.repairRequestLocation}
                                tooltipLabel={workOrder.repairRequestLocation}
                                Icon={MapOutlined}
                                clickable
                                showIcon={workOrder.drawingPinLocation}
                                onClick={(e) => this.listToMapRedirectHandler(e, workOrder)} />
                        )
                    },
                },
            },
        ];

        const options = {
            sortingList: [
                { label: "general.buildingNumber", key: "buildingNoIntern", type: "string" },
                { label: "general.location", key: "repairRequestLocation", type: "string" },
                { label: "repairRequest.targetCompletionDate.shortLabel", key: "targetCompletionDate", type: "date" },
                { label: "general.status", key: "statusText", type: "string" },
            ],
            download: true,
            print: true,
            customToolbarSelect: (selectedRows) => (
                <></>
            ),

            onFilterChange: (_, list) => {
                this.setState({ defaultFilter: list });
                setLocalStorageItem(`ResolverWorkOrders_Columns_Filters`, list);
            },
            onViewColumnsChange: (changedColumn, action) => {
                if (action == "add") {
                    var listToUpdate = columnsVisibility;
                    var column = listToUpdate.find((x) => x.name === changedColumn);
                    if (column) {
                        column.visible = true;
                    } else {
                        listToUpdate.push({ name: changedColumn, visible: true });
                    }
                    this.setState({ columnsVisibility: listToUpdate });
                    setLocalStorageItem("ResolverWorkOrders_ColumnsVisibility", listToUpdate);
                } else if (action == "remove") {
                    var listToUpdate = columnsVisibility;
                    var column = listToUpdate.find((x) => x.name === changedColumn);
                    if (column) {
                        column.visible = false;
                    } else {
                        listToUpdate.push({ name: changedColumn, visible: false });
                    }
                    this.setState({ columnsVisibility: listToUpdate });
                    setLocalStorageItem("ResolverWorkOrders_ColumnsVisibility", listToUpdate);
                }
            },
            customToolbar: () => {
                return <>
                    <Tooltip title={t('workOrders.overview.label')}>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            onClick={this.overViewHandler}
                            size="large">
                            <DashboardOutlined />
                        </IconButton>
                    </Tooltip>
                    <DataGridSetting
                        canListType={this.state.canListType}
                        imageSize={imageSize}
                        fontSize={fontSize}
                        onChangeImageSize={(val) => this.handleResizeImage(val)}
                        onChangeFontSize={(val) => this.handleResizeFont(val)}
                        onChangeGridViewType={(val) => this.handleChangeGridStyle(val)}
                    />
                </>;
            }
        };

        return (
            <Container maxWidth={false} className={classes.mainContainer}>
                <DataGrid
                    canListType={this.state.canListType}
                    CustomRowRender={
                        this.props.isMobileOrTab && !!this.state.canListType ?
                            (rest) => <WorkOrderRequestCustomRow {...rest} />
                            : null
                    }
                    options={options}
                    identifier="resolverId"
                    defaultSort={{ name: "workOrderNumber", direction: "desc" }}
                    localColumnOrdersIdentifier={generateLocalStorageKey("ResolverWorkOrders_ColumnOrder")}
                    localColumnSortIdentifier={generateLocalStorageKey("ResolverWorkOrders_ColumnSort")}
                    columnsVisibilityIdentifier={generateLocalStorageKey("ResolverWorkOrders_ColumnsVisibility")}
                    columns={columns}
                    data={workOrders}
                    rowsPerPageIdentifier={generateLocalStorageKey("ResolverWorkOrders_RowsPerPage")}
                    title={"general.workOrders"}
                    loading={this.state.isLoading}
                    getData={this.getWorkOrdersForPrintAndDownload}
                    canGoBack={!!this.state.filterType}
                    fontSize={fontSize}
                />
            </Container>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, buildings, app } = state;
    const { user } = authentication;
    const { selected, all } = buildings;
    return {
        user,
        app,
        selected,
        buildings: all,
    };
}
function WithMediaQuery({ ...rest }) {
    const theme = useTheme();
    const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    return <ConnectedPage {...rest} isMobileOrTab={mediumScreen} />;
}
const ConnectedPage = connect(mapStateToProps)((withTranslation()(withStyles(styles)(Page))));
export { WithMediaQuery as ResolverGridPage };
