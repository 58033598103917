import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { withTranslation } from "react-i18next";
import { Container, Typography, Grid, CssBaseline, Box, TextField, Button, IconButton, AppBar, Toolbar, Snackbar, Slide, Alert,} from "@mui/material";
import { withTheme } from "@mui/styles";
import { history, validateFile, authHeader, toBase64 } from "../../_helpers";
import Dropzone from "../../components/Dropzone";
import { Clear } from "@mui/icons-material";
import clsx from "clsx";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  wrapper: {
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
  },
  mainContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  container: {
    backgroundColor: theme.palette.common.white,
    maxWidth: "100%",
  },
  innerContainer: {
    padding: theme.spacing(2),
  },
  dropzoneContainer: {
    width: "40%",
    float: "left",
    padding: 8,
    margin: "-2px auto -4px",
    [theme.breakpoints.down("lg")]: {
      width: "40%",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  changedirection: {
    flexDirection: "column",
  },
  uploadcontainer: {
    display: "block",
    width: "100%",
    "&:after": {
      content: "",
      clear: "both",
      display: "block",
    },
  },
  alert: {
    top: "80px"
  },
  pdfIcon: {
    width: "80px",
    height: "80px",
    margin: "15px 10px",
  },

  listview: {
    width: "20%",
    float: "left",
    padding: 8,
    position: "relative",

    "& > button": {
      position: "absolute",
      top: 3,
      right: 3,
    },
    "& > div": {
      width: "100%",
      padding: "50% 0px",
      height: 0,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("lg")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "25%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },

  logoContainer: {
    //backgroundColor: theme.palette.common.white,
    "& > img": {
      margin: "auto",
      maxWidth: 200,
      maxHeight: 50,
      minWidth: 100,
    },
  },
  alert: {
    margin: theme.spacing(2, 0),
  },
  grow: {
    flexGrow: 1,
  },
  bold: {
    fontWeight: "bold",
  },
  thumbnail: {
    maxHeight: 50,
    maxWidth: 50,
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
});

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      workOrderDetails: null,
      locationId: null,
      date: new Date(),
      desc: "",
      formSubmittedOnce: false,
      locations: [],
      uploading: false,
      files: [],
      success: false,
    };
    this.handleSelectFiles = this.handleSelectFiles.bind(this);
  }

  componentDidMount() {
    let { resolverId } = this.props.match.params;
    if (resolverId && resolverId.trim() !== "") this.GetResolverDetailsForWorkOrder();
    else history.push("/");
  }

  GetResolverDetailsForWorkOrder() {
    const { t } = this.props;
    let { resolverId } = this.props.match.params;
    const url = webApiUrl + "api/RepairRequest/GetResolverDetailsForWorkOrder/" + resolverId;
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      workOrderDetails: null,
    });

    fetch(url, requestOptions)
      .then((res) => {
        if (res.ok) return res.json();
        else throw res;
      })
      .then((findResponse) => {
        this.setState({ workOrderDetails: findResponse });
      })
      .catch((err) => {
        if (err.status === 408) {
          this.GetResolverDetailsForWorkOrder();
        } else if (err.status === 400) {
          const alert = {
            type: "alert-danger",
            message: t("workOrders.linkExpired"),
          };
          this.setState({ alert });
        } else {
          alert(t("general.api.error"));
        }
      });
  }

  validateForm() {
    const { desc } = this.state;
    this.setState({ formSubmittedOnce: true });
    return this.validateField(desc, true);
  }

  validateField(field, validateForm = false) {
    return (!validateForm && !this.state.formSubmittedOnce) || (field && field.trim() !== "");
  }

  handleRequest = (event, IsComplete) => {
    const { t } = this.props;
    let { resolverId } = this.props.match.params;
    const { files, desc } = this.state;
    if (this.validateForm()) {
      this.setState({ uploading: true });

      const formData = new FormData();

      formData.append("resolverId", resolverId);
      formData.append("desc", desc);
      formData.append("IsComplete", IsComplete);

      for (var i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      fetch(webApiUrl + "api/RepairRequest/UpdateWorkOrderByDirectLink", {
        method: "POST",
        headers: authHeader(),
        body: formData,
      })
        .then((Response) => Response.json())
        .then((res) => {
          if (res.status === true) {
            const alert = {
              type: "alert-success",
              message: t("workOrders.workOrderCompleted"),
            };
            this.setState({ alert, success: true, uploading: false });
          } else throw res;
        })
        .catch(() => {
          this.setState({ uploading: false, files: [] });
          alert(t("general.api.error"));
        });
    } else {
      event.preventDefault();
    }
  };

  handleChangeTextField = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDateChange = (date) => {
    this.setState({ date });
  };

  handleChangeSendEmail = () => {
    this.setState({
      sendEmail: !this.state.sendEmail,
    });
  };

  async handleSelectFiles(selectedFiles) {
    let files = this.state.files.slice();
    if ( selectedFiles.length +  files.length  > 20) {
      this.setState({
        filesSizeIsInValid: true,
        fileUploadErrorMessage: "files.noOfFilesLimit.error",
      });
      return;
    }
    for (var i = 0; i < selectedFiles.length; i++) {
      if (validateFile({ file: selectedFiles[i], allowImage: true, allowPdf: true }) === true) {
        let currenturl = selectedFiles[i].type === "application/pdf" ?"" : await toBase64(selectedFiles[i]);
        selectedFiles[i].url = currenturl;
        files.push(selectedFiles[i]);
      }
    }
    this.setState({ files });
  }

  handleRemoveFile = (index) => {
    let files = this.state.files.slice();
    files.splice(index, 1);
    this.setState({ files });
  };

  renderImageSelector() {
    const { t, classes } = this.props;
    const { files, uploading, success } = this.state;
    const disabled = uploading === true || success === true;

    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography>{t("general.attachments")}</Typography>
          <Typography variant="body2" color="textSecondary">
            {t("general.addFile")}
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <div className={classes.uploadcontainer}>
            {
              <div className={classes.dropzoneContainer}>
                <Dropzone onFilesAdded={this.handleSelectFiles} disabled={disabled} uploading={uploading} accept="image/*,application/pdf" />
              </div>
            }
            {files.map((file, index) => (
              <div className={classes.listview}>
                {file.type === "application/pdf" ? <PictureAsPdfIcon sx={{ color :"primary.main"}} className={classes.pdfIcon} /> : <div
                  style={{
                    backgroundImage: "url(" + file.url + ")",
                  }}
                ></div>}
                <IconButton aria-label="delete" size="small" disabled={disabled} onClick={() => this.handleRemoveFile(index)}>
                  <Clear />
                </IconButton>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { t, classes } = this.props;
    const { workOrderDetails, uploading, desc, alert } = this.state;

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.wrapper}
        style={{
          minHeight: "100vh",
          backgroundImage: "url(" + webApiUrl + "api/Config/WebBackground)",
        }}
      >
        <CssBaseline />
        <Container className={classes.mainContainer}>
          <Grid container className={classes.container}>
            <AppBar position="sticky">
              <Toolbar variant="dense">
                <Typography className={`${classes.bold} ${classes.grow}`}>
                  {t("general.workOrder")}&nbsp;
                  {workOrderDetails && workOrderDetails.workOrderNumber}
                </Typography>
                {alert == null && (
                  <>
                    <Button aria-describedby="handleWorkOrderPopup" variant="outlined" color="inherit" style={{ marginLeft: 12 }} onClick={(e) => this.handleRequest(e, false)}>
                      {t("general.button.reject")}
                    </Button>
                    <Button aria-describedby="handleWorkOrderPopup" variant="outlined" color="inherit" style={{ marginLeft: 12 }} onClick={(e) => this.handleRequest(e, true)}>
                      {t("general.button.complete")}
                    </Button>
                  </>
                )}
              </Toolbar>
            </AppBar>
            <Grid container className={classes.innerContainer} item xs={12}>
              {
                <Grid container alignContent="center" item xs={12} className={classes.logoContainer}>
                  <img src={webApiUrl + "api/Config/WebLogo"} alt="JPDS" />
                </Grid>
              }
              {alert && (
                <Grid container justifyContent="center" item xs={12}>
                  <Box component="span" className={clsx("alert", alert.type, classes.alert)}>
                    {alert.message}
                  </Box>
                </Grid>
              )}
              {workOrderDetails && (
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="flex-start">
                    <Grid item xs={12}>
                      <Typography>{t("general.objectAddress")}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {workOrderDetails.address && workOrderDetails.address}
                      </Typography>
                    </Grid>
                    <Grid container spacing={1} item xs={12} md={6}>
                      <Grid item xs={12}>
                        <Typography>{t("general.resolver")}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {workOrderDetails.organisationHasLogo === true && <img alt="" className={classes.thumbnail} src={"/api/organisation/GetOrganisationLogo/" + workOrderDetails.organisationId} />}
                          {workOrderDetails.organisationName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          error={!this.validateField(desc)}
                          label={t("workOrders.workPerformed")}
                          className={classes.textField}
                          value={desc}
                          onChange={this.handleChangeTextField("desc")}
                          margin="dense"
                          variant="outlined"
                          rows={3}
                          maxRows={7}
                          multiline
                          fullWidth
                          disabled={uploading}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} item xs={12} md={6}>
                      {this.renderImageSelector()}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Snackbar
            TransitionComponent={(props) => (
              <Slide {...props} direction="left" />
            )}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.state.filesSizeIsInValid}
            onClose={() =>
              this.setState((p) => ({ ...p, filesSizeIsInValid: false }))
            }
            autoHideDuration={3000}
            key={"file-size-validation"}
          >
            <Alert className={classes.alert} elevation={6} variant="filled" severity="error">
              {t(this.state.fileUploadErrorMessage ,{noOfFilesAllowed:20})}
            </Alert>
          </Snackbar>
        </Container>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, buildings, dashboardCount } = state;
  const { user } = authentication;
  const { selected } = buildings;
  return {
    user,
    selected,
    dashboardCount,
  };
}

const connectedPage = connect(mapStateToProps)(withTheme(withTranslation()(withStyles(styles)(Page))));
export { connectedPage as WorkOrderStatusUpdatePage };
